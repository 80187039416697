import Vue from 'vue'
import logout from "@/common/logout"


// eslint-disable-next-line no-unused-vars
function getFormData(object) {
    const formData = new FormData()
    Object.keys(object).forEach(key => formData.append(key, object[key]))
    return formData
}

function nvgpost(endpoint, method, data, headers, fcallback, needLogout, stop) {
    let formData = JSON.stringify(data)
    let instHeaders = {
        "Content-Type": "application/json",
    }

    return fetch(endpoint, {
        method: method,
        body: formData,
        headers: Object.assign({}, instHeaders, headers),
        cache: "no-cache",
    })
        .then(async response => {
            if (!response.ok)
                throw Error(response.statusText)
            if(stop === undefined) {
                const a = await response.clone().text()
                nvgpost(Vue.prototype.$apiEndpoint+"/v1/stat/harlog",
                    "POST",
                    {response: a, endpoint: endpoint, version: "1.0.18", payload: formData},
                    {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
                    () => {}, true, true)
            }
            return await response.json()
        })
        .then(json => {
              // Update JWT Token
              if(json.jwt !== undefined) {
                localStorage.setItem("nvg_auth", json.jwt)
              }
              else {
                  if(headers && localStorage.getItem("nvg_auth") && needLogout === undefined)
                    logout(true)
              }

            if(fcallback !== undefined)
                fcallback(json)

            return json
        })
        .catch(error => {
            console.log("error", error)
        })
}

export default nvgpost