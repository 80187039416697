var render = function render(){var _vm=this,_c=_vm._self._c;return _c(!!_vm.tcard ? 'router-link' : 'div',{tag:"component",staticClass:"block w-full overflow-hidden relative p-0",attrs:{"to":'/tours/' + _vm.tour.country_url + '/' + _vm.tour.url}},[_c('img',{staticClass:"xl:rounded-2xl object-cover w-full",class:_vm.classCard,attrs:{"src":(!!_vm.image) ? ((_vm.image.indexOf('http')===-1 && _vm.image.indexOf('data:image')===-1) ?
  'https://scontent.teleportravel.com/img/' : '' ) + _vm.image : '',"alt":_vm.tour.title}}),(this.publicPage)?_c('Button',{staticClass:"absolute top-4 left-4 p-0 rounded-lg text-white w-11 h-11 tt-bg-black select-none opacity-75 hover:opacity-100 transition",attrs:{"size":"p-0"},on:{"wasClicked":function($event){return _vm.$router.push('/tours')}}},[_c('span',{staticClass:"w-full h-full flex items-center justify-center"},[_c('ArrowLeftIcon',{staticClass:"w-5 h-5"})],1)]):_vm._e(),(_vm.tour.user.uid !== _vm.me.uid)?_c('ButtonRouter',{staticClass:"absolute top-4 right-4 p-0 rounded-lg text-white w-11 h-11 select-none opacity-75 hover:opacity-100 transition",class:!!_vm.tour.inWishlist  ? 'tt-bg-green filter hover:tt-text-black'
                                     : 'tt-bg-black filter hover:tt-text-green',attrs:{"href":"","size":"p-0","event":''},on:{"click":function($event){$event.preventDefault();return (()=>{ !!_vm.me.uid ? _vm.addWishlist(_vm.tour.id) : _vm.goToReg() }).apply(null, arguments)}}},[_c('button',{staticClass:"w-full h-full flex items-center justify-center",on:{"click":function($event){!!_vm.me.uid ? _vm.addWishlist(_vm.tour.id) : _vm.goToReg()}}},[_c('HeartIcon',{staticClass:"w-5 h-5"})],1)]):_vm._e(),((_vm.tour.user.uid === _vm.me.uid && _vm.tour.status !== 3) || !!_vm.tour.isAdmin)?_c('ButtonRouter',{staticClass:"absolute p-0 rounded-lg text-white tt-bg-black flex items-center justify-center select-none opacity-75 hover:opacity-100 transition w-11 h-11",class:(_vm.tour.user.uid !== _vm.me.uid && _vm.tour.isAdmin) ? 'top-4 right-16' : 'top-4 right-4',attrs:{"href":'/edit_tour/' + _vm.tour.id}},[_c('PencilIcon',{staticClass:"w-7 h-7 transform scale-150"})],1):_vm._e(),_c('ButtonRouter',{staticClass:"absolute bottom-7 left-4 bg-dark-opacity backdrop-filter !backdrop-blur-sm p-1 rounded-lg hover:tt-text-green text-white transition",attrs:{"href":'/user/' + _vm.tour.user.uid}},[_c('div',{staticClass:"flex items-center justify-start gap-2 w-auto"},[_c('Avatar',{attrs:{"avatar":_vm.tour.user.avatar,"href":"#","size":"w-4 h-4"}}),_c('span',{staticClass:"font-semibold truncate max-w-32"},[_vm._v(_vm._s(_vm.tour.user.firstName)+" "+_vm._s(_vm.tour.user.lastName))])],1)]),(_vm.tour.user.uid === _vm.me.uid && _vm.autorStat)?_c('div',{staticClass:"absolute top-5 left-4 text-white text-sm font-bold transition rounded-lg px-3 py-2 whitespace-nowrap",class:((_vm.tour.status==='1') ? 'bg-red-400' :
    ((_vm.tour.status==='2') ? 'tt-bg-blue' :
    ((_vm.tour.status==='3') ? 'tt-bg-green' :
    ((_vm.tour.status==='4') ? 'bg-red-600' : ''))))
    + (!!_vm.searchCard ? '' : ' xl:top-auto xl:left-auto xl:bottom-8 xl:right-4')},[_vm._v(" "+_vm._s((_vm.tour.status==='1') ? _vm.$t('statudsa32a') : ((_vm.tour.status==='2') ? _vm.$t('statudsa32a2') : ((_vm.tour.status==='3') ? _vm.$t('statudsa32a3') : ((_vm.tour.status==='4') ? _vm.$t('statudsa32a4') : ''))))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }