<template>
  <nav class="px-5 xl:px-8 py-4 grid grid-cols-3 xl:grid-cols-12 items-center gap-6
              fixed top-0 left-0 w-full z-20" :class="(this.logo==='white') ? '' : 'bg-white border-b'"
       id="navbar">
    <Logo class="col-span-2 xl:col-span-2" :h_class="(this.logo==='white') ? 'text-white' : ''" />
    <div class="xl:col-span-4 xl:col-start-5 hidden xl:flex items-center justify-center gap-10"
         v-if="!this.nonav">
      <router-link to="/" :class="this.thpage === '/' ? 'tt-text-green' : ''"
                   class="flex items-center justify-center gap-2 font-semibold text-sm transition
      hover:tt-text-green" >
        <HomeIcon />
        <span>
          {{ $t('browse') }}
        </span>
      </router-link>

      <router-link to="/search" :class="this.thpage === '/search' ? 'tt-text-green' : ''"
                   class="flex items-center justify-center gap-2 font-semibold text-sm transition
      hover:tt-text-green">
        <SearchIcon />
        <span>
          {{ $t('search') }}
        </span>
      </router-link>

      <router-link to="/blog" :class="this.thpage === '/blog' ? 'tt-text-green' : ''"
                   class="flex items-center justify-center gap-2 font-semibold text-sm transition
      hover:tt-text-green">
        <NewspaperIcon />
        <span>
          {{ $t('blog') }}
        </span>
      </router-link>
    </div>

    <div class="xl:col-span-3 xl:col-end-13 flex items-center justify-end"
         :class="(!user.firstName) ? 'gap-2' : 'gap-4'">
      <ButtonRouter class="hidden xl:block tt-bg-green text-white" href="/sign-in" v-if="!user.firstName">
        {{ $t('signin') }}
      </ButtonRouter>
      <ButtonRouter class="hidden xl:block border tt-border-green tt-text-green" href="/sign-up" v-if="!user.firstName">
        {{ $t('signup') }}
      </ButtonRouter>

      <ButtonRouter class="hidden xl:block tt-bg-green text-white whitespace-nowrap rounded-lg" href="/create" v-if="user.firstName">
        {{ $t('create') }}
      </ButtonRouter>

      <ButtonRouter :href="'/user/' + user.uid"
                    class="relative flex items-center z-30">
        <Avatar :avatar="user.avatar" v-if="user.firstName" href="#"/>
      </ButtonRouter>

      <ChangeLang />
    </div>
  </nav>
</template>

<script>
import userInfo from "@/common/userInfo";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Logo from "@/components/Static/Logo";
import Avatar from "@/components/Static/Avatar";
import {HomeIcon, NewspaperIcon, SearchIcon} from "@vue-hero-icons/outline";
import ChangeLang from "@/components/Control/ChangeLang";


export default {
  name: "Navbar",
  components: {
    ChangeLang,
    Avatar,
    ButtonRouter,
    Logo,
    HomeIcon, SearchIcon, NewspaperIcon,
  },
  props: {
    logo: String,
    nonav: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      thpage: this.$route.path,
      user: {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      },
    }
  },
  mounted() {

    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }.bind(this), 500)
    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }.bind(this), 1000)
  },
}
</script>