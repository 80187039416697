<template>
  <section class="px-5 xl:px-12 grid xl:grid-cols-12 gap-6 xl:gap-20 mt-20 pt-2 tt-bg-light">
    <div class="flex items-center justify-center xl:col-start-1 xl:col-end-7">
      <picture>
        <source srcset="../../assets/iphones.webp" type="image/webp">
        <source srcset="../../assets/iphones.png" type="image/png">
        <img src="../../assets/iphones.png" alt="How to start"
             class="object-cover pointer-events-none select-none w-full">
      </picture>
    </div>
    <div class="flex items-start justify-center flex-col xl:col-span-5 xl:col-end-12">
      <h2 class="tt-text-black font-extrabold text-2xl xl:text-5xl" v-html="$t('choster1e121')"></h2>
      <p class="mt-6" v-html="$t('choster1e1212')"></p>

      <div class="flex items-start justify-start flex-col gap-8 mt-8">
        <div class="flex items-center justify-start place-content-start gap-4">
          <div class="flex-none rounded-full h-16 w-16 flex items-center justify-center bg-white tt-text-black shadow-md">
            <DownloadIcon />
          </div>
          <div>
            <h4 class="font-semibold" v-html="$t('choster1e1213')"></h4>
            <p class="mt-2" v-html="$t('choster1e1214')"></p>
          </div>
        </div>

        <div class="flex items-center justify-start place-content-start gap-4">
          <div class="flex-none rounded-full h-16 w-16 flex items-center justify-center bg-white tt-text-black shadow-md">
            <EmojiHappyIcon />
          </div>
          <div>
            <h4 class="font-semibold" v-html="$t('choster1e1215')"></h4>
            <p class="mt-2" v-html="$t('choster1e1216')"></p>
          </div>
        </div>

        <div class="flex items-center justify-start place-content-start gap-4">
          <div class="flex-none rounded-full h-16 w-16 flex items-center justify-center bg-white tt-text-black shadow-md">
            <DeviceMobileIcon />
          </div>
          <div>
            <h4 class="font-semibold" v-html="$t('choster1e1217')"></h4>
            <p class="mt-2" v-html="$t('choster1e1218')"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {DownloadIcon, EmojiHappyIcon, DeviceMobileIcon} from "@vue-hero-icons/outline";


export default {
  name: "HowToStart",
  components: {
    DownloadIcon, EmojiHappyIcon, DeviceMobileIcon,
  },
}
</script>