<template>
  <Button
          class="w-full border border-dashed tt-border-gray py-10 cursor-pointer
          flex items-center justify-center flex-col gap-2 relative mt-4">
    <img :src="((this.image.indexOf('http')===-1 && this.image.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + this.image" alt="Preview" v-if="this.image"
      class="!xl:w-1/2 select-none">
    <UploadIcon class="tt-text-green w-6 h-6" v-if="!this.image"/>
    <span class="tt-text-green text-sm tt-text-green" v-if="!this.image">{{ $t('addmedia') }}</span>
    <input type="file" class="absolute top-0 z-10 left-0 w-full h-full opacity-0 cursor-pointer"
           :id="inputId" :accept="accept" @change="changeCallback($event, inputId)">
  </Button>
</template>

<script>
import Button from "@/components/Inter/Button";
import {UploadIcon} from "@vue-hero-icons/outline";


export default {
  name: "UploaderBig",
  components: {
    Button, UploadIcon
  },
  props: {
    inputId: String,
    accept: String,
    image: [String, Boolean],
    changeCallback: Function,
    clickPartCallback: Function,
  },
  methods: {
    addImage: function (iid) {
      document.getElementById(iid).click()
      if(this.clickPartCallback !== undefined)
      {
        let placeId = iid.split("_")[0].split("element")[1]
        let elementId = iid.split("_")[1]
        this.clickPartCallback(placeId, elementId)
      }
    },

  }
}
</script>