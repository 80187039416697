<template>
  <section class="px-5 xl:px-10 mt-24">
    <div class="text-left">
      <h2 class="font-bold text-2xl xl:text-5xl">{{ $t('nsahisbrgroihewr') }}</h2>
    </div>

    <div class="grid xl:grid-cols-4 gap-12 xl:gap-10 mt-10">
      <a :href="author.facebook" target="_blank"
         class="w-full overflow-hidden rounded-2xl flex items-start justify-start gap-4 flex-col"
           v-for="(author, index) in authors" :key="'author' + index">
        <img :src="author.photo" :alt="'Photo author ' + author.name"
             class="w-full h-96 xl:h-auto object-cover rounded-2xl">

        <div>
          <h3 class="font-bold text-2xl">
            {{ author.name }}
          </h3>
          <h4 class="tt-text-green mt-1">
            {{ author.position }}
          </h4>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import userInfo from "@/common/userInfo";
import nvgpost from "@/common/nvgpost";


export default {
  name: "WeHelp",
  components: {
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      authors: [],
    }
  },
  mounted() {

    setTimeout(function () {
      this.user = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 500)
    setTimeout(function () {
      this.user = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang

      nvgpost(this.$apiEndpoint+"/v1/authors/list",
          "POST",
          {
            lang: this.codeLang,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.authors = res.data
            }
          }, true)
    }.bind(this), 1000)
  }
}
</script>