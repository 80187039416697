<template>
  <div id="app">
    <router-view :key="randomId" />

    <div
      class="fixed bottom-2 left-2 z-30"
      style="font-size: 5px; color: #c0c0c0"
    >
      v{{ version }}
    </div>

    <Alert />

    <vue-tailwind-modal
      :showing="supportModal"
      @close="supportModal = false"
      :showClose="false"
      :backgroundClose="true"
      class="p-8 bg-smoke-400"
    >
      <div class="relative">
        <ButtonX
          class="absolute top-0 right-0 xmodal ts-text-deep text-3xl"
          @wasClicked="supportModal = false"
        />
      </div>

      <div
        class="ts-text-deep text-3xl font-bold font-inter mt-2"
        v-html="$t('nashliioribre')"
      ></div>

      <div
        class="ts-text-deep text-sm font-inter mt-3"
        v-html="$t('nashliioribre2')"
      ></div>

      <div class="mt-8">
        <div class="mb-4">
          <p class="text-xs mb-2">{{ $t("nashliioribre3") }}</p>
          <Input
            id="supportSubject"
            class="w-full"
            :placeholder="$t('nashliioribre4')"
          />
        </div>

        <div class="mb-4">
          <p class="text-xs mb-2">{{ $t("nashliioribre5") }}</p>
          <Textarea
            id="supportText"
            class="w-full"
            :placeholder="$t('nashliioribre6')"
          />
        </div>

        <div class="border ts-border-light mb-4" id="supportScreenshot"></div>

        <div class="mb-4">
          <Button
            size="Sm"
            color="Blue"
            class="w-full mb-2 rounded-lg"
            @wasClicked="send_feedback"
          >
            {{ $t("nashliioribre7") }}
          </Button>
          <Button
            size="Sm"
            color="Gray"
            class="w-full rounded-lg"
            @wasClicked="supportModal = false"
          >
            {{ $t("nashliioribre8") }}
          </Button>
        </div>
      </div>
    </vue-tailwind-modal>
    <div class="fixed bottom-2 right-2 hidden xl:block z-30">
      <Button
        class="bg-white tt-text-black text-sm font-bold transition hover:tt-text-green border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap"
        @wasClicked="supportModal = true"
      >
        <BellIcon class="inline-block" width="20" height="20"></BellIcon>
        <span class="font-semibold ml-1">{{ $t("nashliioribre9") }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import Textarea from "@/components/Inter/Textarea"
import Input from "@/components/Inter/Input"
import Button from "@/components/Inter/Button"
import ButtonX from "@/components/Inter/ButtonX"
import { BellIcon } from "@vue-hero-icons/outline"
import userInfo from "@/common/userInfo"
import Alert from "@/components/Block/Alert"
import nvgpost from "@/common/nvgpost"
import getUserInfo from "@/common/getUserInfo"
import getPasteImage from "@/common/getPasteImage"
import md5 from "md5/md5"

export default {
  name: "App",
  components: {
    Alert,
    Button,
    BellIcon,
    ButtonX,
    Textarea,
    Input,
  },
  data() {
    return {
      version: "1.0.18",
      newVersion: false,
      supportModal: false,
    }
  },
  computed: {
    randomId() {
      return this.$route.fullPath + new Date()
    },
  },
  methods: {
    send_feedback: function () {
      fetch(document.getElementById("supportScreenshot").firstChild.src)
        .then((r) => r.blob())
        .then(
          function (blob) {
            let base64data = ""
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              base64data = reader.result

              nvgpost(
                this.$apiEndpoint + "/v1/support/send",
                "POST",
                {
                  subject: document.getElementById("supportSubject").value,
                  text: document.getElementById("supportText").value,
                  screenshot: base64data,
                },
                {}
              )
            }.bind(this)
          }.bind(this)
        )

      setTimeout(
        function () {
          this.supportModal = false
        }.bind(this),
        800
      )
    },
  },
  mounted() {
    Notification.requestPermission()

    window.addEventListener(
      "paste",
      function (e) {
        getPasteImage(e, function (iblob) {
          if (iblob) {
            let img = new Image()
            img.onload = function () {
              document.getElementById("supportScreenshot").innerHTML = ""
              document.getElementById("supportScreenshot").appendChild(img)
            }
            let URLObj = window.URL || window.webkitURL
            img.src = URLObj.createObjectURL(iblob)
            setTimeout(
              function () {
                document
                  .getElementById("supportScreenshot")
                  .classList.add("w-full")
              }.bind(this),
              1000
            )
          }
        })
      },
      false
    )

    if (
      !userInfo.userHash ||
      !userInfo.firstName ||
      !userInfo.lastName ||
      (userInfo.userHash &&
        md5(userInfo.firstName + userInfo.lastName + userInfo.jwt) !==
          userInfo.userHash)
    ) {
      if (localStorage.getItem("nvg_auth")) getUserInfo()
      else {
        setTimeout(
          function () {
            getUserInfo()
          }.bind(this),
          1000
        )
      }
    }
  },
}
</script>
