<template>
  <div>
    <Navbar class="grid tt-bg-light" />
    <Menu />
    <Start :countries="countries" />
    <Tours />
    <Become />
    <Countries :countries="countries.slice(0, 8)" />
    <HowItWorks />
    <Curator />
    <TheArticle />
    <Articles />
    <Subscribe />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar"
import Menu from "@/components/Control/Menu"
import Start from "@/components/Landing/Start"
import Become from "@/components/Landing/Become"
import Curator from "@/components/Landing/Curator"
import HowItWorks from "@/components/Landing/HowItWorks"
import Articles from "@/components/Landing/Articles"
import Subscribe from "@/components/Landing/Subscribe"
import Footer from "@/components/Block/Footer"
import Tours from "@/components/Landing/Tours"
import TheArticle from "@/components/Landing/TheArticle"
import nvgpost from "@/common/nvgpost"
import userInfo from "@/common/userInfo"
import Countries from "@/components/Landing/Countries"

export default {
  name: "Landing",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: {
    Countries,
    TheArticle,
    Tours,
    Footer,
    Subscribe,
    HowItWorks,
    Become,
    Curator,
    Start,
    Menu,
    Navbar,
    Articles,
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      countries: [],
    }
  },
  methods: {
    getCountries: function () {
      nvgpost(
        this.$apiEndpoint + "/v1/countries/list",
        "POST",
        {
          lang: this.codeLang,
        },
        {},
        (res) => {
          if (res) {
            this.countries = res.data
          }
        },
        true
      )
    },
  },
  mounted() {
    this.getCountries()

    //Invite to platform referal
    if (this.$route.query.ref) {
      localStorage.setItem("nvgref", this.$route.query.ref)
      localStorage.setItem("nvgref_type", "site")
      localStorage.setItem("referal_tour", "-1")
    }

    setTimeout(
      function () {
        this.codeLang = userInfo.codeLang
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.codeLang = userInfo.codeLang
      }.bind(this),
      1000
    )
  },
}
</script>
