let collapsePlace = function (ident, index, cssClass) {
    let module = document.getElementById(ident[0]+index)
    if(cssClass === undefined) {
        cssClass = "max-h-14"
    }

    if(module.classList.contains(cssClass)) {
        module.classList.add("max-h-full")
        module.classList.remove(cssClass)

        if(ident[1] !== undefined) {
            let arrow = document.getElementById(ident[1]+index)
            arrow.classList.remove('rotate-180')
        }
    }
    else {
        module.classList.add(cssClass)
        module.classList.remove("max-h-full")

        if(ident[1] !== undefined) {
            let arrow = document.getElementById(ident[1]+index)
            arrow.classList.add('rotate-180')
        }
    }
}

let collapsePlaces = function (thClass, cssClass) {
    let modules = document.querySelectorAll("."+thClass)
    if(cssClass === undefined) {
        cssClass = "max-h-14"
    }

    for(let i = 0; i < modules.length; i++) {
        let arrow = modules[i].querySelector(".transform")
        if(modules[i].classList.contains(cssClass))
        {
            arrow.classList.remove('rotate-180')
            modules[i].classList.add("max-h-full")
            modules[i].classList.remove(cssClass)
        }
        else {
            arrow.classList.add('rotate-180')
            modules[i].classList.remove("max-h-full")
            modules[i].classList.add(cssClass)
        }
    }

}

export default {collapsePlace, collapsePlaces}