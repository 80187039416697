<template>
  <button class="absolute xmodal tttext-deep text-3xl" @click="$emit('wasClicked')">
    <XIcon />
  </button>
</template>

<script>
import { XIcon } from "@vue-hero-icons/outline"


export default {
  name: "ButtonX",
  components: {
    XIcon
  }
}
</script>
