<template>
  <router-link class="text-xs font-bold rounded-lg transition hover:opacity-70
                 inline-flex select-none"
               :to="href" :class="size+' '+flex">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "ButtonRouter",
  props: {
    href: String,
    size: {
      type: String,
      default: "px-4 py-3",
    },
    flex: {
      type: String,
      default: "items-center justify-center"
    }
  },
}
</script>
