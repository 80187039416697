var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"px-8 grid xl:grid-cols-12 gap-6 mt-20 pt-16 pb-32 xl:pb-8 bg-white"},[_c('div',{staticClass:"xl:col-start-2 xl:col-span-4"},[_c('Logo'),_c('div',{staticClass:"text-xs mt-3"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" - "),_c('router-link',{staticClass:"tt-text-green",attrs:{"to":"/terms-and-privacy-policy"}},[_vm._v(_vm._s(_vm.$t("polic")))])],1),_c('div',{staticClass:"mt-8"},[_vm._v(" "+_vm._s(_vm.$t("fottrrer"))+" ")]),_vm._m(0),_c('div',{staticClass:"text-sm text-gray-400 mt-4"},[_c('div',[_vm._v(_vm._s(_vm.$t("sposbitopltait")))]),_vm._m(1)])],1),_c('div',{staticClass:"xl:col-end-12 xl:col-span-5"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('ul',{staticClass:"flex items-start justify-start flex-col gap-5"},[_c('li',[_c('h3',{staticClass:"tt-text-green"},[_vm._v(" "+_vm._s(_vm.$t("nvifaga"))+" ")])]),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("browse")))])],1),_c('li',[_c('router-link',{attrs:{"to":"/search"}},[_vm._v(_vm._s(_vm.$t("search")))])],1),_c('li',[_c('router-link',{attrs:{"to":!!this.user.uid ? '/user/' + this.user.uid : '/sign-in'}},[_vm._v(_vm._s(_vm.$t("mytracve")))])],1),_c('li',[_c('router-link',{attrs:{"to":"/blog"}},[_vm._v(_vm._s(_vm.$t("blog")))])],1)]),_c('ul',{staticClass:"flex items-start justify-start flex-col self-start gap-5"},[_c('li',[_c('h3',{staticClass:"tt-text-green"},[_vm._v(" "+_vm._s(_vm.$t("fdolowua"))+" ")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"hidden xl:block self-start"},[_c('a',{staticClass:"rounded-full h-12 w-12 tt-bg-light tt-text-green flex items-center justify-center",attrs:{"href":"#navbar"}},[_c('ChevronUpIcon')],1)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-sm text-gray-400 mt-8"},[_vm._v(" Teleportravel S.R.L. "),_c('br'),_vm._v(" hello@teleportravel.com "),_c('br'),_vm._v(" (+373) 69 830 130 "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-start gap-2"},[_c('img',{staticClass:"mt-2 w-10",attrs:{"src":"/img/logo_visa_master_amex/Visa/logo/visa_logo.jpg","alt":"Visa","width":"80"}}),_c('img',{staticClass:"mt-2 w-10",attrs:{"src":"/img/logo_visa_master_amex/Visa/Secure_code_logo/visa_secure.svg","alt":"Visa secure","width":"80"}}),_c('img',{staticClass:"mt-2 w-10",attrs:{"src":"/img/logo_visa_master_amex/Mastercard/logo/mc.svg","alt":"Mastercard","width":"80"}}),_c('img',{staticClass:"mt-2 w-10",attrs:{"src":"/img/logo_visa_master_amex/Mastercard/logo/ms.svg","alt":"Mastercard","width":"80"}}),_c('img',{staticClass:"mt-2 w-10",attrs:{"src":"/img/logo_visa_master_amex/AmericanExpress/SafeKey_BlueBox_rgb.png","alt":"AmericanExpress","width":"80"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"flex items-center justify-start gap-4",attrs:{"href":"https://www.facebook.com/teleportravel/","target":"_blank"}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("../../assets/facebook.svg"),"alt":"Facebook Logo"}}),_c('span',[_vm._v("Facebook")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"flex items-center justify-start gap-4",attrs:{"href":"https://www.instagram.com/teleportravel","target":"_blank"}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("../../assets/instagram.svg"),"alt":"Instagram Logo"}}),_c('span',[_vm._v("Instagram")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"flex items-center justify-start gap-4",attrs:{"href":"https://t.me/travelmoldova","target":"_blank"}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("../../assets/telegram.svg"),"alt":"Telegram Logo"}}),_c('span',[_vm._v("Telegram")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"flex items-center justify-start gap-4",attrs:{"href":"https://www.tiktok.com/@teleportravel.com","target":"_blank"}},[_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("../../assets/tiktok.svg"),"alt":"Telegram Logo"}}),_c('span',[_vm._v("TikTok")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-end mt-16"},[_c('span',{staticClass:"tt-bg-light p-4 text-xs rounded-2xl"},[_vm._v(" Developed by "),_c('a',{staticClass:"tt-text-green",attrs:{"href":"https://naukin.com","target":"_blank"}},[_vm._v("Naukin Design")]),_vm._v(" and "),_c('a',{staticClass:"tt-text-green",attrs:{"href":"https://nvg-group.com","target":"_blank"}},[_vm._v("New Vektor Group")])])])
}]

export { render, staticRenderFns }