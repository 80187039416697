<template>
  <div class="flex items-center">
    <div>
      <Button v-for="(category, index) in categories" :key="'category' + index"
              class="inline-block tt-text-gray border-gray border-b xl:border-0 uppercase text-xs
                       transition bg-white hover:tt-bg-lightgray hover:tt-text-green rounded-lg p-2 m-2"
              @wasClicked="btnClick(category.title)">
        {{ category.title }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Inter/Button";


export default {
  name: "Categories",
  components: {
    Button
  },
  props: ["categories", "btnClick"]
}
</script>