<template>
  <section class="px-5 xl:px-8 grid xl:grid-cols-3 gap-6 mt-6 tt-bg-light" v-if="!!this.articles && !!this.articles.length">
    <ArticleCard hh="h-60" v-for="(article, index) in articles" :key="'article' + index" :article="article" :homepage="true" />
  </section>
</template>

<script>
import nvgpost from "@/common/nvgpost";
import userInfo from "@/common/userInfo";
import ArticleCard from "@/components/Block/ArticleCard";


export default {
  name: "Articles",
  components: {
    ArticleCard
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      articles: [],
    }
  },
  mounted() {

    setTimeout(function () {
      this.codeLang = userInfo.codeLang

      nvgpost(this.$apiEndpoint+"/v1/blog/articles",
          "POST",
          {
            lang: this.codeLang,
            limit: 3,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.articles = res.data
            }
          }, true)
    }.bind(this), 500)


  }
}
</script>