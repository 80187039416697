<template>
  <footer
    class="px-8 grid xl:grid-cols-12 gap-6 mt-20 pt-16 pb-32 xl:pb-8 bg-white"
  >
    <div class="xl:col-start-2 xl:col-span-4">
      <Logo />
      <div class="text-xs mt-3">
        &copy; {{ new Date().getFullYear() }} -
        <router-link to="/terms-and-privacy-policy" class="tt-text-green">{{
          $t("polic")
        }}</router-link>
      </div>
      <div class="mt-8">
        {{ $t("fottrrer") }}
      </div>
      <div class="text-sm text-gray-400 mt-8">
        Teleportravel S.R.L. <br />
        hello@teleportravel.com <br />
        (+373) 69 830 130 <br />
      </div>
      <div class="text-sm text-gray-400 mt-4">
        <div>{{ $t("sposbitopltait") }}</div>
        <div class="flex items-center justify-start gap-2">
          <img
            src="/img/logo_visa_master_amex/Visa/logo/visa_logo.jpg"
            alt="Visa"
            width="80"
            class="mt-2 w-10"
          />
          <img
            src="/img/logo_visa_master_amex/Visa/Secure_code_logo/visa_secure.svg"
            alt="Visa secure"
            width="80"
            class="mt-2 w-10"
          />
          <img
            src="/img/logo_visa_master_amex/Mastercard/logo/mc.svg"
            alt="Mastercard"
            width="80"
            class="mt-2 w-10"
          />
          <img
            src="/img/logo_visa_master_amex/Mastercard/logo/ms.svg"
            alt="Mastercard"
            width="80"
            class="mt-2 w-10"
          />
          <img
            src="/img/logo_visa_master_amex/AmericanExpress/SafeKey_BlueBox_rgb.png"
            alt="AmericanExpress"
            width="80"
            class="mt-2 w-10"
          />
        </div>
      </div>
    </div>
    <div class="xl:col-end-12 xl:col-span-5">
      <div class="flex items-center justify-between">
        <ul class="flex items-start justify-start flex-col gap-5">
          <li>
            <h3 class="tt-text-green">
              {{ $t("nvifaga") }}
            </h3>
          </li>
          <li>
            <router-link to="/">{{ $t("browse") }}</router-link>
          </li>
          <li>
            <router-link to="/search">{{ $t("search") }}</router-link>
          </li>
          <li>
            <router-link
              :to="!!this.user.uid ? '/user/' + this.user.uid : '/sign-in'"
              >{{ $t("mytracve") }}</router-link
            >
          </li>
          <li>
            <router-link to="/blog">{{ $t("blog") }}</router-link>
          </li>
        </ul>
        <ul class="flex items-start justify-start flex-col self-start gap-5">
          <li>
            <h3 class="tt-text-green">
              {{ $t("fdolowua") }}
            </h3>
          </li>
          <li>
            <a
              href="https://www.facebook.com/teleportravel/"
              target="_blank"
              class="flex items-center justify-start gap-4"
            >
              <img
                src="../../assets/facebook.svg"
                alt="Facebook Logo"
                class="w-6 h-6"
              />
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/teleportravel"
              target="_blank"
              class="flex items-center justify-start gap-4"
            >
              <img
                src="../../assets/instagram.svg"
                alt="Instagram Logo"
                class="w-6 h-6"
              />
              <span>Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/travelmoldova"
              target="_blank"
              class="flex items-center justify-start gap-4"
            >
              <img
                src="../../assets/telegram.svg"
                alt="Telegram Logo"
                class="w-6 h-6"
              />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@teleportravel.com"
              target="_blank"
              class="flex items-center justify-start gap-4"
            >
              <img
                src="../../assets/tiktok.svg"
                alt="Telegram Logo"
                class="w-6 h-6"
              />
              <span>TikTok</span>
            </a>
          </li>
        </ul>
        <div class="hidden xl:block self-start">
          <a
            href="#navbar"
            class="rounded-full h-12 w-12 tt-bg-light tt-text-green flex items-center justify-center"
          >
            <ChevronUpIcon />
          </a>
        </div>
      </div>
      <div class="flex items-center justify-end mt-16">
        <span class="tt-bg-light p-4 text-xs rounded-2xl">
          Developed by
          <a href="https://naukin.com" target="_blank" class="tt-text-green"
            >Naukin Design</a
          >
          and
          <a href="https://nvg-group.com" target="_blank" class="tt-text-green"
            >New Vektor Group</a
          >
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/Static/Logo"
import { ChevronUpIcon } from "@vue-hero-icons/outline"
import userInfo from "@/common/userInfo"

export default {
  name: "Footer",
  components: { Logo, ChevronUpIcon },
  data() {
    return {
      user: {
        uid: userInfo.uid,
      },
    }
  },
  mounted() {
    setTimeout(
      function () {
        this.user = {
          uid: userInfo.uid,
        }
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.user = {
          uid: userInfo.uid,
        }
      }.bind(this),
      1000
    )
  },
}
</script>
