<template>
  <section class="px-5 xl:px-12 mt-20">
    <div class="text-center">
      <h2 class="font-bold text-2xl xl:text-5xl">{{ $t('whldpw1') }}</h2>
      <p class="xl:w-1/3 mx-auto mt-6">
        {{ $t('whldpw2') }}
      </p>
    </div>

    <div class="xl:grid xl:grid-cols-3 gap-11 mt-12 overflow-hidden">
      <div class="flex items-center justify-center gap-6 flex-col bg-white rounded-3xl px-10 py-12 mt-11 xl:mt-0">
        <div class="rounded-full h-16 w-16 flex items-center justify-center tt-bg-lightblue">
          <LightBulbIcon class="w-12 h-12 tt-text-blue" />
        </div>
        <div class="text-center">
          <strong class="text-2xl">
            {{ $t('whldpw3') }}
          </strong>
          <p class="mt-2">
            {{ $t('whldpw4') }}
          </p>
        </div>
      </div>
      <div class="flex items-center justify-center gap-6 flex-col bg-white rounded-3xl px-10 py-12 mt-11 xl:mt-0">
        <div class="rounded-full h-16 w-16 flex items-center justify-center tt-bg-lightred">
          <LightningBoltIcon class="w-12 h-12 tt-text-red" />
        </div>
        <div class="text-center">
          <strong class="text-2xl">
            {{ $t('whldpw5') }}
          </strong>
          <p class="mt-2">
            {{ $t('whldpw6') }}
          </p>
        </div>
      </div>
      <div class="flex items-center justify-center gap-6 flex-col bg-white rounded-3xl px-10 py-12 mt-11 xl:mt-0">
        <div class="rounded-full h-16 w-16 flex items-center justify-center tt-bg-lightyellow">
          <StarIcon class="w-12 h-12 tt-text-yellow" />
        </div>
        <div class="text-center">
          <strong class="text-2xl">
            {{ $t('whldpw7') }}
          </strong>
          <p class="mt-2">
            {{ $t('whldpw8') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {LightBulbIcon, LightningBoltIcon, StarIcon} from "@vue-hero-icons/outline";


export default {
  name: "WeHelp",
  components: {
    LightBulbIcon, LightningBoltIcon, StarIcon,
  }
}
</script>