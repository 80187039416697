var render = function render(){var _vm=this,_c=_vm._self._c;return (!!this.articles.length)?_c('section',{staticClass:"px-5 xl:px-8 mt-16 pt-2 tt-bg-light"},[_c('h2',{staticClass:"font-bold text-2xl"},[_vm._v(" "+_vm._s(_vm.$t("usvdfidsrelfewsgtifcles"))+" ")]),_c('div',{staticClass:"w-full maxhm rounded-2xl relative mt-6"},[_c('img',{staticClass:"w-full maxhm tt-bg-black object-cover rounded-2xl select-none",attrs:{"src":this.articles[0].preview,"alt":this.articles[0].title}}),_c('div',{staticClass:"tt-bg-green p-3 xl:p-5 absolute bottom-2 left-2 xl:bottom-6 xl:left-6 rounded-2xl w-64 xl:w-80"},[_c('h2',{staticClass:"font-medium text-lg xl:text-2xl text-white"},[_vm._v(" "+_vm._s(this.articles[0].title)+" ")]),_c('p',{staticClass:"text-white text-xs xl:text-sm mt-4",domProps:{"innerHTML":_vm._s(
          _vm.weboaMD(
            this.articles[0].short
              .replace(/<img[^>]*>/g, '')
              .replace('<p></p>', '')
              .replace('<p>&nbsp;</p>', '')
          )
        )}}),_c('ButtonRouter',{staticClass:"text-white border border-white mt-3 xl:mt-5 rounded-lg",attrs:{"href":'/article/' + this.articles[0].url}},[_vm._v(" "+_vm._s(_vm.$t("redmemofrekf"))+" ")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }