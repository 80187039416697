<template>
  <div :class="(!this.isAndroid || !!this.isIOS) ? 'pb-6' : 'pb-3'"
      class="xl:hidden z-20 fixed bottom-0 left-0 w-full px-4 pt-3 grid grid-cols-5 items-start gap-4 rounded-t-3xl bg-white">
    <router-link to="/" class="flex items-center justify-center gap-1 flex-col text-center">
      <HomeIcon class="tt-text-black w-6 h-6" />
      <span class="tt-text-gray font-light text-xs">
        {{ $t('browse') }}
      </span>
    </router-link>
    <router-link to="/search" class="flex items-center justify-center gap-1 flex-col text-center">
      <SearchIcon class="tt-text-black w-6 h-6" />
      <span class="tt-text-gray font-light text-xs">
        {{ $t('search') }}
      </span>
    </router-link>
    <router-link to="/create" class="flex items-center justify-center gap-1 flex-col text-center">
      <PlusCircleIcon class="tt-text-green w-6 h-6" />
      <span class="tt-text-gray font-light text-xs">
        {{ $t('create') }}
      </span>
    </router-link>
    <router-link to="/blog" class="flex items-center justify-center gap-1 flex-col text-center">
      <NewspaperIcon class="tt-text-black w-6 h-6" />
      <span class="tt-text-gray font-light text-xs">
        {{ $t('blog') }}
      </span>
    </router-link>
    <router-link to="/sign-in" class="flex items-center justify-center gap-1 flex-col text-center" v-if="!user.firstName">
      <UserIcon class="tt-text-black w-6 h-6" />
      <span class="tt-text-gray font-light text-xs">
        {{ $t('logiinn') }}
      </span>
    </router-link>
    <router-link :to="'/user/' + this.user.uid"
                 class="flex items-center justify-center gap-1 flex-col text-center" v-else>
      <Avatar :avatar="user.avatar" :href="user.uid" size="w-6 h-6" />

      <span class="tt-text-gray font-light text-xs">
        {{ $t('cabinter') }}
      </span>
    </router-link>
  </div>
</template>

<script>
import userInfo from "@/common/userInfo";
import {HomeIcon, SearchIcon, PlusCircleIcon, NewspaperIcon, UserIcon} from "@vue-hero-icons/outline";
import Avatar from "@/components/Static/Avatar";
import { isIOS, isAndroid } from 'mobile-device-detect';


export default {
  name: "Menu",
  components: {
    Avatar,
    HomeIcon, SearchIcon, PlusCircleIcon, NewspaperIcon, UserIcon,
  },
  data() {
    return {
      isIOS: !!isIOS,
      isAndroid: !!isAndroid,

      user: {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }
  },
  mounted() {

    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }.bind(this), 500)
    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }.bind(this), 1000)
  },
}
</script>