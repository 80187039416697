<template>
  <div class="bg-white rounded-2xl drop-shadow-34xl1 filter overflow-hidden">
    <TourBanner
      :image="tour.preview"
      :tour="tour"
      class="h-72"
      classCard="h-1772"
      :searchCard="searchCard"
      :daimnef="daimnef"
      :me="me"
      :addWishlist="addWishlist"
      :goToReg="goToReg"
      :autorStat="autorStat"
    />

    <div
      class="flex items-start justify-between flex-col gap-4 pl-4 pr-6 pb-4 xl:p-4"
      :class="!!searchCard ? '' : 'xl:items-center xl:flex-row'"
    >
      <div class="flex flex-col">
        <ButtonRouter
          :href="'/tours/' + tour.country_url + '/' + tour.url"
          flex="items-start justify-start"
          size="p-0"
        >
          <h2 class="text-lg self-start">
            {{ tour.title }}
          </h2>
        </ButtonRouter>
        <ButtonRouter
          :href="'/tours/' + tour.country_url"
          flex="items-start justify-start"
          size="p-0"
        >
          <h3
            class="tt-text-gray text-sm mt-1 flex items-center justify-start gap-1 self-start"
          >
            <LocationMarkerIcon class="w-4 h-4" />
            <span>{{ tour.country }}, {{ tour.city }}</span>
          </h3>
        </ButtonRouter>
      </div>

      <ButtonRouter
        :href="'/tours/' + tour.country_url + '/' + tour.url"
        v-if="
          tour.user.uid !== me.uid &&
          !tour.paid &&
          tour.price !== '0.00' &&
          !tour.isAdmin &&
          !tour.isVip
        "
        class="self-start tt-text-black text-sm font-bold transition hover:tt-text-green border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap gap-2"
        :class="!!searchCard ? '' : 'xl:self-start'"
      >
        <s class="font-light">{{ tour.price }} €</s>
        <b>{{ tour.price_discount }} €</b>
      </ButtonRouter>
      <ButtonRouter
        :href="'/tours/' + tour.country_url + '/' + tour.url"
        v-else-if="
          [0, '0', '0.00'].includes(tour.price) && !tour.isAdmin && !tour.isVip
        "
        class="self-start tt-text-black text-sm font-bold transition hover:tt-text-green border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap"
        :class="!!searchCard ? '' : 'xl:self-start'"
      >
        {{ $t("freeeeeee") }}
      </ButtonRouter>
      <ButtonRouter
        :href="'/tours/' + tour.country_url + '/' + tour.url + '/start'"
        v-else-if="tour.paid && !tour.isAdmin && !tour.isVip"
        class="self-start tt-text-black text-sm font-bold transition hover:tt-text-green border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap"
        :class="!!searchCard ? '' : 'xl:self-start'"
      >
        {{ $t("openmartou") }}
      </ButtonRouter>
      <ButtonRouter
        :href="'/tours/' + tour.country_url + '/' + tour.url"
        v-else
        class="self-start tt-text-black text-sm font-bold transition hover:tt-text-green border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap"
        :class="!!searchCard ? '' : 'xl:self-start'"
      >
        {{ $t("openmartou") }}
      </ButtonRouter>
    </div>

    <div class="flex items-center justify-start gap-3 px-2.5 xl:px-4 pb-5">
      <div class="flex items-center justify-start gap-1">
        <StarIcon class="fill-current tt-text-yellow w-4 h-4" />
        <span class="text-xs xl:text-sm"
          >{{ tour.rate === 0 ? "5.0" : tour.rate.toFixed(1) }} ({{
            tour.reviews
          }})</span
        >
      </div>
      <span
        class="hidden xl:flex items-center justify-center w-1 h-1 tt-bg-black rounded-full"
      ></span>
      <div class="flex items-center justify-start gap-1">
        <GlobeIcon class="tt-text-black w-4 h-4" />
        <span class="text-xs text-sm"
          >{{ (tour.timetravel.distance / 1000).toFixed(0) }}
          {{ $t("km") }}</span
        >
      </div>
      <span
        class="hidden xl:flex items-center justify-center w-1 h-1 tt-bg-black rounded-full"
      ></span>
      <div class="flex items-center justify-start gap-1">
        <ClockIcon class="tt-text-black w-4 h-4" />
        <span class="text-xs text-sm">
          <span class="ml-1">
            <span v-if="tour.timeroutes[0] === '1'">
              {{ $t("ho112") }}
            </span>
            <span v-if="tour.timeroutes[0] === '2'">
              {{ $t("ho1122") }}
            </span>
            <span v-if="tour.timeroutes[0] === '3'">
              {{ $t("ho1123") }}
            </span>
          </span>
        </span>
      </div>
    </div>

    <hr v-if="(tour.user.uid === me.uid || isAdmin) && autorStat" />

    <div
      class="flex items-start justify-center flex-col gap-2 px-4 pb-5 mt-5"
      v-if="(tour.user.uid === me.uid || isAdmin) && autorStat"
    >
      <div class="flex items-center justify-start gap-2">
        <EyeIcon class="w-4 h-4" />
        <span>{{ $t("porostomror") }}: </span>
        <strong>{{ tour.views }}</strong>
      </div>
      <div class="flex items-center justify-start gap-2">
        <HeartIcon class="w-4 h-4" />
        <span>{{ $t("vizamvbreqaron") }}: </span>
        <strong>{{ tour.wishlists }}</strong>
      </div>
      <div class="flex items-center justify-start gap-2">
        <CreditCardIcon class="w-4 h-4" />
        <span>{{ $t("prokturop") }}: </span>
        <strong>{{ tour.sell }}</strong>
      </div>
      <div class="flex items-center justify-start gap-2">
        <HandIcon class="w-4 h-4" />
        <span>{{ $t("profiajaeweq") }}: </span>
        <strong>{{ tour.dones }}</strong>
      </div>
    </div>

    <hr v-if="me.uid === tour.user.uid" />

    <div
      v-if="me.uid === tour.user.uid"
      class="mt-4 border tt-border-green p-3 rounded-md m-2"
    >
      <div class="flex items-center justify-start gap-2">
        <div class="text-sm">{{ $t("profile_yourlink") }}</div>
        <div class="group cursor-help relative">
          <QuestionMarkCircleIcon class="w-4 h-4 tt-text-green" />

          <div
            class="group-hover:block hidden bg-white shadow-xl rounded-2xl p-4 absolute top-0 -right-36 w-64 z-40"
            v-html="$t('profile_invitefriendstext')"
          ></div>
        </div>
      </div>
      <div class="text-xs mt-2">{{ $t("profile_invitefriends") }}</div>

      <div class="flex items-center justify-center gap-0 mt-2">
        <input
          class="tt-text-black text-sm bg-white rounded-xl border focus:tt-border-green px-4 select-text w-full text-indent py-3"
          style="border-top-right-radius: 0; border-bottom-right-radius: 0"
          type="text"
          readonly
          ref="refLink"
          :value="`https://teleportravel.com/tours/${tour.country_url}/${tour.url}?ref=${me.uid}`"
        />
        <Button
          size="Lg"
          @wasClicked="copyRefLink"
          style="border-top-left-radius: 0; border-bottom-left-radius: 0"
          class="rounded-r-lg tt-bg-green text-white w-auto gap-2"
        >
          <ClipboardCopyIcon class="w-4 h-4" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LocationMarkerIcon,
  StarIcon,
  GlobeIcon,
  ClockIcon,
  EyeIcon,
  HeartIcon,
  CreditCardIcon,
  HandIcon,
  ClipboardCopyIcon,
  QuestionMarkCircleIcon,
} from "@vue-hero-icons/outline"
import ButtonRouter from "@/components/Inter/ButtonRouter"
import nvgpost from "@/common/nvgpost"
import daimnef from "@/common/daimnetranslate"
import router from "@/router"
import TourBanner from "@/components/Static/TourBanner"
import Button from "@/components/Inter/Button"

export default {
  name: "TourCard",
  components: {
    TourBanner,
    ButtonRouter,
    LocationMarkerIcon,
    StarIcon,
    GlobeIcon,
    ClockIcon,
    EyeIcon,
    CreditCardIcon,
    HeartIcon,
    HandIcon,
    ClipboardCopyIcon,
    QuestionMarkCircleIcon,
    Button,
  },
  props: {
    tour: Object,
    user: Object,
    me: Object,
    codeLang: String,
    searchCard: {
      type: Boolean,
      default: false,
    },
    autorStat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdmin: function () {
      return !!this.tour.isAdmin
    },
    isVip: function () {
      return !!this.tour.isVip
    },
  },
  methods: {
    copyRefLink() {
      const copyText = this.$refs.refLink
      copyText.select()
      copyText.setSelectionRange(0, 99999) // For mobile devices
      navigator.clipboard.writeText(copyText.value)
    },
    daimnef: function (titleObj) {
      return daimnef(titleObj, this.codeLang)
    },
    goToReg: function () {
      router.push("/sign-in")
    },
    addWishlist: function (tourId) {
      nvgpost(
        this.$apiEndpoint + "/v1/tours/wishlist",
        "POST",
        {
          tourId: tourId,
        },
        { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
        (res) => {
          if (res) {
            this.tour.inWishlist = !this.tour.inWishlist
          }
        },
        true
      )
    },
  },
}
</script>
