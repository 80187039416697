<template>
  <section class="px-5 xl:px-8 tt-bg-light mt-12">
    <div class="flex items-center justify-center font-semibold flex-row gap-3 xl:gap-8">
      <Button class="hover:tt-text-green text-base transition"
              :class="this.mainSort === 1 ? 'tt-text-green' : 'tt-text-black'"
              @wasClicked="doFilter(1)">
        {{ $t('enwewq') }}
      </Button>
      <Button class="hover:tt-text-green text-base transition"
              :class="this.mainSort === 2 ? 'tt-text-green' : 'tt-text-black'"
              @wasClicked="doFilter(2)">
        {{ $t('pdosapra') }}
      </Button>
      <Button class="hover:tt-text-green text-base transition"
              :class="this.mainSort === 3 ? 'tt-text-green' : 'tt-text-black'"
              @wasClicked="doFilter(3)">
        {{ $t('freee') }}
      </Button>
    </div>

    <div class="grid xl:grid-cols-3 gap-7 mt-7" v-if="this.mainSort === 1">
      <div v-for="(tour,tourIndex) in tours.new" :key="'tour1'+tourIndex">
        <TourCard :user="tour.user" :me="user" :tour="tour"  :codeLang="codeLang" />
      </div>
    </div>
    <div class="grid xl:grid-cols-3 gap-7 mt-7" v-else-if="this.mainSort === 2">
      <div v-for="(tour,tourIndex) in tours.popular" :key="'tour2'+tourIndex">
        <TourCard :user="tour.user" :me="user" :tour="tour"  :codeLang="codeLang" />
      </div>
    </div>
    <div class="grid xl:grid-cols-3 gap-7 mt-7" v-else-if="this.mainSort === 3">
      <div v-for="(tour,tourIndex) in tours.free" :key="'tour3'+tourIndex">
        <TourCard :user="tour.user" :me="user" :tour="tour"  :codeLang="codeLang" />
      </div>
    </div>

    <div class="w-full flex items-center justify-center mt-6">
      <Button size="Lg" class="tt-bg-green text-white text-lg mb-2 rounded-lg" @wasClicked="showMore()">
        {{ $t('showmemore') }}
      </Button>
    </div>
  </section>
</template>

<script>
import nvgpost from "@/common/nvgpost";
import Button from "@/components/Inter/Button";
import TourCard from "@/components/Block/TourCard";
import userInfo from "@/common/userInfo";


export default {
  name: "Tours",
  components: {TourCard, Button},
  data() {
    return {
      mainSort: 1,
      showMoreCounter: 6,
      tours: {
        new: [],
        popular: [],
        free: [],
      },
      codeLang: localStorage.getItem("codeLang"),
      user: {
        uid: userInfo.uid,
      },
    }
  },
  mounted() {
    this.doFilter(1)
    nvgpost(this.$apiEndpoint+"/v1/tours/list",
        "POST",
        {
          publicTours: true,
          lang: this.codeLang,
          limit: this.showMoreCounter,
        },
        {
          'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
        }, (res)=>{
          if(res) {
            this.tours.new = res.data.new
            this.tours.popular = res.data.popular
            this.tours.free = res.data.free
          }
        }, true)


    setTimeout(function () {
      this.user = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 500)
    setTimeout(function () {
      this.user = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 1000)
  },
  methods: {
    doFilter: function (mainSort) {
      this.mainSort = mainSort
    },
    showMore: function () {
      this.showMoreCounter += 3

      nvgpost(this.$apiEndpoint+"/v1/tours/list",
          "POST",
          {
            publicTours: true,
            lang: this.codeLang,
            limit: this.showMoreCounter,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.tours.new = res.data.new
              this.tours.popular = res.data.popular
              this.tours.free = res.data.free
            }
          }, true)
    },
  }
}
</script>