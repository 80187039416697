<template>
  <router-link :to="this.fhref" :is="href==='#' ? 'span' : 'router-link'"
               class="inline-flex items-center justify-center rounded-full overflow-hidden" :class="this.size">
    <img :src="((avatar.indexOf('http')===-1 && avatar.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + avatar"
         alt="Avatar" class="object-cover select-none pointer-events-none" :class="this.size">
  </router-link>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    avatar: {
      type: String,
      default: "",
    },
    href: String,
    size: {
      type: String,
      default: "w-8 h-8"
    },
  },
  data() {
    return {
      fhref: (this.href!=="#") ? '/user/' + this.href : '#'
    }
  },
}
</script>