<template>
  <span class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm
                inline-flex items-center justify-between gap-2" :class="shadow">
    <span>{{ slotText }}</span>
    <button @click="closeFunction(slotText)">
      <XIcon class="w-4 h-4"/>
    </button>
  </span>
</template>

<script>
import {XIcon} from "@vue-hero-icons/outline";


export default {
  name: "Chip",
  components: {
    XIcon,
  },
  props: {
    slotText: String,
    closeFunction: Function,
    shadow: {
      type: String,
      default: "shadow-sm",
    },
  }
}
</script>