<template>
  <router-link to="/" class="flex items-center justify-start gap-2">
    <img src="../../assets/logo.svg" alt="Teleportravel Logo" :class="this.img_class">
    <div class="font-semibold" :class="this.h_class">
      Teleportravel
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  props: {
    img_class: {
      type: String,
      default: "w-6 h-6"
    },
    h_class: {
      type: String,
      default: "text-lg tt-text-black"
    },
  }
}
</script>