<template>
  <div class="mb-48 xl:mb-64">
    <Navbar class="grid" />
    <Menu />
    <StepTitle :title="$t('dsas1')" :text="$t('dsas12')" :step="step" />

    <vue-tailwind-modal
        :showing="addLocationModal"
        @close="addLocationModal = false"
        :showClose="false"
        :backgroundClose="true"
        :css="{modal: 'max-w-4xl'}"
        class="w-5/6 xl:p-8 bg-smoke-400"
    >
      <div class="relative">
        <ButtonX class="absolute top-4 right-4 tt-text-black text-3xl" @wasClicked="addLocationModal = false" />
      </div>

      <div class="tt-text-black font-semibold">
        {{ $t('adsadneqa') }}
      </div>

      <div class="bg-white mt-8">
        <p class="flex items-center justify-start text-sm gap-1 mb-4">
          <InformationCircleIcon class="w-4 h-4" />
          <span>{{ $t('adsadneq2') }}</span>
        </p>

        <div class="overflow-hidden rounded-2xl">
          <MglMap :accessToken="this.accessToken" :mapStyle.sync="this.mapStyle"
                  @load="onMapLoadedNew" @zoomend="this.setMarkerr" class="h-96">
            <MglNavigationControl position="top-right" class="hidden xl:block"/>
            <MglGeolocateControl position="top-right" :trackUserLocation="true" :showUserHeading="true" />
            <MglMarker :coordinates="(startLocation.lng !== undefined)
                  ? [startLocation.lng, startLocation.lat] : startLocation"
                       :draggable="true" color="#35B642" @dragend="savePoint" />
            <MglMarker v-for="(place, pindex) in places" :key="'mepdaker'+pindex"
                       :coordinates="place.coordinates" color="#35B642" />
            <GeocoderControl
                :accessToken="this.accessToken"
                :localGeocoder="this.coordinatesGeocoder"
                :placeholder="$t('findenwsotefuoaanj')"
                :language="this.codeLang"
            />
          </MglMap>
        </div>

        <div class="mt-8">
          <h3 class="font-semibold">
            {{ $t('nameofeq') }}
          </h3>

          <div class="relative" v-for="lang in langs" :key="lang">
            <label :for="'newPoint' + lang" class="uppercase tt-text-green absolute top-3 right-3">{{ lang }}</label>
            <Input maxlength="255" :id="'newPoint' + lang" type="text" :placeholder="$t('wtirname_'+lang)"
                   class="block w-full pr-12 mt-4" itype="Sm" />
          </div>

          <Button class="tt-bg-green text-white mt-4 rounded-lg" size="Lg" @wasClicked="addPoint">
            {{ $t('add') }}
          </Button>
        </div>
      </div>

    </vue-tailwind-modal>
    <vue-tailwind-modal
        :showing="editLocationModal"
        @close="editLocationModal = false"
        :showClose="false"
        :backgroundClose="true"
        :css="{modal: 'max-w-4xl'}"
        class="w-5/6 xl:p-8 bg-smoke-400"
    >
      <div class="relative">
        <ButtonX class="absolute top-4 right-4 tt-text-black text-3xl" @wasClicked="editLocationModal = false" />
      </div>

      <div class="tt-text-black font-semibold">
        {{ $t('adsadneqa2ghfdjvrjsvbsmvsnfm') }}
      </div>

      <div class="bg-white mt-8">
        <p class="flex items-center justify-start text-sm gap-1 mb-4">
          <InformationCircleIcon class="w-4 h-4" />
          <span>{{ $t('adsadneq2') }}</span>
        </p>

        <div class="overflow-hidden rounded-2xl">
          <MglMap :accessToken="this.accessToken" :mapStyle.sync="this.mapStyle"
                  @load="onMapLoadedNew" @zoomend="this.setMarkerr" class="h-96">
            <MglNavigationControl position="top-right" class="hidden xl:block"/>
            <MglGeolocateControl position="top-right" :trackUserLocation="true" :showUserHeading="true" />
            <MglMarker :coordinates="(this.startLocation.lng !== undefined)
                  ? [this.startLocation.lng, this.startLocation.lat] : this.startLocation"
                       :draggable="true" color="#35B642" @dragend="savePoint" />
            <GeocoderControl
                :accessToken="this.accessToken"
                :localGeocoder="this.coordinatesGeocoder"
                :placeholder="$t('findenwsotefuoaanj')"
                :language="this.codeLang"
            />
          </MglMap>
        </div>

        <div class="mt-8">
          <Button class="tt-bg-green text-white rounded-lg mt-4" size="Lg" @wasClicked="editPoint">
            {{ $t('save011') }}
          </Button>
        </div>
      </div>

    </vue-tailwind-modal>

    <div class="px-7 py-5 bg-white h-auto xl:h-full w-full xl:w-64 shadow-sm xl:fixed xl:top-20 z-20 mt-5 xl:mt-0">

      <div class="flex items-center justify-between flex-col h-full">
        <div class="flex items-start justify-center flex-col gap-6">
          <h2 class="font-bold text-xl">
            {{ this.titles[this.codeLang] }}
          </h2>
          <div class="flex items-start justify-center flex-row xl:flex-col gap-6">
            <div class="flex items-center justify-start text-sm gap-1">
              <div>
                <span class="tt-text-gray">{{ $t('pricewqkdfasas') }}:</span> <strong>~{{ this.price }} €</strong>
              </div>
              <div class="group cursor-help	relative">
                <QuestionMarkCircleIcon class="w-4 h-4 tt-text-green" />

                <div class="group-hover:block hidden bg-white shadow-xl rounded-2xl p-4
                absolute top-0 left-0 w-auto xl:w-96">
                  {{ $t('podskadkasotraxschr') }}
                </div>
              </div>
            </div>
            <div class="text-sm">
              <span class="tt-text-gray">{{ $t('pricewqkdfasas2') }}:</span> <strong>{{ this.placesbag.length }}</strong>
            </div>
            <div class="text-sm">
              <span class="tt-text-gray">{{ $t('dlindasa') }}:</span> <strong>{{ this.dlindasa2 }} {{ $t('km') }}</strong>
            </div>
          </div>
        </div>

        <div class="flex items-start xl:justify-start justify-center flex-col gap-3 mt-8
        transform xl:-translate-y-20 w-full px-8 xl:px-2"
             v-if="!this.saebuttons">

          <Button class="tt-bg-light tt-text-black rounded-lg gap-3 text-base w-full"
                  v-if="this.step !== 1 && this.showBack"
                  @wasClicked="doStepM">
            <ArrowLeftIcon class="w-4 h-4" />
            <span>
              {{ $t('back') }}
            </span>
          </Button>
          <Button class="tt-bg-light tt-text-black rounded-lg gap-3 text-base w-full"
                  v-if="this.step !== 4"
                  @wasClicked="doStep()">
            <ArrowRightIcon class="w-4 h-4" />
            <span>
              {{ $t('next') }}
            </span>
          </Button>

          <Button class="rounded-lg text-base w-full"
                  :class="this.saveVirginity && (this.step !== 4 || (this.step === 4 && this.wereLoaded))
                  && (this.placesbag.length >= 3 || this.places.length >= 3)
                   ? 'tt-bg-green text-white' : 'tt-bg-lightgray tt-text-green'"
                  @wasClicked="saveRoute(2)"
                  v-if="this.status !== '2' && this.status !== '3'">
            {{ $t('save02') }}
          </Button>

          <Button class="rounded-lg text-base w-full"
                  :class="(((this.status === '2' || this.status === '3') && this.saveVirginity)
                  || (this.status === '1' && (this.placesbag.length < 3 || this.places.length < 3) ) )
                   && (this.step !== 4 || this.step === 4 && this.wereLoaded) ?
                   'tt-bg-green text-white' : 'tt-bg-lightgray tt-text-green'"
                  @wasClicked="saveRoute(1)">
            {{ $t('save0') }}
          </Button>

          <ButtonRouter :href="'/tours/' + this.country_url + '/' + this.url"
                        v-if="this.wasSaved"
                        class="tt-text-green tt-bg-lightgray rounded-lg text-base w-full">
            {{ $t('openespoeisnotte') }}
          </ButtonRouter>
        </div>

      </div>


    </div>

    <div class="xl:grid xl:grid-cols-4 mt-16 pt-2 px-5 xl:px-0" v-if="this.step === 1">
      <div class="xl:col-start-2 xl:col-span-2">
        <h2 class="font-bold text-2xl">
          {{ $t('step1') }}
        </h2>

        <div class="mt-10">
          <div>
            <h3 class="font-semibold">
              {{ $t('wherewa') }}
            </h3>
            <p class="text-sm tt-text-gray mb-3">
              {{ $t('imerwot1') }}
            </p>
            <select id="selectCountry" @change="setCities" @click="setCities"
                    class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full">
              <option value="000" disabled selected>{{ $t('imerwot1') }}</option>
              <option v-for="(country, key) in hCountries" :key="'country'+key"
                      :value="key" :selected="countryRoute===key">
                {{ country }}
              </option>
            </select>
          </div>

          <div v-if="!!Object.keys(hCities).length" class="mt-8">
            <h3 class="font-semibold">
              {{ $t('wherewa2') }}
            </h3>
            <p class="text-sm tt-text-gray mb-3">
              {{ $t('imerwot12') }}
            </p>

            <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full"
                    @change="addCity" @click="addCity" id="selectCity">
              <option value="000" disabled selected>{{ $t('imerwot12') }}</option>
              <option :value="Object.keys(city)[0]" v-for="(city, key) in hCities" :key="key + 'citytown'">
                {{ Object.values(city)[0] }}
              </option>
            </select>

            <div class="mt-2">
              <Chip v-for="(city, indexdassad) in cityRoute" :key="indexdassad + 'citylol'" class="mr-2 mb-2"
                    :closeFunction="removeCity" :slotText="city"/>
            </div>
          </div>

          <div class="mt-8">
            <h3 class="font-semibold">
              {{ $t('wahtlasne') }}
            </h3>
            <p class="text-sm tt-text-gray mb-3">
              {{ $t('imerwot2') }}
            </p>
            <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full"
                    @change="addLang" @click="addLang" id="langSelector">
              <option value="000" disabled selected>{{ $t('chooseedsifflange') }}</option>
              <option :value="lang" v-for="lang in Object.keys(hLangs)" :key="lang"> {{ hLangs[lang] }}</option>
            </select>

            <div class="mt-2">
              <Chip v-for="lang in langsChips" :key="lang" :closeFunction="removeLang" :slotText="lang" class="mr-2 mb-2" />
            </div>

            <div class="hidden !flex items-start justify-start gap-4 flex-col mt-12">
              <label class="inline-flex items-center">
                <input type="checkbox" value="true" class="autotrans" :checked="!!this.autotrans" />
                <span class="ml-2">{{ $t('ewsdliendoasjloba') }}</span>
              </label>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="xl:grid xl:grid-cols-4 mt-16 pt-2 px-5 xl:px-0" v-else-if="this.step === 2">
      <div class="xl:col-start-2 xl:col-span-2">
        <h2 class="font-bold text-2xl">
          {{ $t('step2') }}
        </h2>

        <div class="mt-10">
          <h2 class="font-semibold">
            {{ $t('name') }}
          </h2>
          <p class="text-sm tt-text-gray mb-3">
            {{ $t('podskdatk20') }}
          </p>
          <div class="relative" v-for="lang in langs" :key="lang">
            <label :for="'rName' + lang" class="uppercase tt-text-green absolute top-3 right-3">{{ lang }}</label>
            <Input maxlength="130" :id="'rName' + lang" type="text" :placeholder="$t('wtirname_'+lang)" :onchangefunc="checkInputName"
                   class="block w-full pr-12 mt-4" itype="Sm" :value="titles[lang]" />
          </div>

          <h2 class="font-semibold mt-6" v-html="$t('description00')"></h2>
          <p class="text-sm tt-text-gray mb-3" v-html="$t('podskdatk202')"></p>
          <div class="relative" v-for="lang in langs" :key="'d'+lang">
            <label :for="'rText' + lang" class="uppercase tt-text-green absolute top-3 right-3">{{ lang }}</label>
            <Textarea :id="'rText' + lang" :placeholder="$t('weirtedes_'+lang)" rows="5" :onchangefunc="checkInputDesc"
                      class="block w-full pr-12 mt-4" itype="Sm" :value="descs[lang]"></Textarea>
          </div>

          <h2 class="font-bold mt-6">
            {{ $t('tags') }}
          </h2>
          <p class="text-sm tt-text-gray mb-3">
            {{ $t('podskdatk203') }}
          </p>

          <div class="mt-4">
            <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full"
                    @change="addTag" @click="addTag" id="tagSelector">
              <option value="000" selected>{{ $t('podskdatk203aaa') }}</option>
              <option :value="tag" v-for="tag in Object.keys(hTags)" :key="tag"> {{ hTags[tag] }}</option>
            </select>

            <div class="mt-2">
              <Chip v-for="tag in tagsChips" :key="tag" :closeFunction="removeTag" :slotText="tag" class="mr-2 mb-2" />
            </div>
          </div>

          <h2 class="font-bold mt-6">
            {{ $t('dltiekdodmas') }}
          </h2>
          <p class="text-sm tt-text-gray mb-3">
            {{ $t('podskdatk204') }}
          </p>

          <div class="flex items-start justify-start gap-4 flex-col mt-4">
            <label class="inline-flex items-center gap-2">
              <input type="radio" value="1" class="timeroute" name="aaradio"
                     :checked="(this.timeroutes !== [] && (this.timeroutes[0] === '1')) || this.tour_id === undefined" />
              <span>{{ $t('ho112') }}</span>
            </label>
            <label class="inline-flex items-center gap-2">
              <input type="radio" value="2" class="timeroute" name="aaradio"
                     :checked="this.timeroutes !== [] &&
                     (this.timeroutes[0] === '2')" />
              <span>{{ $t('ho1122') }}</span>
            </label>
            <label class="inline-flex items-center gap-2">
              <input type="radio" value="3" class="timeroute" name="aaradio"
                     :checked="this.timeroutes !== [] &&
                      (this.timeroutes[0] === '3')" />
              <span>{{ $t('ho1123') }}</span>
            </label>
          </div>

        </div>
      </div>
    </div>
    <div class="xl:grid xl:grid-cols-4 mt-16 pt-2 px-5 xl:px-0" v-else-if="this.step === 3">
      <div class="xl:col-start-2 xl:col-span-2">
        <h2 class="font-bold text-2xl">
          {{ $t('step3') }}
        </h2>

        <div class="mt-10">
          <div>
            <h3 class="font-semibold">
              {{ $t('mainre') }}
            </h3>
            <p class="text-sm tt-text-gray mb-3">
              {{ $t('thidiahelper') }}
            </p>

            <UploaderBig inputId="filePreview" accept="image/*"
                         :changeCallback="onFileSelected1" :image="this.imagePreview" />
          </div>

          <div class="mt-8">
            <h3 class="font-semibold">
              {{ $t('photovie') }}
            </h3>
            <p class="text-sm tt-text-gray mb-3">
              {{ $t('thidiahelper2') }}
            </p>

            <div class="grid xl:grid-cols-4 items-center gap-2 mt-4">
              <Uploader inputId="fileMedia" accept="image/*" :changeCallback="onFileSelected2" />

              <div v-for="(img,index) in imagesMain" :key="'imagemains' + index"
                   class="inline-flex items-center justify-center rounded-lg overflow-hidden h-36 relative">
                <img :src="((img.indexOf('http')===-1 && img.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + img" alt="Photo" class="h-36 w-full object-cover">

                <Button class="absolute top-1 right-1 bg-dark-opacity rounded-3xl text-white"
                        @wasClicked="trashImage(index)">
                  <TrashIcon class="w-4 h-4" />
                </Button>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
    <div class="xl:grid xl:grid-cols-4 mt-4 pt-2 px-5 xl:px-0" v-else-if="this.step === 4">
      <div class="xl:col-start-2 xl:col-span-2 mt-5 xl:mt-0">

        <div class="mt-8">
          <h2 class="font-bold text-2xl mb-2" v-html="$t('places')"></h2>

          <p class="tt-text-gray text-sm flex items-center justify-start gap-2 mb-4">
            <span v-html="$t('placesrecomendati')"></span>
          </p>

          <div class="flex items-center justify-center gap-8 flex-col">
            <div class="w-full bg-white rounded-2xl shadow-md p-6 overflow-hidden
            transition transition-all transition-[height] transition-height duration-500 ease-in-out"
                 :id="'placeCollapse' + index"
                 v-for="(place, index) in places" :key="'plca' + index">
              <div class="flex items-center justify-start">
                <Button @wasClicked="downPlace(index)">
                  <ArrowDownIcon class="w-6 h-6" />
                </Button>
                <Button @wasClicked="upPlace(index)">
                  <ArrowUpIcon class="w-6 h-6" />
                </Button>
                <Button @wasClicked="removePlace(index)">
                  <TrashIcon class="w-6 h-6" />
                </Button>
                <Button @wasClicked="collapsePlace(index)">
                  <ChevronDownIcon class="w-6 h-6 transition transform" :id="'placecollapsearrow' + index" />
                </Button>
              </div>

              <div :id="'sukplaceblin'+index">
                <h3 class="font-semibold text-sm mt-6">
                  {{ index+1 }}. {{ $t('nameofeq') }}
                </h3>

                <div class="relative mt-3" v-for="lang in langs" :key="lang">
                  <label :for="'editPoint' + lang + '_' + index" class="uppercase tt-text-green absolute top-3 right-3">
                    {{ lang }}
                  </label>
                  <Input maxlength="255" :id="'editPoint' + lang + '_' + index" type="text"
                         :placeholder="$t('wtirname_'+lang)"
                         class="block w-full pr-12 mt-4" itype="Sm" :value="place.name[lang]"
                         :farg1="index"
                         :onchangefunc="doEditNamePlace"/>
                </div>
              </div>

              <div class="overflow-hidden rounded-2xl hidemer mt-6">
                <MglMap :accessToken="accessToken" :mapStyle.sync="mapStyle"
                        @load="onMapLoadedPlace" class="h-96"
                        :placeid="index" :place="place">
                  <MglMarker :coordinates="(place.coordinates.lng !== undefined)
                  ? [place.coordinates.lng, place.coordinates.lat] : place.coordinates" color="#35B642" />
                </MglMap>
              </div>

              <Button @wasClicked="startEditLocation(index)" class="tt-text-black text-sm font-bold transition hover:tt-text-green
                    border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap flex items-center justify-center gap-2 mt-3">
                  <span>
                    {{ $t('exitpoojtpoinsdblr') }}
                  </span>
                <PencilIcon class="w-4 h-4" />
              </Button>

              <div class="flex items-center justify-center flex-col w-full gap-8 mt-8">
                <div class="w-full" v-for="(element, index_e) in place.elements" :key="index_e + 'index-e'">
                  <div class="flex xl:justify-center xl:items-between xl:items-center xl:justify-between gap-3 flex-col xl:flex-row">
                    <div>
                      <strong class="text-sm capitalize">{{ $t('podss' + element.name) }}</strong>
                      <h4 class="tt-text-gray text-xs">{{ $t('podskadlatka' + element.name) }}</h4>
                    </div>

                    <div class="flex items-center justify-end">
                      <Button @wasClicked="downElement(index, index_e)">
                        <ArrowDownIcon class="w-4 h-4" />
                      </Button>
                      <Button @wasClicked="upElement(index, index_e)">
                        <ArrowUpIcon class="w-4 h-4" />
                      </Button>
                      <Button @wasClicked="removeElement(index, index_e)">
                        <TrashIcon class="w-4 h-4" />
                      </Button>
                    </div>
                  </div>

                  <div class="relative w-full" v-for="(value,key) in element.values" :key="key + 'eleme'">
                    <label :for="'element' + index + '_' + index_e + '_' + key"
                           class="uppercase tt-text-green absolute top-3 right-3"
                           v-if="element.name!=='image' && element.name!=='poll'">
                      {{ key }}
                    </label>
                    <Input :id="'element' + index + '_' + index_e + '_' + key" type="text"
                           :placeholder="$t('aa'+element.name)" :value="value"
                           :onchangefunc="saveElementValue"
                           maxlength="255"
                           class="block w-full pr-12 mt-4" itype="Sm"
                           v-if="['title'].includes(element.name)" />

                    <div v-else-if="element.name==='poll'">
                      <Input :id="'element' + index + '_' + index_e + '_' + key" type="text"
                             :placeholder="$t('aa'+element.name)"
                             :value="element.values.poll.question"
                             :onchangefunc="saveElementValuePollQ"
                             maxlength="255"
                             class="block w-full pr-12 mt-4" itype="Sm"  />

                      <div class="grid xl:grid-cols-2 gap-2.5 mt-4">
                        <div v-for="(valuepoll, indexpoll) in element.values.poll.vars"
                             :key="'element' + index + '_' + index_e + '_' + key + '_poll_' + indexpoll"
                              class="relative">
                          <Input :id="'element' + index + '_' + index_e + '_' + key + '_poll_' + indexpoll" type="text"
                                 :placeholder="$t('aaa'+element.name)" :value="valuepoll"
                                 :onchangefunc="saveElementValuePollV"
                                 maxlength="255"
                                 class="block w-full pr-14" itype="Sm"  />
                          <Button
                              size="Sm"
                              class="tt-bg-red text-white flex-none uppercase absolute top-1 right-2"
                              @wasClicked="removePollVar(index, index_e, indexpoll)">
                            X
                          </Button>
                        </div>
                        <Button
                            size="Sm"
                            class="tt-text-black text-sm font-bold transition hover:tt-text-green
                    border tt-border-green rounded-lg px-3 py-2 whitespace-nowrap flex-none uppercase"
                            @wasClicked="addPollVar(('element' + index + '_' + index_e + '_' + key)
                            .replace('element', '').split('_'))">
                          {{ $t('addpollvar') }}
                        </Button>
                      </div>
                    </div>

                    <Textarea :id="'element' + index + '_' + index_e + '_' + key"
                              :placeholder="$t('weirtedes_'+key)" rows="5" :value="value"
                              :onchangefunc="saveElementValue"
                              class="block w-full pr-12 mt-4" itype="Sm"
                              v-else-if="element.name==='description'" />

                    <Textarea :id="'element' + index + '_' + index_e + '_' + key"
                              :placeholder="$t(element.name+'_'+key)" rows="5" :value="value"
                              :onchangefunc="saveElementValue"
                              class="block w-full pr-12 mt-4" itype="Sm"
                              v-else-if="element.name==='info'" />

                    <div v-else-if="element.name==='image'" class="mt-4">
                      <img :src="((placesImages[value].indexOf('http')===-1 && placesImages[value].indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + placesImages[value]" alt="Preview" v-if="placesImages[value]"
                           class="!xl:w-1/2 aselect-none cursor-pointer"
                      @click="docClickId('element' + index + '_' + index_e + '_' + key)">

                      <vue-anka-cropper
                          :id="'element' + index + '_' + index_e + '_' + key"
                          :class="{'opacity-0': !docclicked.includes('element' + index + '_' + index_e + '_' + key) && placesImages[value], 'invisible': !docclicked.includes('element' + index + '_' + index_e + '_' + key) && placesImages[value]}"
                          class="w-full overflow-hidden mt-2"
                          :options="{
                             aspectRatio: false,
                             closeOnSave: true,
                             cropArea: 'box',
                             croppedHeight: 1198,
                             croppedWidth: 2064,
                             cropperHeight: false,
                             frameLineDash: [5,3],
                             frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                             handleFillColor: 'rgba(255, 255, 255, 0.2)',
                             handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                             handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                             handleSize: 6,
                             handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                             layoutBreakpoint: 850,
                             maxCropperHeight: 2000,
                             maxFileSize: 16000000,
                             overlayFill: 'rgba(0, 0, 0, 0.5)',
                             previewOnDrag: true,
                             previewQuality: 0.8,
                             resultQuality: 0.8,
                             resultMimeType: 'image/jpeg',
                             selectButtonLabel: $t('addmedia'),
                             dropareaMessage: '',
                             showPreview: false,
                             skin: 'tt',
                             uploadData: {},
                             uploadTo: false}"
                          @cropper-error="ankaError"
                          @cropper-file-selected="onAnkaOpened"
                          @cropper-saved="onFileSelectedElement($event, index + '_' + index_e, 'element' + index + '_' + index_e + '_' + key)"></vue-anka-cropper>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center justify-center gap-2 font-normal text-base mt-8">
                <PlusCircleIcon class="w-4 h-4"/>
                <span>{{ $t('enwqe') }}</span>
              </div>

              <div class="grid grid-cols-2 xl:grid-cols-4 gap-5 mt-4">
                <Button @wasClicked="addElement('image', index)"
                        class="gap-2.5 flex-col border shadow-sm py-6 rounded-lg" style="justify-content: flex-start;">
                  <PhotographIcon class="w-16 h-16"/>
                  <span class="font-light" v-html="$t('photo')"></span>
                </Button>
                <Button @wasClicked="addElement('description', index)"
                        class="gap-2.5 flex-col border shadow-sm py-6 rounded-lg" style="justify-content: flex-start;">
                  <MenuIcon class="w-16 h-16"/>
                  <span class="font-light" v-html="$t('description')"></span>
                </Button>
                <Button @wasClicked="addElement('info', index)"
                        class="gap-2.5 flex-col border shadow-sm py-6 rounded-lg" style="justify-content: flex-start;">
                  <InformationCircleIcon class="w-16 h-16"/>
                  <span class="font-light" v-html="$t('information')"></span>
                </Button>
                <Button @wasClicked="addElement('poll', index)"
                        class="gap-2.5 flex-col border shadow-sm py-6 rounded-lg" style="justify-content: flex-start;">
                  <PresentationChartBarIcon class="w-16 h-16"/>
                  <span class="font-light" v-html="$t('poll2')"></span>
                </Button>
              </div>
            </div>

            <div class="w-full flex items-center justify-center mt-8"
                 v-if="places.length!==placesbag.length">
              <Loader />
            </div>
          </div>
        </div>

        <Button class="h-24 bg-white rounded-lg tt-text-green shadow-md w-full mt-8" @wasClicked="addLocationModal = true">
          <div class="flex items-center justify-center gap-2 font-normal text-base">
            <LocationMarkerIcon class="w-6 h-6"/>
            <span>{{ $t('enwqe2') }}</span>
          </div>
        </Button>
      </div>
    </div>

  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import StepTitle from "@/components/Block/StepTitle";
import {
  PresentationChartBarIcon,
  ArrowLeftIcon,
  QuestionMarkCircleIcon,
  ArrowRightIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  MenuIcon,
  PhotographIcon,
  PlusCircleIcon, TrashIcon, ArrowUpIcon, ArrowDownIcon, ChevronDownIcon, PencilIcon,
} from "@vue-hero-icons/outline";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Button from "@/components/Inter/Button";
import Input from "@/components/Inter/Input";
import Textarea from "@/components/Inter/Textarea";
import userInfo from "@/common/userInfo";
import Menu from "@/components/Control/Menu";
import ButtonX from "@/components/Inter/ButtonX";
import Mapbox from "mapbox-gl";
import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl} from "vue-mapbox";
import GeocoderControl from 'vue-mapbox-geocoder'
import Uploader from "@/components/Inter/Uploader";
import UploaderBig from "@/components/Inter/UploaderBig";
import nvgpost from "@/common/nvgpost";
import router from "@/router";
import Chip from "@/components/Block/Chip";
import md5 from "md5";
import Loader from "@/components/Static/Loader";
import vueAnkaCropper from 'vue-anka-cropper'


export default {
  name: "CreateStep",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {
    Loader,
    Chip,
    UploaderBig,
    Uploader,
    ButtonX,
    Menu,
    vueAnkaCropper,
    MglMap, MglNavigationControl, MglGeolocateControl, MglMarker, GeocoderControl, PresentationChartBarIcon,
    Textarea, ButtonRouter, Button, StepTitle, Navbar, Input,
    ArrowRightIcon, ArrowLeftIcon, MenuIcon, PhotographIcon, QuestionMarkCircleIcon,
    LocationMarkerIcon, InformationCircleIcon, PlusCircleIcon,
    TrashIcon, ArrowUpIcon, ArrowDownIcon, ChevronDownIcon, PencilIcon,
  },
  created() {
    this.mapbox = Mapbox
  },
  data() {
    return {
      tour_id: this.$route.params.tour_id,
      accessToken: userInfo.accessToken,
      mapStyle: userInfo.mapStyle,
      mapbox: false,
      addLocationModal: false,
      editLocationModal: false,
      tempPlaceId: false,
      wasSaved: false,
      tempPhoto: false,
      nameValid: false,
      descValid: false,
      codeLang: userInfo.codeLang,
      docclicked: [],
      startLocation: [11.550837, 48.189151],
      hLangs: {},
      hTags: {},
      hCountries: {},
      hCities: {},
      saebuttons: false,
      saveVirginity: false,
      wasSavedLocal: false,
      tempid0: "0",
      status: 1,
      dlindasa2: 0,
      country: false,
      country_url: false,
      url: false,
      ai: 0, //
      ai2: 0, //
      showBack: true, //

      step: 1,
      user: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        avatar: userInfo.avatar,
        uid: userInfo.uid,
      },

      imagePreview: false,    // step 3
      imagesMain: [],         // step 3
      countryRoute: false,    // step 1
      cities: [],             // step 1
      cityRoute: [],          // step 1
      autotrans: false,       // step 1
      titles: {},             // step 2
      descs: {},              // step 2
      timeroutes: [],         // step 2
      langs: [],              // step 1
      langsChips: [],         // step 1
      tags: [],               // step 2
      tagsChips: [],          // step 2
      placesImages: [],       // step 4
      places: [],             // step 4
      placesbag: [],
    }
  },
  computed: {
    wereLoaded: function () {
      if(this.placesbag.length >= 0)
        return this.placesbag.length === this.places.length
          && (this.placesbag.length === 0
                || this.placesbag[this.placesbag.length-1].elements.length
                === this.places[this.places.length-1].elements.length)
      else
        return false
    },
    price: function () {
      let f = this.placesbag.length*0.5+
          ( Math.floor(((this.imagePreview ? 1 : 0)+this.placesImages.length+this.imagesMain.length)/10) )*0.5+
          ( (this.timeroutes.includes("1")) ? 2 : ((this.timeroutes.includes("2")) ? 5 :
              ((this.timeroutes.includes("3")) ? 8 : 0)) )*0.5
      if(f>=20)
        f = 20

      if(f===1 && this.timeroutes.includes("1"))
        f = 0

      return f
    },
    countOfAnka: function () {
      return document.querySelectorAll(".ankaCropper__fileInput").length
    },
    dlindasa: function () {
      let a = 0

      nvgpost(this.$apiEndpoint+"/v1/travels/len",
          "POST",
          {
            places: this.placesbag
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
          (res) => {
            if(res)
            {
              if(res.data.cycling !== undefined)
                a = res.data.cycling
              else if(res.data.walking !== undefined)
                a = res.data.walking
              else if(res.data.driving !== undefined)
                a = res.data.driving
              else if(res.data.traffic !== undefined)
                a = res.data.traffic
              else
              {
                this.dlindasa2 = 0
                return false
              }

              a = ((a.distance)/1000).toFixed(0)
              this.dlindasa2 = a
            }
          }, true)
      return a
    },
  },
  destroyed: function(){
    clearInterval( this.ai2 )
    clearInterval( this.ai )
  },
  watch: {
    countOfAnka: function () {
      let ankas = document.querySelectorAll(".ankaCropper__fileInput")
      for (let i = 0; i < ankas.length; i++) {
        ankas[i].setAttribute("accept", ".jpg, .jpeg, .png")
      }
    }
  },
  mounted() {
    setInterval(function () {
      if(this.dlindasa)
        (this.dlindasa)
    }.bind(this), 1500)
    setTimeout(function () {
      this.ai2 = setInterval(function () {
        if((this.cityRoute).length)
        {
          this.doStepSave(false)

          let saveProps = {
            tour_id: this.tour_id,
            mapbox: this.mapbox,
            nameValid: this.nameValid,
            descValid: this.descValid,
            startLocation: this.startLocation,
            hLangs: this.hLangs,
            langs: this.langs,
            langsChips: this.langsChips,
            hTags: this.hTags,
            hCountries: this.hCountries,
            hCities: this.hCities,
            tempid0: this.tempid0,
            wasSaved: this.wasSaved,
            status: this.status,
            country: this.country,
            country_url: this.country_url,
            url: this.url,
            imagePreview: this.imagePreview,
            imagesMain: this.imagesMain,
            countryRoute: this.countryRoute,
            cities: this.cities,
            cityRoute: this.cityRoute,
            autotrans: this.autotrans,
            titles: this.titles,
            descs: this.descs,
            timeroutes: this.timeroutes,
            tags: this.tags,
            tagsChips: this.tagsChips,
            placesImages: this.placesImages,
            placesbag: this.placesbag,
          }
          try {
            localStorage.setItem("teleport_backupsave_id", this.tour_id)
            localStorage.setItem("teleport_backupsave_time", new Date().getTime())
            localStorage.setItem("teleport_backupsave", JSON.stringify(saveProps))
          }
          catch (e) {
            this.$root.$emit("toShowTrigger", this.$root.$t('previenshilit'), 2)
          }
          this.wasSavedLocal = true
        }
      }.bind(this), 3000)
      this.ai = setInterval(function () {
        if((this.cityRoute).length)
        {
          this.saveRoute(1, true)
          this.$root.$emit("toShowTrigger", this.$root.$t('autosdaveede1e2'), 1)
        }
      }.bind(this), 600000)
    }.bind(this), 2500)

    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        avatar: userInfo.avatar,
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 500)
    setTimeout(function () {
      this.user = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        avatar: userInfo.avatar,
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 1000)

    nvgpost(this.$apiEndpoint+"/v1/mist/create",
        "POST",
        {
          lang: this.codeLang
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
        (res) => {
          this.hLangs = res.langs
          this.hTags = res.tags
          this.hCountries = res.countries

          if(this.tour_id === undefined
              && !this.langs.includes(this.codeLang) && !this.langsChips.includes(this.codeLang))
          {
            this.langs.push(this.codeLang)
            this.langsChips.push(this.hLangs[this.codeLang])
          }
        }, true)

    let autosave = false

    if(this.tour_id !== undefined) {
      nvgpost(this.$apiEndpoint+"/v1/tours/edit",
          "POST",
          {
            tourId: this.tour_id,
            lang: this.codeLang,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
          (res) => {
            if(res.data !== undefined) {
              this.status = res.data[0].status
              this.imagePreview = res.data[0].imagePreview
              this.imagesMain = res.data[0].imagesMain
              this.countryRoute = res.data[0].countryRoute
              this.country = res.data[0].country
              this.country_url = res.data[0].country_url
              this.url = res.data[0].url
              this.autotrans = res.data[0].autotrans
              this.titles = res.data[0].titles
              this.descs = res.data[0].descs
              this.timeroutes = res.data[0].timeroutes
              this.langs = res.data[0].langs
              this.tags = res.data[0].tags
              this.cities = res.data[0].cityRoute
              if(!this.cities || (this.cities.length === 1 && !this.cities[0]))
                this.cities = []

              this.tempid0 = res.data[0].tempid
              this.nameValid = true
              this.descValid = true
              this.saveVirginity = !!this.places.length

              setTimeout(function () {
                this.setCities()
                if(this.langs) {
                  for(let i = 0; i<this.langs.length;i++) {
                    if(this.hLangs[this.langs[i]] !== undefined)
                      this.langsChips.push(this.hLangs[this.langs[i]])
                  }
                }
                if(this.tags) {
                  for(let i = 0; i<this.tags.length;i++) {
                    if(this.hTags[this.tags[i]] !== undefined)
                      this.tagsChips.push(this.hTags[this.tags[i]])
                  }
                }

                setTimeout(function () {
                  this.cities = res.data[0].cityRoute
                  this.cityRoute = []

                  if(this.cities) {
                    setTimeout(function () {
                      document.getElementById("selectCity").value = this.cities[0]

                      for(let i = 0; i<this.cities.length;i++) {
                        for(let j = 0; j<this.hCities.length;j++) {
                          if(Object.keys(this.hCities[j])[0] === this.cities[i]) {
                            this.cityRoute.push(Object.values(this.hCities[j])[0])
                          }
                        }
                      }
                    }.bind(this),500)
                  }
                }.bind(this), 1000)
              }.bind(this),500)
              this.placesImages = res.data[0].placesImages
              this.placesbag = res.data[0].places

              if(localStorage.getItem("teleport_backupsave"))
              {
                let saveData_tour_id = parseInt(localStorage.getItem("teleport_backupsave_id"))
                if(saveData_tour_id === parseInt(this.tour_id)) {
                  autosave = true
                }
              }
            }
            else {
              router.push("/create/steps/")
            }
          }, true)
    }
    else {
      autosave = true
    }
    let that = this
    setTimeout(function () {
      if(autosave && localStorage.getItem("teleport_backupsave")) {
        let a = parseInt(localStorage.getItem("teleport_backupsave_time"))/(1000*60*2)
        let b = new Date().getTime()/(1000*60*2)
        if(b-a < 1) {
          let saveData = JSON.parse(localStorage.getItem("teleport_backupsave"))
          let saveData_id = parseInt(localStorage.getItem("teleport_backupsave_id"))
          if(saveData_id === this.tour_id || this.tour_id === undefined && saveData_id === undefined) {
            for (let i = 0; Object.keys(saveData).length; i++) {
              let key = Object.keys(saveData)[i]
              if(key === undefined)
                break
              that[key] = saveData[key]
            }
            if(!this.cities || (this.cities.length === 1 && !this.cities[0]))
              this.cities = []
          }
        }
      }
    }.bind(this), 1500)

  },
  methods: {
    docClickId: function (theId) {
      if(!this.docclicked.includes(theId)) {
        document.querySelector("#"+theId+" > div > div > button").click()
        this.docclicked.push(theId)
      }
    },
    checkInputName: function (e) {
      this.nameValid = !!e.target.value
    },
    checkInputDesc: function (e) {
      this.descValid = !!e.target.value
    },
    saveElementValue: function (e) {
      const val = e.target.value
      const attr = e.target.getAttribute("id").replace("element","").split("_")
      this.places[attr[0]].elements[attr[1]].values[attr[2]] = val
      if(this.wereLoaded)
        this.placesbag = [...this.places]
    },
    saveElementValuePollQ: function (e) {
      const val = e.target.value
      const attr = e.target.getAttribute("id").replace("element","").split("_")
      this.places[attr[0]].elements[attr[1]].values.poll.question = val
      if(this.wereLoaded)
        this.placesbag = [...this.places]
    },
    saveElementValuePollV: function (e) {
      const val = e.target.value
      const attr = e.target.getAttribute("id").replace("element","").split("_")
      this.places[attr[0]].elements[attr[1]].values.poll.vars[attr[4]] = val
      if(this.wereLoaded)
        this.placesbag = [...this.places]
    },
    addPollVar: function (attr) {
      this.places[attr[0]].elements[attr[1]].values.poll.vars.push("")
      if(this.wereLoaded)
        this.placesbag = [...this.places]
    },
    removePollVar: function (pi, ei, pollId) {
      this.places[pi].elements[ei].values.poll.vars =
          this.places[pi].elements[ei].values.poll.vars.filter(
              (val, key) => {
                return key !== pollId
              })
      if(this.wereLoaded)
        this.placesbag = [...this.places]
    },
    setMarkerr: function (event) {
      this.startLocation = event.map.getCenter()
      if(this.startLocation.lng !== undefined)
        this.startLocation = [this.startLocation.lng, this.startLocation.lat]
    },
    trashImage: function (imgId) {
      this.imagesMain = this.imagesMain.filter(function(value, key) {
        return key !== imgId
      })
    },
    setCities: function () {
      let a = document.getElementById("selectCountry").value

      if(a==="000")
        return false

      this.cities = []
      this.cityRoute = []

      nvgpost(this.$apiEndpoint+"/v1/countries/cities",
          "POST",
          {
            country_id: a,
            lang: this.codeLang,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
          (res) => {
            this.hCities = res.data
          }, true)
    },
    step2: function () {
      for(let i = 0; i < this.langs.length; i++) {
        this.titles[this.langs[i]] = document.getElementById("rName" + this.langs[i]).value
        this.descs[this.langs[i]] = document.getElementById("rText" + this.langs[i]).value
      }

      this.timeroutes = []
      let timeroutes = document.querySelectorAll(".timeroute:checked")
      for(let i = 0; i < timeroutes.length; i++) {
        this.timeroutes.push(timeroutes[i].value)
      }
    },
    step4: function () {
      if(this.places.length !== this.placesbag.length)
        return false

      for(let i = 0; i < this.places.length; i++) {
        if(this.places[i] && this.places[i].elements) {
          for(let j = 0; j < this.places[i].elements.length; j++) {
            if(this.places[i].elements[j].name !== "image") {
              if(this.places[i].elements[j].name !== "poll")
              {
                for (const [key, ] of Object.entries(this.places[i].elements[j].values)) {
                  this.places[i].elements[j].values[key] =
                      document.getElementById('element' + i + '_' + j + '_' + key).value
                }
              }
              else {
                this.places[i].elements[j].values.poll.question =
                    document.getElementById('element' + i + '_' + j + '_poll').value
                for (const [keypoll, ] of Object.entries(this.places[i].elements[j].values.poll.vars)) {
                  this.places[i].elements[j].values.poll.vars[keypoll] =
                      document.getElementById('element' + i + '_' + j + '_poll_poll_' + keypoll).value
                }
              }
            }
          }
        }
      }

      if(this.places && this.places.length) {
        if(this.wereLoaded)
          this.placesbag = [...this.places]
      }
    },
    doStepSave: function (toscroll) {
      if(toscroll === undefined)
      {
        this.saveVirginity = true
        window.scrollTo(0, 0)
      }

      if(this.step===1) {
        this.countryRoute = document.getElementById("selectCountry").value
        this.autotrans = !!document.querySelector(".autotrans:checked")
      }
      else if(this.step === 2) {
        this.step2()
      }
      else if(this.step === 4) {
        this.step4()
      }
    },
    doStepM: function () {
      if(this.step === 4 && this.wereLoaded || this.step !== 4)
      {
        this.doStepSave()
        this.step--
      }
      else
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
    },
    doStep: function () {


      if(this.step === 1 && this.wasSavedLocal) {
        this.showBack = true

        if(!(this.cityRoute).length) {
          this.$root.$emit("toShowTrigger", this.$root.$t('pleasedoewnrds1'), 2)
          return false
        }
        else if(!this.langs.length)
        {
          this.$root.$emit("toShowTrigger", this.$root.$t('younfncannoggbleatd3'), 2)
          return false
        }
      }
      else if(this.step === 2) {
        this.showBack = true

        if(!this.nameValid) {
          this.$root.$emit("toShowTrigger", this.$root.$t('pleasedoewnrds2'), 2)
          return false
        }
        else if(!this.descValid) {
          this.$root.$emit("toShowTrigger", this.$root.$t('pleasedoewnrds3'), 2)
          return false
        }
        else if(!this.tags) {
          this.$root.$emit("toShowTrigger", this.$root.$t('younfncannoggbleatd2'), 2)
          return false
        }
      }
      else if(this.step === 3) {
        this.showBack = true

        if(!this.imagePreview) {
          this.$root.$emit("toShowTrigger", this.$root.$t('pleasedoewnrds4'), 2)
          return false
        }
        else if(this.imagesMain.length < 5) {
          this.$root.$emit("toShowTrigger", this.$root.$t('pleasedoewnrds5'), 2)
          return false
        }
        else if(this.placesbag.length) {
          this.showBack = false
          setTimeout(function () {
            this.showBack = true
          }.bind(this), 1000)

          this.places = []
          for (let i = 0; i < this.placesbag.length+1; i++) {
            setTimeout(function () {
              if(i < this.placesbag.length) {
                this.places.push(this.placesbag[i])
              }
              // else if (i === this.placesbag.length) {
              //   this.placesbag = [...this.places]
              // }
            }.bind(this), 1000 * i)
          }
        }
      }


      this.doStepSave()
      this.step++
    },
    async onMapLoaded() {

    },
    async onMapLoadedPlace(event) {
      await this.onMapLoaded(event, "place")
      const mapId = event.map._container.id
      let parElem = document.getElementById(mapId).parentElement
      const asyncActions = await event.component.actions
      const placeId = parElem.getAttribute("placeid")

      asyncActions.jumpTo({
        center: this.places[placeId].coordinates,
        zoom: 10,
        speed: 1
      })

    },
    async onMapLoadedNew(event) {
      await this.onMapLoaded(event, "new")
      const asyncActions = await event.component.actions

      asyncActions.jumpTo({
        center: this.startLocation,
        zoom: 2,
        speed: 1
      })

      setTimeout(function () {
        document.querySelector(".mapboxgl-control-container > div.mapboxgl-ctrl-top-right > div:nth-child(2) > button").click()
      }.bind(this), 1000)
    },
    savePoint: function (event) {
      this.startLocation = [event.marker._lngLat.lng, event.marker._lngLat.lat]
    },
    addPoint: function () {
      if(this.wereLoaded)
      {
        let names = {}
        for (let i = 0; i < this.langs.length; i++)
        {
          let nameloc = document.getElementById("newPoint" + this.langs[i]).value
          if(nameloc)
            names[this.langs[i]] = nameloc
          else
            return false
        }

        if(this.startLocation.lng !== undefined)
          this.startLocation = [this.startLocation.lng, this.startLocation.lat]

        this.places.push({
          name: names,
          coordinates: this.startLocation,
          elements: [
            {"name":"description","values":{}},
            {"name":"image","values":{"image":false}},
          ],
        })

        for (let i = 0; i < this.langs.length; i++)
        {
          this.places[this.places.length-1].elements[0].values[this.langs[i]] = ""
        }

        this.placesbag = [...this.places]
        this.addLocationModal = false
        this.$root.$emit("toShowTrigger", this.$root.$t('locsatiuselndia'), 1)
      }
    },
    startEditLocation: function (placeId) {
      this.tempPlaceId = placeId
      this.startLocation = this.places[placeId].coordinates
      this.editLocationModal = true
    },
    editPoint: function () {
      this.editLocationModal = false

      if(this.startLocation.lng !== undefined)
        this.startLocation = [this.startLocation.lng, this.startLocation.lat]

      this.places[this.tempPlaceId].coordinates = this.startLocation
      if(this.wereLoaded)
        this.placesbag = [...this.places]
      this.$root.$emit("toShowTrigger", this.$root.$t('locsatiuselndiaedsisdat'), 1)
    },

    addElement: function (element, pid) {
      this.tempPlaceId = pid

      let emptyLangs = {}
      for (let i = 0; i < this.langs.length; i++) {
        emptyLangs[this.langs[i]] = ""
      }

      const sampleText = {
        name: element,
        values: emptyLangs,
      }
      const sampleEmpty = {
        name: element,
        values: {
          "image": false
        },
      }
      const samplePoll = {
        name: element,
        values: {
          "poll": {
            vars: ["", "", ""],
            question: "",
          },
        },
      }

      let sample = {}

      if(["title","description","info"].includes(element)) {
        sample = sampleText
      }
      else if(["image",].includes(element)) {
        sample = sampleEmpty
      }
      else if(["poll",].includes(element)) {
        sample = samplePoll
      }
      this.places[this.tempPlaceId].elements.push(sample)
      if(this.wereLoaded)
        this.placesbag = [...this.places]
      this.$root.$emit("toShowTrigger", this.$root.$t('elemsndodbae'), 1)
    },
    onFileSelected: function (event, ttype, theId) {

      if(ttype === 3) {
        if (event.croppedImageURI) {
          this.placesImages.push(event.croppedImageURI)
          this.places[this.tempPlaceId].elements[this.tempPhoto].values = [this.placesImages.length-1]
          this.placesbag = this.places
          this.docclicked = [...this.docclicked.filter((e) => e !== theId)]

          this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq'), 1)
        }
      }
      else {
        let selectedFiles = event.target.files
        for(let i = 0; i < selectedFiles.length; i++) {
          if(i>8)
            break

          let selectedFile = selectedFiles[i]
          let reader = new FileReader()
          reader.onload = function(event) {
            if(ttype === 1) {
              if (event.target.result.match('image.*') || event.target.result.match('image/*'))
                this.imagePreview = event.target.result
            }
            else if(ttype === 2) {
              if (event.target.result.match('image.*') || event.target.result.match('image/*')) {
                this.imagesMain.push(event.target.result)
              }
            }
            this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq'), 1)
          }.bind(this)
          setTimeout(function(){
            reader.readAsDataURL(selectedFile)
          }.bind(this),150)
        }
      }

    },
    onFileSelected1: function (event) {
      this.onFileSelected(event, 1)
    },
    onFileSelected2: function (event) {
      this.onFileSelected(event, 2)
    },
    onFileSelectedElement: function (event, iid, theId) {
      this.tempPlaceId = iid.split("_")[0]
      this.tempPhoto = iid.split("_")[1]

      this.onFileSelected(event, 3, theId)
    },
    onAnkaOpened: function (file) {
      if(!file)
        this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq_error'), 2)

      setTimeout(function () {
        document.querySelector(".ankaCropper__saveButton").innerText = this.$root.$t('save0')
      }.bind(this), 500)
    },
    ankaError: function (errorMessage) {
      console.log(errorMessage)
      this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq_error'), 2)
    },
    addLang: function () {
      let codeLang = document.getElementById("langSelector").value

      if(codeLang==="000")
        return false

      if(!this.langs.includes(codeLang) && !this.langsChips.includes(codeLang)) {
        this.langs.push(codeLang)
        this.langsChips.push(this.hLangs[codeLang])

        this.descs[codeLang] = ""
        this.titles[codeLang] = ""

        for(let i = 0; i < this.placesbag.length; i++) {
          this.placesbag[i].name[codeLang] = ""

          for(let j = 0; j < this.placesbag[i].elements.length; j++) {
            if(this.placesbag[i].elements[j].name !== "image" && this.placesbag[i].elements[j].name !== "poll")
            {
              this.placesbag[i].elements[j].values[codeLang] = ""
            }
          }
        }

      }
    },
    removeLang: function (lang) {
      if(this.langs.length === 1)
      {
        this.$root.$emit("toShowTrigger", this.$root.$t('younfncannoggbleatd3'), 2)
        return false
      }



      let codeLang = undefined
      for(let i = 0; i < this.langs.length; i++) {
        if(this.hLangs[this.langs[i]] === lang)
        {
          codeLang = this.langs[i]
          break
        }
      }

      delete this.descs[codeLang]
      delete this.titles[codeLang]

      for(let i = 0; i < this.places.length; i++) {
        delete this.places[i].name[codeLang]
        for(let j = 0; j < this.places[i].elements.length; j++) {
          if(this.places[i].elements[j].name !== "image")
            delete this.places[i].elements[j].values[codeLang]
        }
      }
      this.placesbag = [...this.places]

      this.langs = this.langs.filter(function(value) {
        return this.hLangs[value] !== lang
      }.bind(this))
      this.langsChips = this.langsChips.filter(function(value){
        return value !== lang
      })
    },
    addTag: function () {
      let a = document.getElementById("tagSelector").value

      if(a==="000")
        return false

      if(!this.tags.includes(a)) {
        this.tags.push(a)
        this.tagsChips.push(this.hTags[a])
      }
    },
    removeTag: function (tag) {
      if(this.tags.length === 1)
      {
        this.$root.$emit("toShowTrigger", this.$root.$t('younfncannoggbleatd2'), 2)
        return false
      }


      this.tags = this.tags.filter(function(value) {
        return this.hTags[value] !== tag
      })
      this.tagsChips = this.tagsChips.filter(function(value){
        return value !== tag
      })
    },

    addCity: function () {
      let a = document.getElementById("selectCity").value

      if(a==="000")
        return false

      if(!this.cities.includes(a)) {
        this.cities.push(a)
        let b = 0
        for(let i = 0; i < this.hCities.length; i++) {
          if(a===Object.keys(this.hCities[i])[0])
            b = Object.values(this.hCities[i])[0]
        }
        this.cityRoute.push(b)
      }
    },
    removeCity: function (city) {
      if(this.cities.length === 1 || this.cityRoute.length === 1)
      {
        this.$root.$emit("toShowTrigger", this.$root.$t('younfncannoggbleatd1'), 2)
        return false
      }

      this.cities = this.cities.filter(function(value) {
        let city0 = -1
        for(let i = 0; i < this.hCities.length; i++) {
          if(Object.values(this.hCities[i])[0] === city)
          {
            city0 = Object.keys(this.hCities[i])[0].toString()
            break
          }
        }
        return value !== city0
      }.bind(this))
      this.cityRoute = this.cityRoute.filter(function(value){
        return value !== city
      })
    },
    removePlace: function (pid) {
      this.places = this.places.filter(function(value, index) {
        return index !== pid
      })
      if(this.wereLoaded)
        this.placesbag = [...this.places]
      else
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
    },
    upPlace: function (pid) {
      if (pid-1 < 0) {
        return false
      }
      this.places.splice(pid-1, 0, this.places.splice(pid, 1)[0])
      if(this.wereLoaded)
        this.placesbag = [...this.places]
      else
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
    },
    downPlace: function (pid) {
      if (pid+1 >= this.places.length) {
        return false
      }
      this.places.splice(pid+1, 0, this.places.splice(pid, 1)[0])
      if(this.wereLoaded)
        this.placesbag = [...this.places]
      else
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
    },
    collapsePlace: function (pid) {
      if(document.getElementById("placeCollapse" + pid).classList.contains("h-48")) {
        document.getElementById("placeCollapse" + pid).classList.remove("h-48")
        document.querySelector("#placeCollapse" + pid + " > .hidemer").classList.remove("hidden")
        document.getElementById("placecollapsearrow" + pid).classList.remove('rotate-180')
      }
      else {
        document.getElementById("placeCollapse" + pid).classList.add("h-48")
        document.querySelector("#placeCollapse" + pid + " > .hidemer").classList.add("hidden")
        document.getElementById("placecollapsearrow" + pid).classList.add('rotate-180')
      }

    },
    removeElement: function (pid, eid) {
      this.places[pid].elements = this.places[pid].elements.filter(function(value, index) {
        return index !== eid
      })
      this.placesbag = [...this.places]
    },
    upElement: function (pid, eid) {
      if (eid-1 < 0) {
        return false
      }
      else if(this.wereLoaded) {
        this.places[pid].elements.splice(eid-1, 0, this.places[pid].elements.splice(eid, 1)[0])
        this.placesbag = [...this.places]

        //sync textareas
        const eids = [eid, eid-1]
        for(let j = 0; j < eids.length; j++) {
          let keys = Object.keys(this.places[pid].elements[eids[j]].values)
          for(let i = 0; i < keys.length; i++) {
            const lid = keys[i]
            document.getElementById('element' + pid + '_' + eids[j] + '_' + lid).value =
                this.places[pid].elements[eids[j]].values[lid]
          }
        }

      }
      else {
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
      }
    },
    downElement: function (pid, eid) {
      if (eid+1 >= this.places[pid].elements.length) {
        return false
      }
      else if(this.wereLoaded) {
        this.places[pid].elements.splice(eid+1, 0, this.places[pid].elements.splice(eid, 1)[0])
        this.placesbag = [...this.places]

        //sync textareas
        const eids = [eid, eid+1]
        for(let j = 0; j < eids.length; j++) {
          let keys = Object.keys(this.places[pid].elements[eids[j]].values)
          for(let i = 0; i < keys.length; i++) {
            const lid = keys[i]
            document.getElementById('element' + pid + '_' + eids[j] + '_' + lid).value =
                this.places[pid].elements[eids[j]].values[lid]
          }
        }
      }
      else {
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
      }
    },
    doEditNamePlace: function (e, pid) {
      for(let i = 0; i < this.langs.length; i++)
      {
        let nameloc = document.getElementById('editPoint' + this.langs[i] + '_' + pid).value
        if(nameloc)
        {
          this.places[pid].name[this.langs[i]] = nameloc
          if(this.wereLoaded)
            this.placesbag = [...this.places]
          else
            this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
          this.$root.$emit("toShowTrigger", this.$root.$t('naxnitpormeplaoc'), 1)
        }
        else
          return false
      }
    },

    coordinatesGeocoder: function (query) {
      const matches = query.match(
          /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
      )
      if (!matches) {
        return null
      }

      function coordinateFeature(lng, lat) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature'
        }
      }

      const coord1 = Number(matches[1])
      const coord2 = Number(matches[2])
      const geocodes = []

      if (coord1 < -90 || coord1 > 90) {
        geocodes.push(coordinateFeature(coord1, coord2))
      }

      if (coord2 < -90 || coord2 > 90) {
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      if (geocodes.length === 0) {
        geocodes.push(coordinateFeature(coord1, coord2))
        geocodes.push(coordinateFeature(coord2, coord1))
      }

      return geocodes
    },

    saveRoute: function (ttype, noAlert) {
      if(this.step === 4 && !this.wereLoaded) {
        this.$root.$emit("toShowTrigger", this.$root.$t('podojitote0'), 2)
        return false
      }

      this.wasSaved = true
      this.step4()
      if(this.tour_id === undefined && this.tempid0 === "0")
      {
        this.tempid0 = md5(md5(JSON.stringify(this.titles)) + md5(JSON.stringify(this.places)))
      }

      if(ttype===2) {
        if(this.placesbag.length < 3) {
          this.$root.$emit("toShowTrigger", this.$root.$t('nujnozaplitionplces'), 2)
          return false
        }

        let b = false
        let a = false

        for (let i = 0; i < this.placesbag.length; i++) {
          b = false
          a = false
          for (let j = 0; j < this.placesbag[i].elements.length; j++) {
            if(this.placesbag[i].elements[j].name === "description" &&
                Object.values(this.placesbag[i].elements[j].values)[0].length > 1
                || (Object.values(this.placesbag[i].elements[j].values).length > 1 && Object.values(this.placesbag[i].elements[j].values)[1].length > 1 )
                || (Object.values(this.placesbag[i].elements[j].values).length > 2 && Object.values(this.placesbag[i].elements[j].values)[2].length > 1 ))
            {
              a = true
            }
            else if(this.placesbag[i].elements[j].name === "image")
            {
              b = true
            }
            if(a && b)
              break
          }

          if(!b || !a) {
            this.$root.$emit("toShowTrigger", this.$root.$t('elsinebnebkakkdekead'), 2)
            return false
          }

        }
      }

      if(noAlert === undefined)
        this.saebuttons = true


      setTimeout(function () {
        if(noAlert === undefined)
          this.saebuttons = false
      }.bind(this), 3000)

      nvgpost(this.$apiEndpoint+"/v1/travels/create",
          "POST",
          {
            noAlert: !!(noAlert),
            ttype: ttype,
            tempid: this.tempid0,
            is_edit: this.tour_id !== undefined,
            tour_id: this.tour_id,
            imagePreview: this.imagePreview,
            imagesMain: this.imagesMain,
            countryRoute: this.countryRoute,
            cityRoute: this.cities,
            titles: this.titles,
            descs: this.descs,
            price: this.price,
            timeroutes: this.timeroutes,
            autotrans: this.autotrans,
            langs: this.langs,
            tags: this.tags,
            placesImages: this.placesImages,
            places: this.placesbag,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
          (res) => {
            if(res.error === undefined) {
              if(ttype===1) {
                if(noAlert === undefined)
                  this.saebuttons = false

                if(res.is_edit) {
                  this.$root.$emit("toShowTrigger", this.$root.$t('asudjepsmoa22'), 1)
                }
                else {
                  this.$root.$emit("toShowTrigger", this.$root.$t('asudjepsmoa'), 1)
                }

              }
              else {
                if(noAlert === undefined)
                {
                  this.$root.$emit("toShowTrigger", this.$root.$t('pdokrbaenro'), 1)

                  setTimeout(function () {
                    router.push("/user/"+this.user.uid)
                  }.bind(this),2000)
                }
              }
            }
          }, true)
    }
  }
}
</script>

<style>
  @media (min-width: 1280px) {
    .xl\:top-19 {
      top: 4.55rem;
    }
  }
</style>