<template>
  <div class="flex items-center justify-center overflow-hidden">
    <img :src="((image.indexOf('http')===-1 && image.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + image"
         alt="Banner" class="object-cover w-full select-none pointer-events-none">
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    image: {
      type: String,
    }
  },
}
</script>