import Vue from 'vue'
import App from './App.vue'
import userInfo from "@/common/userInfo"
import router from '@/router'
import VueTailwindModal from "vue-tailwind-modal"
import GAuth from 'vue-google-oauth2'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import './assets/css/styles.css'
import './index.css'
import nvgpost from "@/common/nvgpost";

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueViewer)
Vue.component("VueTailwindModal", VueTailwindModal)

//remove PWA
navigator?.serviceWorker?.getRegistrations().then(function (registrations) { for (let registration of registrations) { registration.unregister() } })


//change_endpoint
Vue.prototype.$apiEndpoint = 'https://api.teleportravel.com'
//Vue.prototype.$apiEndpoint = 'http://localhost:8000'
//Vue.prototype.$apiEndpoint = 'https://dev.api.teleportravel.com'

Vue.prototype.$stripePublic = 'pk_live_RMsEWccocwWEvBh7d6K7sr6h'

let dict = JSON.parse(localStorage.getItem('dict'))
let codeLang = localStorage.getItem('codeLang')
if (codeLang === undefined || codeLang === null)
  codeLang = "en"

if (dict === undefined || 1 === 1) {
  setTimeout(function () {
    nvgpost(Vue.prototype.$apiEndpoint + "/v1/dict/",
      "POST",
      {
        lang: codeLang,
      },
      {}, (res) => {
        localStorage.setItem('dict', JSON.stringify(res.data))
        localStorage.setItem('codeLang', codeLang)
        userInfo.dict = {}
        userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))
        const i18n = new VueI18n({
          locale: codeLang,
          fallbackLocale: codeLang,
          messages: userInfo.dict,
        })

        new Vue({
          router,
          i18n,
          render: h => h(App),
        }).$mount('#app')
      }, true)
  }.bind(this), 300)
} else if (dict === null) {
  setTimeout(function () {
    nvgpost(Vue.prototype.$apiEndpoint + "/v1/dict/",
      "POST",
      {
        lang: codeLang,
      },
      {}, (res) => {
        localStorage.setItem('dict', JSON.stringify(res.data))
        localStorage.setItem('codeLang', codeLang)
        userInfo.dict = {}
        userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))
        const i18n = new VueI18n({
          locale: codeLang,
          fallbackLocale: codeLang,
          messages: userInfo.dict,
        })

        new Vue({
          router,
          i18n,
          render: h => h(App),
        }).$mount('#app')
      }, true)
  }.bind(this), 300)
} else {
  userInfo.dict = {}
  userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))

  const i18n = new VueI18n({
    locale: codeLang,
    fallbackLocale: codeLang,
    messages: userInfo.dict,
  })

  new Vue({
    router,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}


userInfo.userHash = localStorage.getItem("usrha")
userInfo.firstName = localStorage.getItem("firstName")
userInfo.lastName = localStorage.getItem("lastName")
userInfo.avatar = localStorage.getItem("avatar")
userInfo.uid = localStorage.getItem("uid")
userInfo.codeLang = localStorage.getItem("codeLang")
userInfo.jwt = localStorage.getItem("nvg_auth")

const gauthOption = {
  clientId: '822011559824-dj25iu3iik2nshjk5bcu95pehe8lai4b.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
Vue.use(VueReCaptcha, { siteKey: '6Lc1mF4dAAAAAIRhSk0h6QN137f_i5Wbs3DonU_z' })
Vue.use(VueMeta)