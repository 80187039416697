<template>
  <section class="container mx-auto py-32">
    <router-link to="/" class="rounded-2xl tt-bg-green text-white inline-block py-4 px-6 mb-24">
      {{ $t('back') }}
    </router-link>

    <p v-html="$t('terms')"></p>

    <p><br></p>
    <p><br></p>
    <p><br></p>
  </section>
</template>

<script>
export default {
  name: "Terms",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
}
</script>