<template>
  <section class="px-5 xl:px-12 mt-20">
    <div class="xl:grid xl:grid-cols-2 gap-6 items-center">
      <picture>
        <source srcset="../../assets/startCreate5.webp" type="image/webp">
        <source srcset="../../assets/startCreate5.png" type="image/png">
        <img src="../../assets/startCreate5.png" alt="Become a creator"
             class="object-cover pointer-events-none select-none w-full">
      </picture>

      <div class="p-5 xl:p-12 shadow-lg xl:w-3/4 bg-white rounded-3xl">
        <h2 class="font-extrabold text-2xl xl:text-5xl">{{ $t('bdcodsk21') }}</h2>
        <p class="mt-4">
          {{ $t('bdcodsk22') }}
        </p>
        <ButtonRouter class="tt-bg-green rounded-lg text-white text-base mt-8" href="/create/steps/">
          {{ $t('choosetra2') }}
        </ButtonRouter>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "BecomeCreate",
  components: {
    ButtonRouter
  }
}
</script>