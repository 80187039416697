<template>
    <section class="text-center mt-28">
      <h1 class="font-bold text-3xl">{{ this.title }}</h1>
      <p class="text-sm mt-2">{{ this.text }}</p>
    </section>
</template>

<script>
export default {
  name: "StepTitle",
  props: ["title","text","step"],
}
</script>