<template>
  <section>
    <div class="h-screen relative">
      <Background class="fixed w-full" />

      <div
        class="absolute top-16 xl:top-0 h-full w-full grid xl:grid-cols-12 px-5 xl:px-16 gap-16 xl:gap-4"
      >
        <div class="xl:col-span-5 flex items-center justify-center">
          <Logo img_class="w-9 h-9" h_class="text-3xl text-white" />
        </div>

        <div
          class="xl:col-end-13 xl:col-span-6 flex items-center justify-center"
        >
          <div
            class="tt-bg-light rounded-2xl w-full my-32 xl:my-16 p-6 xl:p-14"
            v-if="!this.gretoken"
          >
            <h1 class="font-semibold text-center text-3xl">
              {{ $t("signup") }}
            </h1>

            <div class="text-sm tt-text-black mt-8">
              <form action="#" class="mt-6" @submit="doReg">
                <div>
                  <label for="reg_fname" class="block font-semibold mb-3">{{
                    $t("fname")
                  }}</label>
                  <Input
                    maxlength="255"
                    id="reg_fname"
                    type="text"
                    class="block w-full shadow-sm"
                    required=""
                    placeholder="John"
                    :vkeyup="doReg"
                  />
                </div>
                <div class="mt-7">
                  <label for="reg_lname" class="block font-semibold mb-3">{{
                    $t("lname")
                  }}</label>
                  <Input
                    maxlength="255"
                    id="reg_lname"
                    type="text"
                    class="block w-full shadow-sm"
                    :vkeyup="doReg"
                    required=""
                    placeholder="Snow"
                  />
                </div>
                <div class="mt-7">
                  <label for="reg_email" class="block font-semibold mb-3"
                    >E-mail</label
                  >
                  <Input
                    maxlength="255"
                    id="reg_email"
                    type="email"
                    class="block w-full shadow-sm"
                    :vkeyup="doReg"
                    required=""
                    placeholder="john.snow@gmail.com"
                  />
                </div>
                <div class="mt-7">
                  <label for="reg_password" class="block font-semibold mb-3">{{
                    $t("password")
                  }}</label>
                  <Input
                    maxlength="255"
                    id="reg_password"
                    type="password"
                    class="block w-full shadow-sm"
                    :vkeyup="doReg"
                    required=""
                    placeholder="******"
                  />
                </div>
                <div class="mt-7">
                  <label for="reg_password2" class="block font-semibold mb-3">{{
                    $t("password2")
                  }}</label>
                  <Input
                    maxlength="255"
                    id="reg_password2"
                    type="password"
                    class="block w-full shadow-sm"
                    :vkeyup="doReg"
                    required=""
                    placeholder="******"
                  />
                </div>

                <div class="mt-7">
                  <label class="inline-flex items-center gap-2">
                    <input type="checkbox" id="iread" class="autotrans" />
                    <span>
                      <span>
                        {{ $t("iarprochaita") }}
                      </span>
                      <router-link
                        to="/terms-and-privacy-policy"
                        class="tt-text-green"
                      >
                        {{ $t("polic") }}
                      </router-link>
                    </span>
                  </label>
                </div>

                <Button
                  class="rounded-lg tt-bg-green text-white w-full mt-8"
                  size="Lg"
                  @wasClicked="doReg"
                  type="button"
                >
                  {{ $t("createacc") }}
                </Button>
              </form>

              <div class="mt-4">
                <router-link
                  to="/sign-in"
                  class="tt-text-green font-medium text-xs"
                >
                  {{ $t("signin") }}
                </router-link>
              </div>
            </div>
          </div>
          <Loader class="mt-24" v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from "@/components/Static/Logo"
import Input from "@/components/Inter/Input"
import Button from "@/components/Inter/Button"
import Background from "@/components/Static/Background"
import Loader from "@/components/Static/Loader"
import nvgpost from "@/common/nvgpost"
import getUserInfo from "@/common/getUserInfo"
import router from "@/router"
import userInfo from "@/common/userInfo"

export default {
  name: "SignUp",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: { Background, Button, Input, Logo, Loader },
  data() {
    return {
      gretoken: false,
      codeLang: localStorage.getItem("codeLang"),
    }
  },
  mounted() {
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      1000
    )

    // setTimeout(function () {
    //   this.recaptcha()
    // }.bind(this), 700)
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.gretoken = await this.$recaptcha("login")
    },
    doReg: function () {
      let fname = document.getElementById("reg_fname").value
      let lname = document.getElementById("reg_lname").value
      let email = document.getElementById("reg_email").value
      let password = document.getElementById("reg_password").value
      let password2 = document.getElementById("reg_password2").value
      let iread = document.getElementById("iread").checked

      if (!iread) {
        this.$root.$emit("toShowTrigger", this.$root.$t("noread"), 2)
        return false
      }

      if (password === password2 && fname && lname && email && password) {
        nvgpost(
          this.$apiEndpoint + "/v1/sign-up",
          "POST",
          {
            fname: fname,
            lname: lname,
            email: email,
            password: password,
            password2: password2,
            lang: this.codeLang,
          },
          {},
          (res) => {
            if (res.jwt) {
              let jwt = res.jwt
              localStorage.setItem("nvg_auth", jwt)
              getUserInfo(jwt)
              router.push("/")
            } else {
              this.$root.$emit("toShowTrigger", this.$root.$t("chtotorne"), 2)
            }
          },
          true
        )
      }
    },
  },
}
</script>
