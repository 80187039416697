<template>
  <section class="mx-5 xl:mx-8 grid xl:grid-cols-12 items-center gap-14 mt-20">
    <div class="xl:col-start-2 xl:col-span-4 flex items-center justify-center gap-6 flex-col order-2 xl:order-1">
        <h2 class="font-extrabold text-2xl xl:text-5xl self-start">
          {{ $t('godcas1') }}
        </h2>
        <p>
          {{ $t('godcas2') }}
        </p>
    </div>
    <div class="xl:col-span-7 w-full order-1 xl:order-2">
      <div class="rounded-3xl overflow-hidden relative">
        <picture>
          <source srcset="../../assets/startCreate2.webp" type="image/webp">
          <source srcset="../../assets/startCreate2.png" type="image/png">
          <img src="../../assets/startCreate2.png" alt="Who can become an author"
               class="object-cover pointer-events-none select-none w-full">
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhoCanBe"
}
</script>