<template>
  <div>
    <Navbar class="grid" />
    <Menu />
    <section class="mt-24 xl:mt-28">
      <h1 class="text-center text-3xl mt-48 mb-72">
        {{ $t("thank-you") }}
      </h1>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar"
import Menu from "@/components/Control/Menu"
import Footer from "@/components/Block/Footer"
import router from "@/router"

export default {
  name: "ThankYou",
  components: { Footer, Menu, Navbar },
  created() {
    setTimeout(
      function () {
        router.push({
          path: this.$route.path.replace("/thank-you", "/start"),
        })
      }.bind(this),
      2000
    )
  },
}
</script>
