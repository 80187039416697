<template>
  <div>
    <Navbar class="grid"/>
    <Menu />
    <section class="mt-24 xl:mt-28" v-if="!!this.categories.length && !!this.articles.length">
      <Categories :categories="categories" class="justify-center" :btnClick="doFilter" />

      <div class="px-5 grid xl:grid-cols-12">
        <div class="xl:grid xl:grid-cols-2 items-center xl:mt-8">
          <div class="hidden tt-text-black text-base mt-8 xl:mt-0">
            2013 - 2019
          </div>
          <div class="hidden tt-text-black flex items-center mt-2 xl:mt-0 xl:ml-auto">
            <span class="font-semibold">Sorting:</span>
            <label>
              <select class="tt-bg-light ml-2">
                <option value="0" selected>Sort by date</option>
                <option value="1">Sort by date</option>
              </select>
            </label>
          </div>
        </div>

        <div class="xl:col-span-10 xl:col-start-2 flex items-center flex-col gap-5 mt-8"
             v-if="!!this.articles[0]">
          <ArticleCard class="mt-5"
                       v-for="(article, index) in articles" :key="'article' + index" :article="article" />
        </div>
      </div>

    </section>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import Menu from "@/components/Control/Menu";
import Footer from "@/components/Block/Footer";
import Loader from "@/components/Static/Loader";
import userInfo from "@/common/userInfo";
import nvgpost from "@/common/nvgpost";
import Categories from "@/components/Block/Categories";
import ArticleCard from "@/components/Block/ArticleCard";


export default {
  name: "Blog",
  components: {ArticleCard, Categories, Footer, Menu, Navbar, Loader},
  data() {
    return {
      tag_get: this.$route.query.tag,
      codeLang: localStorage.getItem("codeLang"),
      categories: [],
      articles: [],
    }
  },
  methods: {
    doFilter: function (title) {
      this.articles = [0]
      nvgpost(this.$apiEndpoint+"/v1/blog/articles",
          "POST",
          {
            lang: this.codeLang,
            tag: title,
            limit: 50,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.articles = res.data
            }
          }, true)
    }
  },
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  mounted() {

    setTimeout(function () {
      this.codeLang = userInfo.codeLang
    }.bind(this), 500)
    setTimeout(function () {
      this.codeLang = userInfo.codeLang
      let tagget = this.tag_get
      if(!tagget)
        tagget = "none"

      nvgpost(this.$apiEndpoint+"/v1/blog/categories",
          "POST",
          {
            lang: this.codeLang,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.categories = res.data
            }
          }, true)

      nvgpost(this.$apiEndpoint+"/v1/blog/articles",
          "POST",
          {
            lang: this.codeLang,
            tagget: tagget,
            limit: 50,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.articles = res.data
            }
          }, true)
    }.bind(this), 1000)
  }
}
</script>