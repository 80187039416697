<template>
  <div>
    <Navbar class="xl:grid tt-bg-light"/>
    <Menu />
    <SearchBlock class="mt-28" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import Menu from "@/components/Control/Menu";
import Footer from "@/components/Block/Footer";
import SearchBlock from "@/components/Block/SearchBlock";


export default {
  name: "Search",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {Footer, Menu, Navbar, SearchBlock}
}
</script>