<template>
  <section class="xl:w-1/2 mt-8">
    <div class="flex flex-col gap-2 mb-8" v-for="(review, index) in tour.reviews" :key="'review' + index">
      <div class="flex items-start gap-2 mt-2">

        <Avatar :avatar="review.user.avatar" :href="review.user.id" size="w-10 h-10" />

        <div class="flex flex-col">
          <div class="tt-text-black text-sm font-semibold">
            {{ review.user.firstName }} {{ review.user.lastName }}
          </div>
          <div class="ts-text-black text-sm font-medium flex items-center">
            <StarIcon class="tt-text-yellow fill-current w-4 h-4"
                      v-for="raz in Array.from({length: review.rate}, (_, i) => i + 1)"
                      :key="raz + 'rate1'" />
            <span class="ml-1">{{ parseInt(review.rate).toFixed(1) }}</span>
          </div>
          <div class="tt-text-gray text-xs mt-2">
            {{ review.date }}
          </div>
        </div>
      </div>
      <p class="tt-text-gray text-sm font-medium mt-2">
        {{ review.text }}
      </p>
    </div>
  </section>
</template>

<script>
import Avatar from "@/components/Static/Avatar";
import {StarIcon} from "@vue-hero-icons/outline";


export default {
  name: "Reviews",
  components: {
    Avatar, StarIcon,
  },
  props: {
    tour: Object,
  }
}
</script>