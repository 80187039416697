<template>
  <div class="boxes">
    <div class="box" v-for="item in [1,2,3,4]" :key="item">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>