<template>
  <section>
    <div class="h-screen relative">
      <Background class="fixed w-full" />

      <div
        class="absolute top-16 xl:top-0 h-full w-full grid xl:grid-cols-12 px-5 xl:px-16 gap-16 xl:gap-4"
      >
        <div class="xl:col-span-5 flex items-center justify-center">
          <Logo img_class="w-9 h-9" h_class="text-3xl text-white" />
        </div>

        <div
          class="xl:col-end-13 xl:col-span-6 flex items-center justify-center"
        >
          <div
            class="tt-bg-light rounded-2xl w-full mb-32 xl:my-16 p-6 xl:p-14"
            v-if="!this.gretoken"
          >
            <h1 class="font-semibold text-center text-3xl">
              {{ $t("signintocontiutne") }}
            </h1>

            <div class="text-sm tt-text-black mt-8">
              <button
                class="flex items-center justify-between w-full p-5 rounded-lg bg-white"
                @click="logInWithGoogle"
              >
                <span class="font-semibold">
                  {{ $t("etnregole") }}
                </span>
                <img
                  src="../assets/google.svg"
                  alt="Google Logo"
                  class="w-6 h-6"
                />
              </button>

              <button
                class="flex items-center justify-between w-full p-5 rounded-lg bg-white mt-6"
                @click="logInWithFacebook"
              >
                <span class="font-semibold">
                  {{ $t("etnregole2") }}
                </span>
                <img
                  src="../assets/fbauth.svg"
                  alt="Facebook Logo"
                  class="w-6 h-6"
                />
              </button>

              <p class="my-8 text-xs tt-text-gray text-center">
                {{ $t("etnregole3") }}
              </p>

              <button
                @click="doEmail"
                v-if="!this.isEmail"
                class="flex items-center justify-between w-full p-5 rounded-lg bg-white mt-6"
              >
                <span class="font-semibold">
                  {{ $t("etnregole4") }}
                </span>
                <MailIcon class="w-6 h-6" />
              </button>
              <form
                action="#"
                class="mt-6"
                @submit="logInWithTeleportravel"
                v-else
              >
                <div>
                  <label for="email_email" class="block font-semibold mb-3"
                    >E-mail</label
                  >
                  <Input
                    maxlength="255"
                    id="email_email"
                    type="email"
                    class="block w-full shadow-sm"
                    :vkeyup="logInWithTeleportravel"
                    required=""
                    placeholder="john.snow@gmail.com"
                  />
                </div>
                <div class="mt-7">
                  <label
                    for="email_password"
                    class="block font-semibold mb-3"
                    >{{ $t("password") }}</label
                  >
                  <Input
                    maxlength="255"
                    id="email_password"
                    type="password"
                    class="block w-full shadow-sm"
                    :vkeyup="logInWithTeleportravel"
                    required=""
                    placeholder="******"
                  />
                </div>

                <Button
                  class="rounded-lg tt-bg-green text-white w-full mt-8"
                  size="Lg"
                  @wasClicked="logInWithTeleportravel"
                  type="button"
                >
                  {{ $t("etnregole5") }}
                </Button>
              </form>

              <div class="mt-4">
                <router-link
                  to="/sign-up"
                  class="tt-text-green font-medium text-xs"
                  >{{ $t("eshenproifileadsad") }}</router-link
                >
              </div>
            </div>
          </div>
          <Loader class="mt-24" v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import nvgpost from "@/common/nvgpost"
import getUserInfo from "@/common/getUserInfo"
import router from "@/router"
import Logo from "@/components/Static/Logo"
import { MailIcon } from "@vue-hero-icons/outline"
import Input from "@/components/Inter/Input"
import Button from "@/components/Inter/Button"
import Background from "@/components/Static/Background"
import Loader from "@/components/Static/Loader"
import userInfo from "@/common/userInfo"

export default {
  name: "SignIn",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: { Background, Button, Input, Logo, MailIcon, Loader },
  async mounted() {
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      1000
    )

    await this.loadFacebookSDK(document, "script", "facebook-jssdk")
    await this.initFacebook()

    // setTimeout(
    //   function () {
    //     this.recaptcha()
    //   }.bind(this),
    //   700
    // )
  },
  data() {
    return {
      gretoken: false,
      isEmail: false,
      codeLang: localStorage.getItem("codeLang"),
    }
  },
  methods: {
    doEmail: function () {
      this.isEmail = !this.isEmail
    },

    async recaptcha() {
      await this.$recaptchaLoaded()
      this.gretoken = await this.$recaptcha("login")
    },

    async logInWithGoogle() {
      try {
        await this.$gAuth.signIn().then((b) => {
          setTimeout(
            function () {
              let accessToken = undefined
              let idToken = undefined
              let tokenType = undefined

              let lokeys = Object.keys(b)
              for (let i = 0; i < lokeys.length; i++) {
                if (b[lokeys[i]].access_token !== undefined) {
                  accessToken = b[lokeys[i]].access_token
                  idToken = b[lokeys[i]].id_token
                  tokenType = b[lokeys[i]].token_type
                }
              }

              nvgpost(
                this.$apiEndpoint + "/v1/auth/google",
                "POST",
                {
                  access_token: accessToken,
                  id_token: idToken,
                  token_type: tokenType,
                  //gretoken: this.gretoken,
                  lang: this.codeLang,
                },
                {},
                (res) => {
                  if (res.jwt) {
                    let jwt = res.jwt
                    localStorage.setItem("nvg_auth", jwt)
                    getUserInfo(jwt)
                    router.push("/")
                  }
                },
                true
              )
            }.bind(this),
            600
          )
        })
      } catch (error) {
        return null
      }
    },

    async logInWithFacebook() {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            let accessToken = response.authResponse.accessToken
            let user = response.authResponse.userID

            nvgpost(
              this.$apiEndpoint + "/v1/auth/fb",
              "POST",
              {
                access_token: accessToken,
                user: user,
                //gretoken: this.gretoken,
                lang: this.codeLang,
              },
              {},
              (res) => {
                if (res.jwt) {
                  let jwt = res.jwt
                  localStorage.setItem("nvg_auth", jwt)
                  getUserInfo(jwt)
                  router.push("/")
                }
              },
              true
            )
          }
        }.bind(this),
        { scope: "public_profile,email" }
      )
      return false
    },

    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "286813569012289",
          scope: "public_profile,email",
          cookie: true,
          xfbml: true,
          version: "v12.0",
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    },

    async logInWithTeleportravel() {
      let email = document.getElementById("email_email").value
      let password = document.getElementById("email_password").value

      if (email && password) {
        nvgpost(
          this.$apiEndpoint + "/v1/auth/teleportravel",
          "POST",
          {
            email: email,
            password: password,
            //'gretoken': this.gretoken,
          },
          {},
          (res) => {
            if (res.jwt) {
              let jwt = res.jwt
              localStorage.setItem("nvg_auth", jwt)
              getUserInfo(jwt)
              router.push("/")
            } else {
              this.$root.$emit("toShowTrigger", this.$root.$t("nevernieoe"), 2)
            }
          },
          true
        )
      }
    },
  },
}
</script>
