<template>
  <section class="px-5 xl:px-8 mt-5 pt-2 tt-bg-light">
    <vue-tailwind-modal
        :showing="filterModal"
        @close="filterModal = false"
        :showClose="false"
        :backgroundClose="true"
        :css="{modal: 'max-w-2xl'}"
        class="w-1/2 xl:p-8 bg-smoke-400"
    >
      <div class="relative">
        <ButtonX class="absolute top-4 right-4 tt-text-black text-3xl" @wasClicked="filterModal = false" />
      </div>

      <div class="tt-text-black font-semibold">
        {{ $t('filterrrr') }}
      </div>

      <div class="bg-white mt-8 mb-4">

        <div>
          <h3 class="font-semibold">
            {{ $t('itngrfddsf') }}
          </h3>
          <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full mt-3"
                  @change="addTag" @click="addTag" id="tagSelector">
            <option :value="tag" v-for="tag in Object.keys(hTags)" :key="tag"> {{ hTags[tag] }}</option>
          </select>

          <div class="mt-2">
            <Chip v-for="tag in tagsChips" :key="tag" :closeFunction="removeTag" :slotText="tag"
                  shadow="shadow-md" class="mt-2 mr-2" />
          </div>
        </div>

        <div class="mt-6">
          <h3 class="font-semibold">
            {{ $t('lasgndua') }}
          </h3>
          <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full mt-3"
                  @change="addLang" @click="addLang" id="langSelector">
            <option :value="lang" v-for="lang in Object.keys(hLangs)" :key="lang"> {{ hLangs[lang] }}</option>
          </select>

          <div class="mt-2">
            <Chip v-for="lang in langsChips" :key="lang" :closeFunction="removeLang" :slotText="lang"
                  shadow="shadow-md" class="mt-2 mr-2" />
          </div>
        </div>

        <div class="mt-5">
          <h3 class="font-semibold">
            {{ $t('filterrrr43rfdj1') }}:
          </h3>
          <div class="mt-3">
            <select class="bg-white tt-text-black rounded-2xl p-4 font-bold text-sm shadow-sm w-full mt-3"
                    @change="editFilterby" @click="editFilterby" id="filterbySelector">
              <option value="1">{{ $t('fdsrewdfjgoflgw1') }}</option>
              <option value="2">{{ $t('fdsrewdfjgoflgw2') }}</option>
              <option value="3">{{ $t('fdsrewdfjgoflgw3') }}</option>
              <option value="4">{{ $t('fdsrewdfjgoflgw4') }}</option>
              <option value="5">{{ $t('fdsrewdfjgoflgw5') }}</option>
            </select>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="font-semibold">
            {{ $t('dlindasa') }} ({{ $t('km') }})
          </h3>
          <div class="flex items-center justify-start gap-2 mt-3">
            <Input maxlength="120" class="w-full" id="distot" :placeholder="$t('ot00')" :onchangefunc="editDistance"
                   :value="this.distot" />
            <span>-</span>
            <Input maxlength="120" class="w-full" id="distto" :placeholder="$t('do00')" :onchangefunc="editDistance"
                   :value="this.distto" />
          </div>
        </div>

        <div class="mt-6">
          <h3 class="font-semibold">
            {{ $t('pricewqkdfasas') }} (€)
          </h3>
          <div class="flex items-center justify-start gap-2 mt-3">
            <Input maxlength="120" class="w-full" id="priceot" :placeholder="$t('ot00')" :onchangefunc="editPrice"
                   :value="this.priceot" />
            <span>-</span>
            <Input maxlength="120" class="w-full" id="priceto" :placeholder="$t('do00')" :onchangefunc="editPrice"
                   :value="this.priceto" />
          </div>
        </div>

        <div class="flex items-start justify-start gap-4 flex-col mt-6">
          <h3 class="font-semibold">
            {{ $t('dltiekdodmas00') }}
          </h3>

          <label class="inline-flex items-center gap-2 mt-3">
            <input type="radio" value="1" @change="editTimeroute" name="aaradio"
                   :checked="this.timesr !== [] && this.timesr.includes('1')" />
            <span>{{ $t('ho112') }}</span>
          </label>
          <label class="inline-flex items-center gap-2">
            <input type="radio" value="2" @change="editTimeroute" name="aaradio"
                   :checked="this.timesr !== [] && this.timesr.includes('2')"/>
            <span>{{ $t('ho1122') }}</span>
          </label>
          <label class="inline-flex items-center gap-2">
            <input type="radio" value="3" @change="editTimeroute" name="aaradio"
                   :checked="this.timesr !== [] && this.timesr.includes('3')"/>
            <span>{{ $t('ho1123') }}</span>
          </label>
        </div>

      </div>

    </vue-tailwind-modal>
    
    <div class="grid xl:grid-cols-5 items-start gap-8">
      <div class="xl:col-span-3">
        <div class="flex items-center justify-start gap-4 w-full">
          <div class="relative w-full">
            <Input maxlength="255" class="border-transparent w-full text-indent" :onchangefunc="doFilter"
                   :placeholder="$t('search')" itype="Sm" id="filterText" :value="!!tour_country ? tour_country : cname_get" />
            <LocationMarkerIcon class="absolute top-3 left-4 w-5 h-5" />
            <SearchIcon class="absolute top-3 right-3 w-5 h-5 tt-text-green" @click="doFilter" />
          </div>
          <Button class="flex items-center justify-center rounded-full overflow-hidden w-12 h-12 bg-white"
                  @wasClicked="filterModal = true">
            <FilterIcon class="w-6 h-6" />
          </Button>
        </div>

        <div class="mt-5 xl:mt-0">
          <div class="flex items-start xl:items-center justify-start flex-col xl:flex-row gap-3 mt-4" v-if="!!this.tags.toString()">
          <span class="tt-text-gray text-sm">
            {{ $t('itngrfddsf') }}:
          </span>

            <div class="xl:grid xl:grid-cols-3 items-center gap-1 xl:gap-2">
              <Chip class="ml-3 mt-3 xl:mt-0" v-for="(tag, index) in tagsChips" :slotText="tag"
                    :key="'tag'+index" :closeFunction="removeTag" />
            </div>
          </div>
          <div class="flex items-start xl:items-center justify-start flex-col xl:flex-row gap-3 mt-4" v-if="!!this.langs.toString()">
          <span class="tt-text-gray text-sm">
            {{ $t('lasgndua') }}:
          </span>

            <div class="xl:grid xl:grid-cols-3 items-center gap-1 xl:gap-2">
              <Chip class="ml-3 mt-3 xl:mt-0" v-for="(lang, index) in langsChips" :slotText="lang"
                    :key="'lang'+index" :closeFunction="removeLang" />
            </div>
          </div>
          <div class="flex items-start xl:items-center justify-start flex-col xl:flex-row gap-3 mt-4" v-if="!!this.timesr.length">
          <span class="tt-text-gray text-sm">
            {{ $t('dltiekdodmas00') }}:
          </span>

            <div class="xl:grid xl:grid-cols-3 items-center gap-1 xl:gap-2">
              <Chip class="ml-3 mt-3 xl:mt-0" v-for="(timesr, index) in timesrChips" :slotText="timesr"
                    :key="'timesr'+index" :closeFunction="editTimeroute2" />
            </div>
          </div>
          <div class="mt-4" v-if="this.distot !== null || this.distto !== null">
            <span class="tt-text-gray text-sm">
              {{ $t('dlindasa') }} {{ $t('ot00') }} {{ this.distot}} {{ $t('do00') }} {{ this.distto}} {{ $t('km') }}
            </span>
          </div>
          <div class="mt-4" v-if="this.priceot !== null || this.priceto !== null">
            <span class="tt-text-gray text-sm">
              {{ $t('pricewqkdfasas') }} {{ $t('ot00') }} {{ this.priceot}}€ {{ $t('do00') }} {{ this.priceto}} €
            </span>
          </div>
        </div>

        <h2 class="font-bold text-2xl xl:text-4xl mt-12">
          {{ $t('palcfoijedofr') }} {{ this.tours.filtered.length }}
        </h2>

        <div v-if="this.tours.filtered.length === 0" class="my-10">
          <ButtonRouter class="tt-bg-green text-base text-white rounded-lg" size="px-8 py-3" href="/create">
            {{ $t('becomefirst') }}
          </ButtonRouter>
        </div>

        <div class="xl:grid xl:grid-cols-2 gap-5 mt-8" v-if="!!tours.filtered.length">
          <div v-for="(tour,tourIndex) in tours.filtered" :key="'searcher'+tourIndex">
            <TourCard :me="me" :tour="tour" :codeLang="codeLang" :searchCard="true" />
          </div>
        </div>
      </div>

      <div class="xl:col-span-2 shadow-inner rounded-3xl overflow-hidden xl:sticky xl:top-0">
        <MglMap :accessToken="this.accessToken" :mapStyle.sync="this.mapStyle"
                @load="onMapLoadedNew" class="h-screen">
          <MglNavigationControl position="top-right" class="hidden xl:block"/>
          <MglGeolocateControl position="top-right" :trackUserLocation="true" :showUserHeading="true" />
          <MglMarker v-for="(tour, index) in tours.filtered" :key="'markerrr' + index"
                     :coordinates="(tour.startLocation.lng !== undefined)
                  ? [tour.startLocation.lng, tour.startLocation.lat] : tour.startLocation" color="#35B642">
            <MglPopup>
              <div class="px-4 py-6">
                <router-link :to="'/tours/' + tour.country_url + '/' + tour.url">
                  {{ tour.title }}
                </router-link>
              </div>
            </MglPopup>
          </MglMarker>
        </MglMap>
      </div>
    </div>
  </section>
</template>

<script>
import Input from "@/components/Inter/Input";
import { LocationMarkerIcon, SearchIcon, FilterIcon } from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";
import Chip from "@/components/Block/Chip";
import userInfo from "@/common/userInfo";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import nvgpost from "@/common/nvgpost";
import TourCard from "@/components/Block/TourCard";
import ButtonX from "@/components/Inter/ButtonX";
import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup} from "vue-mapbox";


export default {
  name: "SearchBlock",
  components: {
    MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup, ButtonRouter,
    Chip, Button, Input, LocationMarkerIcon, SearchIcon, FilterIcon, TourCard, ButtonX
  },
  data() {
    return {
      accessToken: userInfo.accessToken,
      mapStyle: userInfo.mapStyle,
      codeLang: userInfo.codeLang,
      tour_country: this.$route.params.tour_country,
      country_get: this.$route.query.country,
      cname_get: this.$route.query.name,
      city_get: this.$route.query.city,
      me: {
        uid: userInfo.uid,
      },
      filterModal: false,
      ppos: 0,

      hLangs: {},
      hTags: {},

      distot: null,
      distto: null,
      priceot: null,
      priceto: null,
      filterby: 0,
      langs: [],
      langsChips: [],
      tags: [],
      tagsChips: [],
      timesr: [],
      timesrChips: [],
      tours: {
        filtered: [],
      },
    }
  },
  mounted() {

    setTimeout(function () {
      this.me = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 500)
    setTimeout(function () {
      this.me = {
        uid: userInfo.uid,
      }
      this.codeLang = userInfo.codeLang
    }.bind(this), 1000)

    nvgpost(this.$apiEndpoint+"/v1/mist/create",
        "POST",
        {
          lang: this.codeLang
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
        (res) => {
          this.hLangs = res.langs
          this.hTags = res.tags
          this.hCountries = res.countries

          setTimeout(function () {
            this.doFilter()
          }.bind(this),500)

        }, true)
  },
  methods: {
    async onMapLoadedNew(event) {
      const asyncActions = await event.component.actions
      asyncActions.jumpTo({
        center: [28.844358422889414, 47.02887390948706],
        zoom: 4,
        speed: 1
      })
    },
    doFilter: function () {
      this.ppos++
      if(this.ppos < 100)
      {
        nvgpost(this.$apiEndpoint+"/v1/tours/list",
            "POST",
            {
              searchText: document.getElementById("filterText").value,
              langs: this.langs,
              tags: this.tags,
              lang: this.codeLang,
              timesr: this.timesr,
              dist: [this.distot, this.distto],
              price: [this.priceot, this.priceto],
              filterby: this.filterby,
              country: this.country_get,
              tour_country: this.tour_country,
            },
            {
              'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
            }, (res)=>{
              if(res) {
                this.tours.filtered = res.data.filtered
              }
            }, true)
      }
    },
    addLang: function () {
      let codeLang = document.getElementById("langSelector").value

      if(!this.langs.includes(codeLang)) {
        this.langs.push(codeLang)
        this.langsChips.push(this.hLangs[codeLang])
      }
      this.doFilter()
    },
    removeLang: function (lang) {

      this.langs = this.langs.filter(function(value) {
        return this.hLangs[value] !== lang
      })
      this.langsChips = this.langsChips.filter(function(value){
        return value !== lang
      })
      this.doFilter()
    },
    editTimeroute2: function (timesr) {
      let a = "a"
      this.timesrChips = this.timesrChips.filter(function(value, index) {
        if(value === timesr)
          a = index
        return value !== timesr
      })
      this.timesr = this.timesr.filter(function(value, index) {
        return index !== a
      })

      this.doFilter()
    },
    editTimeroute: function () {
      let a = document.querySelectorAll("input[type='radio']:checked")
      this.timesr = []
      this.timesrChips = []

      for(let i = 0; i < a.length; i++) {
        let b = a[i].getAttribute('value')
        if(!this.timesr.includes(b))
        {
          this.timesr.push(b)
          this.timesrChips.push(a[i].parentElement.querySelector("span").innerText)
        }
      }
      this.doFilter()
    },
    editDistance: function () {
      let a = document.getElementById("distot").value
      let b = document.getElementById("distto").value

      if(a)
        this.distot = a
      else
        this.distot = 0
      if(b)
        this.distto = b
      else
        this.distto = 2001
      this.doFilter()
    },
    editPrice: function () {
      let a = document.getElementById("priceot").value
      let b = document.getElementById("priceto").value

      if(a)
        this.priceot = a
      else
        this.priceot = 0
      if(b)
        this.priceto = b
      else
        this.priceto = 500
      this.doFilter()
    },
    editFilterby: function () {
      this.filterby = document.getElementById("filterbySelector").value
      this.doFilter()
    },
    addTag: function () {
      let a = document.getElementById("tagSelector").value
      if(!this.tags.includes(a)) {
        this.tags.push(a)
        this.tagsChips.push(this.hTags[a])
      }
      this.doFilter()
    },
    removeTag: function (tag) {

      this.tags = this.tags.filter(function(value) {
        return this.hTags[value] !== tag
      })
      this.tagsChips = this.tagsChips.filter(function(value){
        return value !== tag
      })
      this.doFilter()
    },
  }
}
</script>

<style scoped>
.text-indent {
  text-indent: 25px;
}
</style>