<template>
  <section class="xl:px-8 mt-24 xl:mt-28 tt-bg-light">
    <div class="cush-96 xl:h-auto w-full rounded-3xl overflow-hidden relative mx-auto
    drop-shadow-34xl1 filter mt-6">
      <picture>
        <source srcset="../../assets/start.webp" type="image/webp">
        <source srcset="../../assets/start.jpeg" type="image/jpeg">
        <img src="../../assets/start.jpeg" alt="Start Image Family Travel"
             class="cush-96 xl:h-auto object-cover pointer-events-none select-none w-full brightness-60 filter">
      </picture>

      <div class="absolute top-0 left-0 p-5 xl:py-0 xl:px-24 text-white flex items-start justify-end xl:justify-center flex-col gap-6
      h-full w-full xl:w-1/2">
        <h2 class="font-extrabold text-3xl xl:text-6xl" v-html="$t('start1')"></h2>
        <p class="font-medium text-base xl:text-lg" v-html="$t('start2')"></p>
        <div class="flex items-center gap-2 bg-white rounded-2xl flex-col xl:flex-row w-full p-4 xl:p-2">
          <select class="w-full xl:w-auto tt-bg-light tt-text-black rounded-lg p-4 flex-grow"
                  id="searchValue" @change="changeSearchValue" @click="changeSearchValue">
            <option value="" selected disabled>{{ $t('country') }}</option>
            <option :value="country.id" :key="'country'+index" v-for="(country,index) in countries">
              {{ country.title }}
            </option>
          </select>
          <ButtonRouter class="w-full xl:w-auto tt-bg-green text-white flex-grow xl:flex-none"
                        :href="'/search/?country=' + searchValue + '&city=' + searchValue2">
            <SearchIcon />
          </ButtonRouter>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";
import {SearchIcon} from "@vue-hero-icons/outline";
import nvgpost from "@/common/nvgpost";


export default {
  name: "Start",
  components: {ButtonRouter, SearchIcon},
  props: {
    countries: Array,
  },
  data() {
    return {
      cities: [],
      searchValue: "",
      searchValue2: "",
      codeLang: localStorage.getItem("codeLang")
    }
  },
  methods: {
    changeSearchValue: function () {
      this.searchValue = document.getElementById("searchValue").value

      nvgpost(this.$apiEndpoint+"/v1/countries/cities",
          "POST",
          {
            country_id: document.getElementById("searchValue").value,
            lang: this.codeLang,
          },
          {},
          (res) => {
            this.cities = res.data
          }, true)
    },
  }
}
</script>

<style scoped>
  .cush-96 {
    height: 75vh;
    min-height: 470px;
  }
</style>