<template>
  <section class="mt-16 pt-2 tt-bg-light">
    <div class="h-96 xl:h-auto w-full overflow-hidden relative">
      <picture>
        <source srcset="../../assets/become.webp" type="image/webp">
        <source srcset="../../assets/become.jpg" type="image/jpeg">
        <img src="../../assets/become.jpg" alt="Become a creator Teleportravel"
             class="h-96 xl:h-auto object-left object-cover pointer-events-none select-none w-full">
      </picture>

      <div class="absolute top-0 left-0 p-5 xl:py-0 xl:px-24 text-white flex items-start justify-center flex-col gap-6
      h-full w-full xl:w-1/2">
        <h2 class="font-extrabold text-3xl xl:text-5xl">
          {{ $t('become1') }}
        </h2>
        <div class="font-medium text-base xl:text-lg" v-html="$t('become2')"></div>
        <ButtonRouter class="tt-bg-green rounded-lg text-base text-white" size="px-8 py-3" href="/create">
          {{ $t('podrovboren') }}
        </ButtonRouter>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "Become",
  components: {ButtonRouter}
}
</script>