<template>
  <section class="px-5 xl:px-8 grid xl:grid-cols-12 gap-6 mt-16 pt-2 tt-bg-light">
    <strong class="xl:col-start-2 xl:col-span-4 text-lg" v-html="$t('sub1')"></strong>
    <div class="xl:col-end-12 xl:col-span-5">
      <div @submit="doSubscribe" class="flex items-center justify-start flex-col xl:flex-row gap-3">
        <input type="email" maxlength="120" class="bg-white shadow-lg px-4 py-3 tt-text-gray w-full rounded-xl"
               :placeholder="$t('sub2')" @keyup.enter="doSubscribe" id="subemai">
        <Button size="Sm" class="w-full xl:w-auto tt-bg-green text-white rounded-lg" type="button" @wasClicked="doSubscribe">
          {{ $t('sub3') }}
        </Button>
      </div>
      <p class="text-xs mt-2">
        <span>{{ $t('sub4') }} </span>
        <router-link to="/terms-and-privacy-policy" class="tt-text-green">{{ $t('sub5') }}</router-link>
      </p>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Inter/Button";
import nvgpost from "@/common/nvgpost";


export default {
  name: "Subscribe",
  components: {Button},
  data() {
    return {
      gretoken: false,
    }
  },
  mounted() {
    setTimeout(function () {
      this.recaptcha()
    }.bind(this), 700)
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.gretoken = await this.$recaptcha('login')
    },
    doSubscribe: function () {
      let em = document.getElementById("subemai").value
      if(em)
      {
        nvgpost(this.$apiEndpoint+"/v1/mist/subscribe",
            "POST",
            {
              'email': em,
              'gretoken': this.gretoken,
            },
            {}, ()=>{
              this.$root.$emit("toShowTrigger", this.$root.$t('vuasndqwe'), 1)
            }, true)
      }
    },
  }
}
</script>