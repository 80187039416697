import Vue from "vue"
import VueRouter from "vue-router"
import Landing from "@/views/Landing"
import SignIn from "@/views/SignIn"
import SignUp from "@/views/SignUp"
import Profile from "@/views/Profile"
import EditProfile from "@/views/EditProfile"
import Create from "@/views/Create"
import nvgpost from "@/common/nvgpost"
import CreateStep from "@/views/CreateStep"
import Terms from "@/views/Terms"
import TermsMaib from "@/views/TermsMaib"
import Search from "@/views/Search"
import Blog from "@/views/Blog"
import Article from "@/views/Article"
import TourPublic from "@/views/TourPublic"
import Travel from "@/views/Travel"
import PayRedirect from "@/views/PayRedirect";
import ThankYou from "@/views/ThankYou";


Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            component: Landing
        },
        {
            path: "/terms-and-privacy-policy",
            component: Terms
        },
        {
            path: "/terms-payments",
            component: TermsMaib
        },
        {
            path: "/privacy",
            component: Terms
        },
        {
            path: "/:langCode/privacy",
            component: Terms
        },
        {
            path: "/sign-in",
            component: SignIn
        },
        {
            path: "/sign-up",
            component: SignUp
        },
        {
            path: "/create",
            component: Create
        },
        {
            path: "/contributor",
            component: Create
        },
        {
            path: "/:langCode/contributor",
            component: Create
        },
        {
            path: "/blog",
            component: Blog
        },
        {
            path: "/:langCode/blog",
            component: Blog
        },
        {
            path: "/search",
            component: Search
        },
        {
            path: "/tours/:tour_country",
            component: Search
        },
        {
            path: "/tours",
            component: Search
        },
        {
            path: "/:langCode/tours",
            component: Search
        },
        {
            path: "/create/steps",
            component: CreateStep
        },
        {
            path: "/user/:uid",
            component: Profile
        },
        {
            path: "/edit/:uid",
            component: EditProfile
        },
        {
            path: "/edit_tour/:tour_id",
            component: CreateStep
        },
        {
            path: "/tour/:tour_country/:tour_url",
            component: TourPublic
        },
        {
            path: "/tours/:tour_country/:tour_url",
            component: TourPublic
        },
        {
            path: "/ru/tours/:tour_country/:tour_url",
            component: TourPublic
        },
        {
            path: "/tours/ru/:tour_country/:tour_url",
            component: TourPublic
        },
        {
            path: "/article/:article_name",
            component: Article
        },
        {
            path: "/blog/:article_name",
            component: Article
        },
        {
            path: "/blog/:langCode/:article_name",
            component: Article
        },
        {
            path: "/:langCode/blog/:article_name",
            component: Article
        },
        {
            path: "/travel/:tour_id",
            component: Travel
        },
        {
            path: "/tours/:tour_country/:tour_url/thank-you",
            component: ThankYou
        },
        {
            path: "/tour/:tour_country/:tour_url/thank-you",
            component: ThankYou
        },
        {
            path: "/tours/:tour_country/:tour_url/start",
            component: Travel
        },
        {
            path: "/tour/:tour_country/:tour_url/start",
            component: Travel
        },
        {
            path: "/pay",
            component: PayRedirect
        },
        {
            path: "/:langCode/ruta-mestesugarilor",
            component: Landing,
            beforeEnter() {
                window.location.href = "https://ruta-mestesugarilor.teleportravel.com/";
            }
        },
        {
            path: "/ruta-mestesugarilor",
            component: Landing,
            beforeEnter() {
                window.location.href = "https://ruta-mestesugarilor.teleportravel.com/";
            }
        },
        {
            path: "/:langCode/moara-magica",
            component: Landing,
            beforeEnter() {
                window.location.href = "https://moara-magica.teleportravel.com/";
            }
        },
        {
            path: "/moara-magica",
            component: Landing,
            beforeEnter() {
                window.location.href = "https://moara-magica.teleportravel.com/";
            }
        },
        {
            path: "/:langCode/curator",
            component: Landing,
            beforeEnter() {
                window.location.href = "http://teleportravel.tilda.ws/";
            }
        },
        {
            path: "/curator",
            component: Landing,
            beforeEnter() {
                window.location.href = "http://teleportravel.tilda.ws/";
            }
        },

        { path: '/:catchAll(.*)', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['', 'sign-in', 'sign-up', 'user', 'terms-and-privacy-policy', 'terms-payments', 'curator', 'ruta-mestesugarilor', 'moara-magica',
        'create', 'search', 'privacy', 'blog', 'article', 'tour', 'tours', 'contributor']
    let turl = to.path.split("/")[1]
    const turl2 = to.path.split("/")[2]
    if (turl === 'create' && !!turl2)
        turl = "create2"
    const authRequired = !publicPages.includes(turl)
    const loggedIn = !!localStorage.getItem('nvg_auth')

    let tturl4 = false
    if (['tour', 'tours'].includes(turl) && to.path.split("/").length > 4) {
        tturl4 = to.path.split("/")[4] === "start"
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
        nvgpost(Vue.prototype.$apiEndpoint + "/v1/stat/pwa_user",
            "POST",
            {},
            { 'Authorization': 'Bearer ' + localStorage.getItem("nvg_auth"), },
            () => { }, true)
    }

    let temp = localStorage.getItem("backcomeUrl")

    if ((authRequired && !loggedIn) || (tturl4 && !loggedIn)) {
        localStorage.setItem("backcomeUrl", to.path)
        return next('/sign-in')
    }
    else if (temp && !!loggedIn) {
        localStorage.removeItem("backcomeUrl")
        return next(temp)
    }
    else if (['sign-in', 'sign-up'].includes(turl) && !!loggedIn)
        return next('/user/' + localStorage.getItem('uid'))
    next()
})

export default router