<template>
  <section class="px-5 xl:px-8 mt-16 pt-2 tt-bg-light">
    <h2 class="font-bold text-2xl">
      {{ $t('poldsadaarucsa') }}
    </h2>

    <div class="grid xl:grid-cols-4 gap-6 mt-6">
      <router-link :to="'/tours/' + country.url"
                   class="group bg-white rounded-2xl drop-shadow-34xl1 filter overflow-hidden"
           v-for="(country, index) in countries" :key="index+'coutryy2'">
        <div class="overflow-hidden rounded-2xl h-48 p-1">
          <img :src="(!!country.photo) ? ((country.photo.indexOf('http')===-1 && country.photo.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + country.photo : ''"
               :alt="country.title" class="group-hover:scale-110 group-hover:opacity-60 transition transform tt-bg-black
               rounded-2xl object-cover h-48 w-full">
        </div>

        <div class="p-3 mt-1">
          <h3 class="flex items-center justify-start gap-1 text-lg">
            <LocationMarkerIcon class="w-4 h-4" />
            <strong>{{ country.title }}</strong>
          </h3>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import {LocationMarkerIcon} from "@vue-hero-icons/outline";


export default {
  name: "Countries",
  props: {
    countries: Array,
  },
  components: {
    LocationMarkerIcon,
  },
}
</script>