<template>
  <div class="h-screen w-screen tt-bg-lightgray"></div>
</template>

<script>
import nvgpost from "@/common/nvgpost"
import userInfo from "@/common/userInfo"
import router from "@/router"
import { loadStripe } from "@stripe/stripe-js"

export default {
  name: "PayRedirect",
  data() {
    return {
      payInfo: {},
    }
  },
  created() {
    setTimeout(
      function () {
        const _local = localStorage.getItem("payinfo")
        if (_local) {
          this.payInfo = JSON.parse(_local)

          const referal = localStorage.getItem("nvgref")
          const referalType = localStorage.getItem("nvgref_type")
          const referalTour = localStorage.getItem("referal_tour")

          nvgpost(
            this.$apiEndpoint + "/v1/buy/vip",
            "POST",
            {
              tour: this.payInfo.tour_id,
              lang: this.payInfo.codeLang,
              referal: referal,
              referal_type: referalType,
              referal_tour: referalTour,
            },
            { Authorization: "Bearer " + userInfo.jwt },
            async (res) => {
              if (res.answer !== undefined && res.answer === "ok") {
                localStorage.removeItem("payinfo")
                const stripePromise = loadStripe(this.$stripePublic)
                const stripe = await stripePromise
                await stripe.redirectToCheckout({
                  sessionId: res.data.session_id,
                })
              }
            },
            true
          )
        } else {
          setTimeout(function () {
            router.go(-1)
          }, 100)
        }
      }.bind(this),
      100
    )
  },
}
</script>
