<template>
  <div>
    <Navbar class="hidden xl:grid" />
    <Menu />
    <section
      class="tt-bg-light grid xl:grid-cols-12 mt-0 xl:mt-28"
      v-if="!!tour.preview"
    >
      <div class="col-start-2 col-span-10 xl:px-24">
        <TourBanner
          :tcard="false"
          :image="tour.preview"
          :tour="tour"
          :publicPage="true"
          class="xl:rounded-3xl h-64 xl:h-101"
          classCard="h-64 xl:h-101 p-0"
          :daimnef="daimnef"
          :me="user"
          :addWishlist="addWishlist"
          :goToReg="goToReg"
        />
      </div>

      <div
        class="col-start-3 col-span-8 transform -translate-y-5 xl:translate-y-0"
      >
        <div class="tt-bg-light w-full py-8 rounded-3xl">
          <div class="lg:grid lg:grid-cols-2 px-6 xl:px-0">
            <div class="col-span-2 xl:col-span-1">
              <div class="tt-text-black text-lg xl:text-2xl font-bold">
                {{ tour.title }}
              </div>
              <div
                class="tt-text-black text-sm xl:text-base flex items-center mt-1"
              >
                <LocationMarkerIcon class="tt-text-green w-4 h-4" />
                <span class="ml-1">
                  <span>{{ tour.country }}</span>
                  <span
                    v-for="(t, index00) in tour.cities"
                    :key="'ttt' + index00"
                    >, {{ tour.cities[index00] }}</span
                  >
                </span>
              </div>

              <div
                class="tt-text-black text-sm font-medium flex items-center mt-2"
              >
                <StarIcon class="tt-text-yellow w-4 h-4" />
                <span class="ml-1"
                  >{{
                    this.tour.rate === 0 ? "5.0" : this.tour.rate.toFixed(1)
                  }}
                  ({{ this.tour.reviews.length }} {{ $t("otesiendis") }})</span
                >
              </div>
            </div>
            <div class="col-span-2 xl:col-span-1 xl:text-right mt-5 xl:mt-0">
              <div
                v-if="
                  ![0, '0', '0.00'].includes(tour.price) &&
                  !tour.isAdmin &&
                  !tour.isVip &&
                  !tour.paid &&
                  tour.user.uid !== user.uid
                "
                class="flex justify-center items-end flex-col"
              >
                <Button
                  @wasClicked="buy(tour.id)"
                  size="0"
                  class="inline-block tt-text-black text-sm font-bold border border-green rounded-lg overflow-hidden"
                >
                  <div class="xl:grid grid-cols-2 items-center">
                    <div
                      class="px-4 py-2 flex gap-2 justify-center text-center"
                    >
                      <s class="text-sm font-light">{{ tour.price }} €</s>
                      <b>{{ tour.price_discount }} €</b>
                    </div>
                    <div class="tt-bg-green text-white px-4 py-3">
                      <span v-if="tour.user.uid === user.uid">
                        {{ $t("openmartou") }}
                      </span>
                      <span v-else>
                        {{ $t("buyyy") }}
                      </span>
                    </div>
                  </div>
                </Button>

                <div
                  v-if="
                    ![0, '0', '0.00'].includes(tour.price) &&
                    !tour.paid &&
                    tour.user.uid !== user.uid
                  "
                  style="min-width: min(100%, 242px)"
                  class="inline-block whitespace-nowrap overflow-hidden border tt-border-green p-3 tt-text-gray text-sm rounded-lg mt-2 text-center"
                >
                  {{ $t("discount_until") }}
                  <b class="inline-block whitespace-nowrap">{{ sitetimer }}</b>
                </div>

                <div
                  v-if="![0, '0', '0.00'].includes(tour.price)"
                  class="text-xxs tt-text-gray text-left mt-1 flex items-center justify-center gap-1"
                >
                  <div v-html="$t('soasdfrhfdshfgos')"></div>
                  <img
                    class="w-20"
                    src="../assets/guarantee.png"
                    alt="Guarantee sign money back 100%"
                  />
                </div>
              </div>

              <div class="flex items-end justify-center flex-col gap-2" v-else>
                <ButtonRouter
                  :href="'/tours/' + tour_country + '/' + tour_url + '/start'"
                  class="inline-block text-white tt-bg-green rounded-lg text-sm font-bold"
                >
                  {{ $t("openmartou") }}
                </ButtonRouter>

                <div
                  v-if="
                    ![0, '0', '0.00'].includes(tour.price) &&
                    !tour.paid &&
                    tour.user.uid !== user.uid
                  "
                  style="min-width: min(100%, 242px)"
                  class="inline-block whitespace-nowrap overflow-hidden border tt-border-green p-3 tt-text-gray text-sm rounded-lg mt-2 text-center"
                >
                  {{ $t("discount_until") }}
                  <b class="inline-block whitespace-nowrap">{{ sitetimer }}</b>
                </div>

                <div
                  v-if="
                    ![0, '0', '0.00'].includes(tour.price) &&
                    !tour.paid &&
                    tour.user.uid !== user.uid
                  "
                  class="text-xxs tt-text-gray text-left mt-1 flex items-center justify-center gap-1"
                >
                  <div v-html="$t('soasdfrhfdshfgos')"></div>
                  <img
                    class="w-20"
                    src="../assets/guarantee.png"
                    alt="Guarantee sign money back 100%"
                  />
                </div>

                <div
                  v-else
                  class="mt-2 flex items-end justify-end gap-2 space-x-2"
                >
                  <div
                    class="mt-6 text-xs text-gray-400"
                    v-html="$t('soasdfrhfdshfgos')"
                  ></div>

                  <Button
                    @wasClicked="refundBuy(tour.id)"
                    class="bg-white tt-text-black"
                    v-if="![0, '0', '0.00'].includes(tour.price)"
                  >
                    <ReceiptRefundIcon class="w-6 h-6" />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tt-text-gray font-medium px-6 xl:px-0 mt-8 lg:mt-2 mb-8 md:w-8/12"
            style="word-break: break-word"
            v-html="tour.description.replace(/(\r\n|\r|\n)/g, '<br>')"
          ></div>

          <div class="xl:w-1/2 px-6 xl:px-0 py-2">
            <div
              class="flex items-start lg:items-center justify-start flex-col lg:flex-row gap-4 xl:gap-16 tt-text-black"
            >
              <div class="flex flex-col xl:m-0">
                <div class="text-lg">{{ $t("dlindasa") }}:</div>
                <div class="font-semibold flex items-center">
                  <GlobeIcon class="tt-text-sky w-4 minw16 h-4" />
                  <span class="ml-1 whitespace-nowrap"
                    >{{ (tour.km / 1000).toFixed(0) }} {{ $t("km") }}</span
                  >
                </div>
              </div>
              <div class="flex flex-col xl:pl-0 xl:m-0">
                <div class="text-lg">{{ $t("dltiekdodmas00") }}:</div>
                <div class="font-semibold flex items-center">
                  <ClockIcon class="tt-text-blue w-4 minw16 h-4" />
                  <span class="ml-1 whitespace-nowrap">
                    <span v-if="tour.timeroutes[0] === '1'">
                      {{ $t("ho112") }}
                    </span>
                    <span v-if="tour.timeroutes[0] === '2'">
                      {{ $t("ho1122") }}
                    </span>
                    <span v-if="tour.timeroutes[0] === '3'">
                      {{ $t("ho1123") }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="flex flex-col xl:pl-0 xl:m-0">
                <div class="text-lg">{{ $t("newtadp10") }}:</div>
                <div class="font-semibold flex items-center">
                  <ClockIcon class="tt-text-blue w-4 minw16 h-4" />
                  <span class="ml-1 whitespace-nowrap">
                    {{ $t("newtadp101") }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col xl:pl-0 xl:m-0">
                <div class="text-lg">{{ $t("newtadp102") }}:</div>
                <div class="font-semibold flex items-center">
                  <CurrencyDollarIcon class="tt-text-green w-4 minw16 h-4" />
                  <span class="ml-1 whitespace-nowrap">
                    {{ $t("newtadp103_2") }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col xl:pl-0 xl:m-0">
                <div class="text-lg">{{ $t("newtadp104") }}:</div>
                <div class="font-semibold flex items-center">
                  <ClockIcon class="tt-text-blue w-4 minw16 h-4" />
                  <span class="ml-1 whitespace-nowrap">
                    {{ $t("newtadp105") }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="px-6 xl:px-0 mt-8">
            <h2 class="tt-text-black text-lg font-bold">
              {{ $t("chtomjsna") }}
            </h2>
            <div
              class="tt-text-gray font-medium flex items-start justify-start flex-col gap-3 mt-3"
            >
              <div class="flex items-center justify-start">
                <TranslateIcon class="hidden xl:block tt-text-green w-4 h-4" />
                <span class="ml-1"
                  >{{ $t("eusdtdpkrdfnaa") }}: {{ tour.langs.join(", ") }}</span
                >
              </div>
              <div class="flex items-center justify-start">
                <GlobeIcon class="hidden xl:block tt-text-sky w-4 h-4" />
                <span class="ml-1"
                  >{{ $t("mrsauhfsaogtia") }} {{ tour.placesCount }}
                  {{ $t("mdesagtsa") }}</span
                >
              </div>
            </div>
          </div>

          <div
            class="px-6 xl:px-0 mt-8"
            v-if="![0, '0', '0.00'].includes(tour.price)"
          >
            <div class="flex items-center justify-start gap-1">
              <h2 class="tt-text-black text-lg font-bold">
                {{ $t("discountss") }}:
              </h2>
              <div class="group cursor-help relative">
                <QuestionMarkCircleIcon class="w-4 h-4 tt-text-green" />

                <div
                  class="group-hover:block hidden bg-white shadow-xl rounded-2xl p-4 absolute top-0 left-0 w-auto xl:w-96"
                >
                  {{ $t("podskadkasotraxschr2") }}
                </div>
              </div>
            </div>
            <div
              class="tt-text-gray font-medium flex items-start justify-start flex-col gap-3 mt-3"
            >
              <div
                v-for="(disc, indexDisc) in discs"
                :key="'disc' + indexDisc"
                class="flex items-center justify-start"
              >
                {{ disc }}
              </div>
            </div>
          </div>

          <div class="px-6 xl:px-0 mt-8 relative">
            <h2 class="tt-text-black text-lg font-bold">
              {{ $t("photosfrmtha") }}
            </h2>
            <div
              v-if="!!tour.images.length"
              class="xl:col-span-8 overflow-hidden overflow-x-auto mx-auto max-w-xs2 mt-4"
            >
              <div
                v-viewer
                class="flex items-center justify-start gap-4 transform transition"
              >
                <div
                  v-for="(image, index) in tour.images.slice(0)"
                  :key="'imageMain0' + index"
                  class="block tt-bg-green overflow-hidden rounded-lg h-48 w-48 flex-none cursor-pointer"
                >
                  <img
                    :src="
                      (image.indexOf('http') === -1 &&
                      image.indexOf('data:image') === -1
                        ? 'https://scontent.teleportravel.com/img/'
                        : '') + image
                    "
                    :alt="'Tour Photo #' + index"
                    class="text-transparent object-cover w-full h-full select-none"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="px-6 xl:px-0 mt-8">
            <h2 class="tt-text-black text-lg font-bold">
              {{ $t("stfdldsovatcjha") }}
            </h2>
            <div class="mt-4">
              <div class="tt-text-gray text-sm flex items-center">
                <LocationMarkerIcon class="w-4 h-4" />
                <span class="ml-1">{{ tour.country }}, {{ tour.city }}</span>
              </div>
            </div>
            <div class="overflow-hidden rounded-2xl mt-4">
              <MglMap
                :accessToken="this.accessToken"
                :mapStyle.sync="this.mapStyle"
                :interactive="true"
                @load="onMapLoadedNew"
                class="h-nemnogo"
              >
                <MglMarker
                  :coordinates="
                    tour.startLocation !== null
                      ? tour.startLocation.lng !== undefined
                        ? [tour.startLocation.lng, tour.startLocation.lat]
                        : tour.startLocation
                      : ''
                  "
                  color="#35B642"
                />

                <MglGeojsonLayer
                  type="fill"
                  :sourceId="'firstSource'"
                  :layerId="'firstLayer'"
                  :layer="llayaer"
                  :source="geojson"
                ></MglGeojsonLayer>
              </MglMap>
            </div>
          </div>

          <div
            class="px-6 xl:px-0 mt-8"
            v-if="this.tour.paid || this.tour.reviews.length !== 0"
          >
            <h2 class="tt-text-black text-lg font-bold">
              {{ $t("otsrngewna") }}
            </h2>

            <FeedBacker
              :tour="this.tour"
              :doRate="doRate"
              :rates="this.rates"
              :rate="this.rate"
              :tour_id="this.tour_id"
              v-if="this.tour.paid && this.tour.done"
              class="px-4"
            />
            <Reviews :tour="this.tour" />
          </div>

          <div class="px-6 xl:px-0 mt-8 hidden agrid xl:grid-cols-2 gap-8">
            <a
              :href="referal.url"
              target="_blank"
              v-for="(referal, indexRef) in referals"
              :key="'referal' + indexRef"
              class="block rounded-2xl shadow-sm tt-bg-lightgray hover:opacity-75 transition overflow-hidden flex items-center justify-center flex-col"
            >
              <div
                class="bg-white w-full h-full p-4 flex items-start justify-end flex-col gap-2"
              >
                <h3 class="text-2xl font-medium">
                  {{ referal.title }}
                </h3>
                <div class="text-lg">
                  {{ referal.text }}
                </div>
              </div>
              <img
                :src="require('../assets/' + referal.img + '.png')"
                :alt="referal.title"
                class="object-cover w-full h-full select-none"
              />
            </a>
          </div>
        </div>

        <div class="flex items-center justify-center gap-2 mt-8 xl:mt-12">
          <div class="px-6 xl:px-0 hidden xl:block">
            <div
              v-if="
                ![0, '0', '0.00'].includes(tour.price) &&
                !tour.isAdmin &&
                !tour.isVip &&
                !tour.paid &&
                tour.user.uid !== user.uid
              "
              class="flex justify-center"
            >
              <Button
                @wasClicked="buy(tour.id)"
                size="0"
                class="inline-block tt-text-black text-sm font-bold border border-green rounded-lg overflow-hidden"
              >
                <div class="xl:grid grid-cols-2 items-center">
                  <div class="px-4 py-2 justify-center text-center">
                    <s class="font-light">{{ tour.price }} €</s>
                    <b>{{ tour.price_discount }} €</b>
                  </div>
                  <div class="tt-bg-green text-white px-4 py-3">
                    <span v-if="tour.user.uid === user.uid">
                      {{ $t("openmartou") }}
                    </span>
                    <span v-else>
                      {{ $t("buyyy") }}
                    </span>
                  </div>
                </div>
              </Button>
            </div>
          </div>

          <div
            class="flex items-center justify-center flex-col"
            v-if="!tour.inWishlist && tour.user.uid !== user.uid"
          >
            <Button
              @wasClicked="!!user.uid ? addWishlist(tour.id) : goToReg()"
              size="0"
              class="inline-block tt-text-black text-sm font-bold border border-green rounded-lg overflow-hidden"
            >
              <div class="flex items-center justify-center">
                <div class="px-4 py-2 justify-center text-center">
                  {{ $t("vibcvarnoeq") }}
                </div>
                <div class="bg-white tt-text-green px-4 py-3">
                  <HeartIcon class="w-6 h-6" />
                </div>
              </div>
            </Button>
          </div>
        </div>

        <section class="px-6 xl:px-0 mt-20">
          <div>
            <h2 class="font-extrabold text-2xl xl:text-5xl">
              {{ $t("dsoba") }}
            </h2>
          </div>
          <div class="flex items-center justify-center flex-col mt-8">
            <div
              v-for="col in [1, 2, 3, 4, 5]"
              :key="'bb' + col"
              class="w-full border-t tt-border-lightgray"
            >
              <button
                class="flex items-center justify-between hover:bg-white transition w-full px-4 py-10 text-left"
                @click="openColp(col)"
              >
                <span
                  v-html="$t('faq' + col)"
                  class="block w-full text-base text-left self-start place-content-start"
                ></span>
                <div :id="'coalp21' + col" class="transition transform">
                  <PlusIcon class="w-8 h-8" />
                </div>
              </button>
              <div
                class="hidden px-8 py-5 text-sm"
                :id="'col2p' + col"
                v-html="$t('faq' + col + '-answer')"
              ></div>
            </div>
          </div>
        </section>
      </div>

      <div class="px-6 xl:px-0 mt-6 fixed bottom-20 w-full xl:hidden">
        <div
          v-if="
            ![0, '0', '0.00'].includes(tour.price) &&
            !tour.isAdmin &&
            !tour.isVip &&
            !tour.paid &&
            tour.user.uid !== user.uid
          "
          class="flex justify-center"
        >
          <Button
            @wasClicked="buy(tour.id)"
            size="Lg"
            class="inline-block tt-bg-green text-white text-lg font-bold border border-green rounded-lg overflow-hidden w-full xl:w-auto"
          >
            <span v-if="tour.user.uid === user.uid">
              {{ $t("openmartou") }}
            </span>
            <span v-else>
              {{ $t("buyyy") }} - <s class="font-light">{{ tour.price }} €</s>
              <b>{{ tour.price_discount }} €</b>
            </span>
          </Button>
        </div>
      </div>
    </section>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar"
import Menu from "@/components/Control/Menu"
import Footer from "@/components/Block/Footer"
import nvgpost from "@/common/nvgpost"
import TourBanner from "@/components/Static/TourBanner"
import router from "@/router"
import {
  LocationMarkerIcon,
  StarIcon,
  GlobeIcon,
  ClockIcon,
  TranslateIcon,
  HeartIcon,
  PlusIcon,
  CurrencyDollarIcon,
  QuestionMarkCircleIcon,
  ReceiptRefundIcon,
} from "@vue-hero-icons/outline"
import userInfo from "@/common/userInfo"
import ButtonRouter from "@/components/Inter/ButtonRouter"
import Button from "@/components/Inter/Button"
import Loader from "@/components/Static/Loader"
import { MglMap, MglMarker, MglGeojsonLayer } from "vue-mapbox"
import Reviews from "@/components/Block/Reviews"
import daimnef from "@/common/daimnetranslate"
import FeedBacker from "@/components/Block/FeedBacker"

export default {
  name: "TourPublic",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: {
    FeedBacker,
    Reviews,
    ButtonRouter,
    Loader,
    TourBanner,
    Footer,
    Menu,
    Navbar,
    Button,
    LocationMarkerIcon,
    StarIcon,
    GlobeIcon,
    PlusIcon,
    ClockIcon,
    TranslateIcon,
    HeartIcon,
    CurrencyDollarIcon,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    QuestionMarkCircleIcon,
    ReceiptRefundIcon,
  },
  mounted() {
    //Invite to platform referal
    if (this.$route.query.ref) {
      localStorage.setItem("nvgref", this.$route.query.ref)
      localStorage.setItem("nvgref_type", "tour")
    }

    setTimeout(
      function () {
        this.user = {
          uid: userInfo.uid,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          avatar: userInfo.avatar,
        }
        this.codeLang = userInfo.codeLang

        nvgpost(
          this.$apiEndpoint + "/v1/tours/public",
          "POST",
          {
            tour_country: this.tour_country,
            tour_url: this.tour_url,
            lang: this.codeLang,
          },
          { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
          (res) => {
            this.tour = res.data[0]

            if (this.tour.myReview) this.doRate(this.tour.myReview.rate)
            this.tour_id = res.data[0].id
            localStorage.setItem("referal_tour", this.tour_id)

            if (res?.ref === "ok") {
              localStorage.removeItem("nvgref")
              localStorage.removeItem("nvgref_type")
              localStorage.removeItem("referal_tour")
            }
          },
          true
        )
      }.bind(this),
      500
    )
  },
  data() {
    return {
      accessToken: userInfo.accessToken,
      mapStyle: userInfo.mapStyle,
      tour_country: this.$route.params.tour_country,
      tour_url: this.$route.params.tour_url,
      tour_id: 0,
      referals: [
        {
          url: "https://amigocar.md/",
          img: "amigo",
          title: "Amigocar",
          text: this.$root.$t("amigorreferal"),
        },
        {
          url: "https://duckscooters.com/",
          img: "duck",
          title: "Duck",
          text: this.$root.$t("duckgorreferal"),
        },
      ],
      user: {
        uid: userInfo.uid,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        avatar: userInfo.avatar,
      },
      llayaer: {
        type: "fill",
        layout: {},
        paint: {
          "fill-color": "#35B642",
          "fill-opacity": 0.5,
        },
      },

      rates: [0, 0, 0, 0, 0],
      rate: 0,
      codeLang: localStorage.getItem("codeLang"),
      tour: {
        preview: "",
        images: [],
        langs: [],
        placesCount: 0,
        km: 0,
        price: 0,
        time: 0,
        rate: 5,
        reviews: [],
        myReview: "",
        startLocation: [],
        user: {
          uid: "",
        },
      },
      countdownDuration: 3 * 24 * 60 * 60 * 1000,
      remainingTime: 0,
      intervalId: null,
    }
  },
  computed: {
    geojson: function () {
      return {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              this.createGeoJSONCircle(
                this.tour.mLocation,
                this.tour.mLocation2 * 110 + 2,
                50
              ),
            ],
          },
        },
      }
    },
    sitetimer() {
      const days = Math.floor(this.remainingTime / (24 * 60 * 60 * 1000))
      const hours = Math.floor(
        (this.remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      )
      const minutes = Math.floor(
        (this.remainingTime % (60 * 60 * 1000)) / (60 * 1000)
      )
      const seconds = Math.floor((this.remainingTime % (60 * 1000)) / 1000)

      return `${days.toString().padStart(2, "0")}:${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    },
    discs() {
      return this.tour.discounts ? this.tour.discounts : []
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      const endTime = localStorage.getItem("countdownEndTime")
      const currentTime = new Date().getTime()
      this.remainingTime = endTime
        ? endTime - currentTime
        : this.countdownDuration

      // If remainingTime is less than or equal to 0, start the countdown again
      if (this.remainingTime <= 0) {
        this.resetCountdown()
        this.remainingTime = this.countdownDuration
      }

      // Save the updated remainingTime to local storage
      localStorage.setItem("countdownEndTime", currentTime + this.remainingTime)
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    resetCountdown() {
      localStorage.removeItem("countdownEndTime")
    },
    openColp: function (col) {
      let colm = document.getElementById("col2p" + col)
      if (colm.classList.contains("hidden")) colm.classList.remove("hidden")
      else colm.classList.add("hidden")

      let colm2 = document.getElementById("coalp21" + col)
      if (colm2.classList.contains("rotate-45"))
        colm2.classList.remove("rotate-45")
      else colm2.classList.add("rotate-45")
    },
    createGeoJSONCircle: function (center, radiusInKm, points) {
      if (center === undefined || center === null) {
        return false
      }

      if (!points) points = 64

      let coords = {
        latitude: center[1],
        longitude: center[0],
      }

      let km = radiusInKm
      let ret = []
      let distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
      let distanceY = km / 110.574

      let theta, x, y
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI)
        x = distanceX * Math.cos(theta)
        y = distanceY * Math.sin(theta)

        ret.push([coords.longitude + x, coords.latitude + y])
      }
      ret.push(ret[0])
      return ret
    },

    buy: function (tour_id) {
      if (!this.user.uid) {
        localStorage.setItem("backcomeUrl", this.$route.path)
        router.push("/sign-in")
        return false
      }

      localStorage.setItem(
        "payinfo",
        JSON.stringify({
          tour_id: tour_id,
          codeLang: this.codeLang,
        })
      )
      router.push("/pay")
    },

    async onMapLoadedNew(event) {
      const asyncActions = await event.component.actions
      asyncActions.jumpTo({
        center: this.tour.startLocation,
        zoom: 9,
        speed: 1,
      })
    },

    doRate: function (star) {
      this.rates = [0, 0, 0, 0, 0]
      this.rate = star
      for (let i = 0; i <= star; i++) {
        this.rates[i] = 1
      }
    },
    goToReg: function () {
      localStorage.setItem("backcomeUrl", this.$route.path)
      router.push("/sign-in")
    },
    addWishlist: function (tourId) {
      nvgpost(
        this.$apiEndpoint + "/v1/tours/wishlist",
        "POST",
        {
          tourId: tourId,
        },
        { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
        (res) => {
          if (res) {
            this.tour.inWishlist = !this.tour.inWishlist
          }
        },
        true
      )
    },
    daimnef: function (titleObj) {
      return daimnef(titleObj, this.codeLang)
    },
    refundBuy: function (tour_id) {
      nvgpost(
        this.$apiEndpoint + "/v1/buy/refund",
        "POST",
        {
          tour: tour_id,
        },
        { Authorization: "Bearer " + userInfo.jwt },
        (res) => {
          if (res.answer !== undefined && res.answer === "ok") {
            this.$root.$emit(
              "toShowTrigger",
              this.$root.$t("zavkadapbitlapoda"),
              1
            )
          }
        },
        true
      )
    },
  },
}
</script>

<style scoped>
.h-nemnogo {
  height: 50vh;
}

.max-w-xs2 {
  max-width: 85vw;
}

.minw16 {
  min-width: 16px;
}

@media (min-width: 1280px) {
  .max-w-xs2 {
    max-width: 100%;
  }
}
</style>
