<template>
  <div>
    <Navbar class="grid" />
    <Menu />
    <StartCreate />
    <StatCreate />
    <WhoCanBe/>
    <WhatYouNeed />
    <HowMuch />
    <WeHelp />
    <HowToStart />
    <OurHeroes />
    <BecomeCreate />
    <QuestionsCreate />
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import StartCreate from "@/components/Landing/StartCreate";
import Menu from "@/components/Control/Menu";
import StatCreate from "@/components/Landing/StatCreate";
import WhoCanBe from "@/components/Landing/WhoCanBe";
import WhatYouNeed from "@/components/Landing/WhatYouNeed";
import HowToStart from "@/components/Landing/HowToStart";
import Footer from "@/components/Block/Footer";
import HowMuch from "@/components/Landing/HowMuch";
import WeHelp from "@/components/Landing/WeHelp";
import BecomeCreate from "@/components/Landing/BecomeCreate";
import QuestionsCreate from "@/components/Landing/QuestionsCreate";
import OurHeroes from "@/components/Landing/OurHeroes";


export default {
  name: "Create",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {
    QuestionsCreate,
    BecomeCreate,
    WeHelp, HowMuch, Footer, WhoCanBe, StatCreate, StartCreate, Navbar, Menu, WhatYouNeed, HowToStart, OurHeroes}
}
</script>