<template>
  <component :is="!!tcard ? 'router-link' : 'div'"
             :to="'/tours/' + tour.country_url + '/' + tour.url" class="block w-full overflow-hidden relative p-0">
    <img :src="(!!image) ? ((image.indexOf('http')===-1 && image.indexOf('data:image')===-1) ?
    'https://scontent.teleportravel.com/img/' : '' ) + image : ''"
         :alt="tour.title" class="xl:rounded-2xl object-cover w-full" :class="classCard">

    <Button @wasClicked="$router.push('/tours')" size="p-0"
            v-if="this.publicPage"
            class="absolute top-4 left-4 p-0 rounded-lg text-white w-11 h-11 tt-bg-black
                   select-none opacity-75 hover:opacity-100 transition">
      <span class="w-full h-full flex items-center justify-center" >
        <ArrowLeftIcon class="w-5 h-5"/>
      </span>
    </Button>

    <ButtonRouter href="" size="p-0" class="absolute top-4 right-4 p-0 rounded-lg text-white w-11 h-11
                            select-none opacity-75 hover:opacity-100 transition"
            v-if="tour.user.uid !== me.uid"
            :event="''"
            @click.prevent="()=>{ !!me.uid ? addWishlist(tour.id) : goToReg() }"
            :class="!!tour.inWishlist  ? 'tt-bg-green filter hover:tt-text-black'
                                       : 'tt-bg-black filter hover:tt-text-green'">
      <button @click="!!me.uid ? addWishlist(tour.id) : goToReg()" class="w-full h-full flex items-center justify-center" >
        <HeartIcon class="w-5 h-5"/>
      </button>
    </ButtonRouter>

    <ButtonRouter :href="'/edit_tour/' + tour.id" class="absolute p-0 rounded-lg text-white tt-bg-black
                            flex items-center justify-center select-none opacity-75 hover:opacity-100 transition w-11 h-11"
                  v-if="(tour.user.uid === me.uid && tour.status !== 3) || !!tour.isAdmin"
                  :class="(tour.user.uid !== me.uid && tour.isAdmin) ? 'top-4 right-16' : 'top-4 right-4' ">
      <PencilIcon class="w-7 h-7 transform scale-150"/>
    </ButtonRouter>

    <ButtonRouter :href="'/user/' + tour.user.uid"
                  class="absolute bottom-7 left-4 bg-dark-opacity backdrop-filter !backdrop-blur-sm
               p-1 rounded-lg hover:tt-text-green text-white transition">
      <div class="flex items-center justify-start gap-2 w-auto">
        <Avatar :avatar="tour.user.avatar" href="#" size="w-4 h-4" />
        <span class="font-semibold truncate max-w-32">{{ tour.user.firstName }} {{ tour.user.lastName }}</span>
      </div>
    </ButtonRouter>

    <div v-if="tour.user.uid === me.uid && autorStat"
         :class="((tour.status==='1') ? 'bg-red-400' :
      ((tour.status==='2') ? 'tt-bg-blue' :
      ((tour.status==='3') ? 'tt-bg-green' :
      ((tour.status==='4') ? 'bg-red-600' : ''))))
      + (!!searchCard ? '' : ' xl:top-auto xl:left-auto xl:bottom-8 xl:right-4')"
         class="absolute top-5 left-4 text-white text-sm
                    font-bold transition rounded-lg px-3 py-2 whitespace-nowrap">
      {{ (tour.status==='1') ? $t('statudsa32a') :
        ((tour.status==='2') ? $t('statudsa32a2') :
            ((tour.status==='3') ? $t('statudsa32a3') :
                ((tour.status==='4') ? $t('statudsa32a4') : ''))) }}
    </div>
  </component>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Avatar from "@/components/Static/Avatar";
import {PencilIcon, HeartIcon, ArrowLeftIcon} from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";


export default {
  name: "TourBanner",
  props: {
    publicPage: {
      type: Boolean,
      default: false,
    },
    tour: Object,
    me: Object,
    searchCard: {
      type: Boolean,
      default: false,
    },
    classCard: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    autorStat: {
      type: Boolean,
      default: false,
    },
    tcard: {
      type: Boolean,
      default: true,
    },
    daimnef: {
      type: Function,
      default: () => {

      }
    },
    addWishlist: {
      type: Function,
      default: () => {
      }
    },
    goToReg: {
      type: Function,
      default: () => {

      }
    },

  },
  components: {
    Button,
    ButtonRouter, Avatar, PencilIcon, HeartIcon, ArrowLeftIcon,
  }
}
</script>

<style scoped>
  .h-1772 {
    height: 17rem;
  }
</style>