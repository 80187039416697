let userHash = localStorage.getItem("usrha")
let firstName = localStorage.getItem("firstName")
let lastName = localStorage.getItem("lastName")
let avatar = localStorage.getItem("avatar")
let uid = localStorage.getItem("uid")
let jwt = localStorage.getItem("nvg_auth")
let accessToken = "pk.eyJ1IjoidGVsZXBvcnRyYXZlbCIsImEiOiJja3VzaDZxaXYwZXZ1Mm5tZmhxb2pncjFtIn0.3AFglpii2buTQT_og_JtiA"
let mapStyle = "mapbox://styles/mapbox/streets-v11"
let codeLang = localStorage.getItem('codeLang')
let dict = {}
try {
    dict[codeLang] = JSON.parse(localStorage.getItem('dict'))
}
catch (e) {
    console.error("dicteccodelang")
}


export default { userHash, firstName, lastName, jwt, avatar, uid, accessToken, mapStyle, dict, codeLang}