<template>
  <section
    class="px-5 xl:px-8 mt-16 pt-2 tt-bg-light"
    v-if="!!this.articles.length"
  >
    <h2 class="font-bold text-2xl">
      {{ $t("usvdfidsrelfewsgtifcles") }}
    </h2>

    <div class="w-full maxhm rounded-2xl relative mt-6">
      <img
        :src="this.articles[0].preview"
        :alt="this.articles[0].title"
        class="w-full maxhm tt-bg-black object-cover rounded-2xl select-none"
      />

      <div
        class="tt-bg-green p-3 xl:p-5 absolute bottom-2 left-2 xl:bottom-6 xl:left-6 rounded-2xl w-64 xl:w-80"
      >
        <h2 class="font-medium text-lg xl:text-2xl text-white">
          {{ this.articles[0].title }}
        </h2>

        <p
          class="text-white text-xs xl:text-sm mt-4"
          v-html="
            weboaMD(
              this.articles[0].short
                .replace(/<img[^>]*>/g, '')
                .replace('<p></p>', '')
                .replace('<p>&nbsp;</p>', '')
            )
          "
        ></p>

        <ButtonRouter
          :href="'/article/' + this.articles[0].url"
          class="text-white border border-white mt-3 xl:mt-5 rounded-lg"
        >
          {{ $t("redmemofrekf") }}
        </ButtonRouter>
      </div>
    </div>
  </section>
</template>

<script>
import nvgpost from "@/common/nvgpost"
import userInfo from "@/common/userInfo"
import ButtonRouter from "@/components/Inter/ButtonRouter"

export default {
  name: "TheArticle",
  components: { ButtonRouter },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      articles: [],
    }
  },
  mounted() {
    setTimeout(
      function () {
        this.codeLang = userInfo.codeLang

        nvgpost(
          this.$apiEndpoint + "/v1/blog/articles",
          "POST",
          {
            lang: this.codeLang,
            limit: 1,
          },
          {
            Authorization: "Bearer " + localStorage.getItem("nvg_auth"),
          },
          (res) => {
            if (res) {
              if (res.data) this.articles = res.data
            }
          },
          true
        )
      }.bind(this),
      500
    )
  },
  methods: {
    weboaMD: function (text) {
      try {
        let a = JSON.parse(text)
        return a.html.join("\n")
      } catch (e) {
        return text
      }
    },
  },
}
</script>

<style>
.maxhm {
  height: 467px;
}
</style>
