<template>
  <button class="text-xs font-bold rounded-lg inline-flex items-center justify-center select-none transition hover:opacity-70"
          :class="
          (this.size === 'Lg' ? 'py-4 px-6' : '') + ' '
          + (this.size === 'Sm' ? 'py-3 px-4' : '') + ' '
          + (this.size === 'Mega' ? 'py-6 px-8' : '') + ' '
          + (this.size === '0' ? 'p-0' : '') + ' '
          "
          @click="$emit('wasClicked')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: "Sm",
    },
  }
}
</script>
