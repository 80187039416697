<template>
  <picture>
    <source srcset="../../assets/bgreg.webp" type="image/webp">
    <source srcset="../../assets/bgreg.png" type="image/png">
    <img src="../../assets/bgreg.png" alt="Background Travel"
         class="h-screen object-cover pointer-events-none select-none w-full">
  </picture>
</template>

<script>
export default {
  name: "Background"
}
</script>