<template>
  <section class="mt-16 pt-2 tt-bg-light">
    <div class="w-full overflow-hidden relative hcustom">
      <picture>
        <source srcset="../../assets/curator.webp" type="image/webp">
        <source srcset="../../assets/curator.jpeg" type="image/jpeg">
        <img src="../../assets/curator.jpeg" alt="Let Us Plan Your Trip For You"
             class="hcustom2 filter brightness-75 object-left object-cover pointer-events-none select-none w-full transform xl:-translate-y-64">
      </picture>

      <div class="absolute top-0 left-0 p-5 xl:py-0 xl:px-24 text-white flex items-start justify-center
      flex-col gap-2 md:gap-6
      h-full w-full w-1/2">
        <h2 class="font-extrabold text-lg md:text-3xl xl:text-5xl shadow-md">
          {{ $t('letuasplayer') }}
        </h2>
        <div class="font-medium text-sm md:text-base xl:text-lg shadow-md" v-html="$t('letuspaletytotstptext')"></div>
        <ButtonRouter class="tt-bg-green rounded-lg text-base text-white" size="px-8 py-3" href="/curator">
          {{ $t('podrovboren') }}
        </ButtonRouter>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "Become",
  components: {ButtonRouter}
}
</script>
<style scoped>
@media (min-width: 1280px) {
  .hcustom {
    height: 30rem !important;
  }
}

@media (max-width: 1280px) {
  .hcustom2 {
    height: 16rem !important;
  }
}

.hcustom2 {
  height: auto;
}

.hcustom {
  height: 16rem;
}
</style>