<template>
  <div>
    <Navbar class="grid" />
    <Menu />

    <section class="px-5 xl:px-8 mt-24 xl:mt-28 xl:grid xl:grid-cols-4" v-if="this.user.firstName">
        <div class="shadow-sm border rounded-3xl bg-white p-8 xl:col-start-2 xl:col-span-2">
          <h3 class="tt-text-gray text-sm">
            {{ $t('yorbagfdsih') }}
          </h3>

          <div class="mt-4 flex items-start justify-start flex-col gap-6">
            <Banner :image="this.user.banner" class="w-32 max-h-32" />

            <Button @wasClicked="collapsePlace(['collapse'], 'Banner', 'max-h-0')"
                    class="rounded-lg border tt-border-gray px-5 py-3 flex items-center justify-center gap-2">
              <span class="text-sm">{{ $t('cahgnegeba') }}</span>
              <PencilAltIcon class="w-4 h-4" />
            </Button>

            <vue-anka-cropper
                id="collapseBanner"
                class="w-full max-h-0 overflow-hidden transition-all"
                :options="{
               aspectRatio: false,
               closeOnSave: true,
               cropArea: 'box',
               croppedHeight: 772,
               croppedWidth: 2880,
               cropperHeight: false,
               frameLineDash: [5,3],
               frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
               handleFillColor: 'rgba(255, 255, 255, 0.2)',
               handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
               handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
               handleSize: 6,
               handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
               layoutBreakpoint: 850,
               maxCropperHeight: 2000,
               maxFileSize: 16000000,
               overlayFill: 'rgba(0, 0, 0, 0.5)',
               previewOnDrag: true,
               previewQuality: 1,
               resultQuality: 1,
               resultMimeType: 'image/jpeg',
               selectButtonLabel: $t('cahgnegeba'),
               dropareaMessage: $t('sdorprpfdielkesd'),
               showPreview: false,
               skin: 'tt',
               uploadData: {},
               uploadTo: false}"
                @cropper-error="ankaError"
                @cropper-file-selected="onAnkaOpened"
                @cropper-saved="onFileSelected1"></vue-anka-cropper>
          </div>

          <h3 class="tt-text-gray text-sm mt-12">
            {{ $t('youfpictea') }}
          </h3>
          <div class="mt-4 flex items-start justify-start flex-col gap-6">
            <Avatar :avatar="this.user.avatar" href="#" size="w-16 h-16" />

            <Button @wasClicked="collapsePlace(['collapse'], 'Avatar', 'max-h-0')"
                    class="rounded-lg border tt-border-gray px-5 py-3 flex items-center justify-center gap-2">
              <span class="text-sm">{{ $t('hcasngevata') }}</span>
              <PencilAltIcon class="w-4 h-4" />
            </Button>

            <vue-anka-cropper
                id="collapseAvatar"
                class="w-full max-h-0 overflow-hidden transition-all"
                :options="{
               aspectRatio: 1,
               closeOnSave: true,
               cropArea: 'circle',
               croppedHeight: 400,
               croppedWidth: 400,
               cropperHeight: false,
               frameLineDash: [5,3],
               frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
               handleFillColor: 'rgba(255, 255, 255, 0.2)',
               handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
               handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
               handleSize: 6,
               handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
               layoutBreakpoint: 850,
               maxCropperHeight: 768,
               maxFileSize: 16000000,
               overlayFill: 'rgba(0, 0, 0, 0.5)',
               previewOnDrag: true,
               previewQuality: 0.65,
               resultQuality: 0.8,
               resultMimeType: 'image/jpeg',
               selectButtonLabel: $t('hcasngevata'),
               dropareaMessage: $t('sdorprpfdielkesd'),
               showPreview: false,
               skin: 'tt',
               uploadData: {},
               uploadTo: false}"
                              @cropper-error="ankaError"
                              @cropper-file-selected="onAnkaOpened"
                              @cropper-saved="onFileSelected2"></vue-anka-cropper>
          </div>

          <h3 class="tt-text-gray text-sm mt-12">
            {{ $t('lasgndua') }}
          </h3>
          <div class="mt-4 flex items-start xl:items-center justify-start flex-col xl:flex-row gap-6">
            <ChangeLang sid="langchecker2"/>
          </div>

          <h3 class="tt-text-gray text-sm mt-12"
              v-if="!!this.user.teleport">
            {{ $t('smenapsaoro') }}
          </h3>
          <div class="mt-4 flex items-start xl:items-center justify-start flex-col gap-4"
               v-if="!!this.user.teleport">
            <Input type="password" maxlength="255" class="block w-full" id="pswCur" :placeholder="$t('smenapsaoro0')" />
            <Input type="password" maxlength="255" class="block w-full" id="pswNew1" :placeholder="$t('smenapsaoro1')" />
            <Input type="password" maxlength="255" class="block w-full" id="pswNew2" :placeholder="$t('smenapsaoro2')" />

            <div class="xl:grid xl:grid-cols-2 w-full">
              <div>
                <Button class="rounded-lg tt-bg-green text-white" @wasClicked="changePassword">{{ $t('smenapsaoro4') }}</Button>
              </div>
            </div>
          </div>

          <div class="mt-12 flex items-center justify-between flex-col xl:flex-row gap-5">
            <div class="w-full">
              <h3 class="tt-text-gray text-sm mt-4">
                {{ $t('fname') }}
              </h3>
              <Input maxlength="255" class="block w-full mt-4" id="fname" :value="this.user.firstName" />
            </div>
            <div class="w-full">
              <h3 class="tt-text-gray text-sm mt-4">
                {{ $t('lname') }}
              </h3>
              <Input maxlength="255" class="block w-full mt-4" id="lname" :value="this.user.lastName" />
            </div>
          </div>

          <div class="mt-10">
            <h3 class="tt-text-gray text-sm mt-4">
              {{ $t('abome') }}
            </h3>
            <h3 class="tt-text-gray text-sm mt-2">
              {{ $t('dmsicxm8sajdasn') }}
            </h3>
            <Textarea id="biome" class="block w-full mt-4" rows="5" maxlength="250" />
            <div id="hiddenBio" class="hidden" v-html="this.user.bio"></div>
          </div>

          <div class="mt-10">
            <Button class="rounded-lg tt-bg-green text-white" @wasClicked="saveProfile">{{ $t('savejcsnja') }}</Button>
          </div>
        </div>
    </section>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import nvgpost from "@/common/nvgpost";
import router from "@/router";
import vueAnkaCropper from 'vue-anka-cropper'
import Menu from "@/components/Control/Menu";
import Footer from "@/components/Block/Footer";
import Loader from "@/components/Static/Loader";
import Banner from "@/components/Static/Banner";
import Button from "@/components/Inter/Button";
import {PencilAltIcon} from "@vue-hero-icons/outline";
import Avatar from "@/components/Static/Avatar";
import Input from "@/components/Inter/Input";
import Textarea from "@/components/Inter/Textarea";
import ChangeLang from "@/components/Control/ChangeLang";
import userInfo from "@/common/userInfo";
import animations from "@/common/animations";


export default {
  name: "Profile",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        { name: 'description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { name: 'og;description', content:  'Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов'},
        { property: 'og:title', content: "Teleportravel"},
        { property: 'og:site_name', content: 'Teleportravel'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {ChangeLang, Textarea, Input, Avatar, Button, Footer,
    Navbar,Menu,Loader,Banner,PencilAltIcon,vueAnkaCropper },
  data() {
    return {
      uid: this.$route.params.uid,
      user: {},
      draggedAvatar: false,
      draggedBanner: false,
    }
  },
  computed: {
    biome: function () {
      return document.getElementById("hiddenBio") ? document.getElementById("hiddenBio").innerText : ""
    },
    countOfAnka: function () {
      return document.querySelectorAll(".ankaCropper__fileInput").length
    },
  },
  watch: {
    countOfAnka: function () {
      let ankas = document.querySelectorAll(".ankaCropper__fileInput")
      for (let i = 0; i < ankas.length; i++) {
        ankas[i].setAttribute("accept", ".jpg, .jpeg, .png")
      }
    }
  },
  methods: {
    collapsePlace: animations.collapsePlace,
    changePassword: function () {
      let payload = {
        uid: this.user.uid,
        psw_cur: document.getElementById("pswCur").value,
        psw_new1: document.getElementById("pswNew1").value,
        psw_new2: document.getElementById("pswNew2").value,
      }

      if(payload.psw_new1 !== payload.psw_new2)
      {
        this.$root.$emit("toShowTrigger", this.$root.$t('sminaepi2'), 2)
        return false
      }

      nvgpost(this.$apiEndpoint+"/v1/change/password",
          "POST",
          payload,
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res) => {
            if(res)
            {
              document.getElementById("pswCur").value = ""
              document.getElementById("pswNew1").value = ""
              document.getElementById("pswNew2").value = ""

              if(res.answer === "success") {
                this.$root.$emit("toShowTrigger", this.$root.$t('sminaepi0'), 1)
              }
              else {
                this.$root.$emit("toShowTrigger", this.$root.$t('sminaepi1'), 2)
              }
            }
          }, true)
    },
    saveProfile: function (ttype) {
      let payload = {
        uid: this.user.uid,
      }

      if(this.draggedAvatar)
        payload["avatar"] = this.user.avatar
      if(this.draggedBanner)
        payload["banner"] = this.user.banner

      if(ttype !== 'ava') {
        payload["firstName"] = document.getElementById("fname").value
        payload["lastName"] = document.getElementById("lname").value
        payload["bio"] = document.getElementById("biome").value
      }

      nvgpost(this.$apiEndpoint+"/v1/users/save",
          "POST",
          payload,
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res) => {
            if(res)
            {
              if(this.draggedAvatar)
                userInfo.avatar = this.user.avatar

              if(ttype !== 'ava') {
                router.push("/user/" + this.user.uid)
                this.$root.$emit("toShowTrigger", this.$root.$t('visneodueansodhaitvirt'), 1)
              }


            }
          }, true)
    },
    changeCallbackBg: function (selectedFile, ttype) {
      if(ttype === 1) {
        this.user.banner = selectedFile.croppedImageURI
        this.collapsePlace(['collapse'], 'Banner', 'max-h-0')
      }
      else if(ttype === 2) {
        this.user.avatar = selectedFile.croppedImageURI
        this.collapsePlace(['collapse'], 'Avatar', 'max-h-0')
      }
      this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq0'), 1)

      this.saveProfile('ava')
    },
    onFileSelected1: function (file) {
      this.draggedBanner = true
      this.changeCallbackBg(file, 1)
    },
    onFileSelected2: function (file) {
      this.draggedAvatar = true
      this.changeCallbackBg(file, 2)
    },
    onAnkaOpened: function (file) {
      if(!file)
        this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq_error'), 2)


      setTimeout(function () {
        document.querySelector(".ankaCropper__saveButton").innerText = this.$root.$t('save0')
      }.bind(this), 500)
    },
    ankaError: function (errorMessage) {
      console.log(errorMessage)
      this.$root.$emit("toShowTrigger", this.$root.$t('fulesazgureq_error'), 2)
    }
  },
  mounted() {
    nvgpost(this.$apiEndpoint+"/v1/users/info",
        "POST",
        {
          uid: this.uid
        },
        {}, (res) => {
          if(res.firstName === undefined || res.lastName === undefined)
            console.log("Some error")

          if(res.firstName !== undefined && res.lastName !== undefined) {
            this.user = {
              firstName: res.firstName,
              lastName: res.lastName,
              email: res.email,
              avatar: res.avatar,
              banner: res.banner,
              uid: res.uid,
              bio: res.bio,
              teleport: res.teleport
            }


            setTimeout(function () {
              document.getElementById("biome").value = this.biome

              let ankas = document.querySelectorAll(".ankaCropper__fileInput")
              for (let i = 0; i < ankas.length; i++) {
                ankas[i].setAttribute("accept", ".jpg, .jpeg, .png")
              }
            }.bind(this), 1000)
            setTimeout(function () {
              document.getElementById("biome").value = this.biome
            }.bind(this), 2000)
          }
        }, true)


  },
}
</script>