<template>
  <div class="px-6 xl:px-0 mt-2" id="formReview">
    <Textarea rows="4" itype="Sm" :placeholder="$t('writoyuanspgtrrfvierw')"
              id="reviewText" class="w-full"
              :disabled="!!tour.myReview"
              :value="!!tour.myReview ? tour.myReview.text : ''"></Textarea>
    <div class="flex items-center justify-start gap-2 mt-1 mb-2">
      <Button class="rounded-lg justify-start tt-text-yellow transition group p-1"
              size="0"
              v-for="star in [0,1,2,3,4]" :key="star" :id="'buttonRate' + star"
              @wasClicked="(!tour.myReview) ? doRate(star) : ()=>{}">
        <StarIcon class="transition w-8 h-8"
                  :class="rates[star]===1 || parseInt(tour.myReview.rate)>=star+1
                            ? 'fill-current' : 'group-hover:fill-current'" />
      </Button>
    </div>
    <div class="mt-4">
      <Button class="rounded-lg tt-bg-green text-white"
              @wasClicked="sendReview()" v-if="!tour.myReview">
        {{ $t('otrraovitpsivd') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Textarea from "@/components/Inter/Textarea";
import Button from "@/components/Inter/Button";
import {StarIcon} from "@vue-hero-icons/outline";
import nvgpost from "@/common/nvgpost";


export default {
  name: "FeedBacker",
  components: {
    Textarea, Button, StarIcon,
  },
  props: {
    tour: Object,
    rates: Array,
    rate: {
      type: [Number, String],
      default: 0,
    },
    tour_url: {
      type: [String],
      default: "",
    },
    doRate: Function,
  },
  methods: {
    sendReview: function () {
      const rate = this.rate
      const text = document.getElementById("reviewText").value

      if(this.tour_url === "")
        return false;

      nvgpost(this.$apiEndpoint+"/v1/tours/review",
          "POST",
          {
            rate: rate+1,
            text: text,
            tourUrl: this.tour_url,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res) {
              if(res.status==="success") {
                this.$root.$emit("toShowTrigger", this.$root.$t('soobniewsbilopraveno'), 1)
                document.getElementById("formReview").parentElement.innerHTML = ""
              }
              else {
                this.$root.$emit("toShowTrigger", this.$root.$t('viujevoograli'), 2)
              }
            }
          }, true)
    },
  }
}
</script>