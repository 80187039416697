<template>
  <Button
          class="tt-text-green border border-dashed tt-border-gray px-5 py-3 cursor-pointer
          flex items-center justify-center flex-col relative h-32 gap-2">
    <UploadIcon class="w-6 h-6"/>
    <span class="text-sm tt-text-green">{{ $t('addmedia') }}</span>
    <input type="file" class="absolute top-0 z-10 left-0 w-full h-full opacity-0 cursor-pointer"
           :id="inputId" multiple :accept="accept" @change="changeCallback">
  </Button>
</template>

<script>
import Button from "@/components/Inter/Button";
import {UploadIcon} from "@vue-hero-icons/outline";


export default {
  name: "Uploader",
  components: {
    Button, UploadIcon
  },
  props: {
    inputId: String,
    accept: String,
    changeCallback: Function,
  },
  methods: {
    addImage: function (iid) {
      document.getElementById(iid).click()
    },
  }
}
</script>