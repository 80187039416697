<template>
  <select class="bg-transparent !ml-4" @change="changeLang" :id="sid">
    <option value="ru" :selected="this.codeLang==='ru'">Ru</option>
    <option value="ro" :selected="this.codeLang==='ro'">Ro</option>
    <option value="en" :selected="this.codeLang==='en'">En</option>
  </select>
</template>

<script>
import userInfo from "@/common/userInfo";

export default {
  name: "ChangeLang",
  props: {
    sid: {
      type: String,
      default: "langchecker",
    },
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang")
    }
  },
  mounted() {

    setTimeout(function () {
      this.codeLang = localStorage.getItem("codeLang")
    }.bind(this), 500)
    setTimeout(function () {
      this.codeLang = localStorage.getItem("codeLang")
    }.bind(this), 1000)
  },
  methods: {
    changeLang: function () {
      userInfo.codeLang = document.getElementById(this.sid).value
      localStorage.setItem("codeLang", userInfo.codeLang)
      localStorage.removeItem("dict")
      if(this.sid==="langchecker") {
        window.location.reload()
      }
    },
  }
}
</script>