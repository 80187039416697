<template>
  <section class="mx-5 xl:mx-12 grid xl:grid-cols-4 items-center gap-6 mt-20">
    <div class="xl:col-start-2 xl:col-span-2 flex items-center justify-between">
      <div class="flex items-center justify-center flex-col gap-6">
        <h4 class="font-medium text-2xl xl:text-7xl">
          {{ this.stat[1] }}+
        </h4>
        <h5 class="tt-text-gray">
          {{ $t('p20tt') }}
        </h5>
      </div>
      <div class="flex items-center justify-center flex-col gap-6">
        <h4 class="font-medium text-2xl xl:text-7xl">
          {{ this.stat[0] }}+
        </h4>
        <h5 class="tt-text-gray">
          {{ $t('p51tt') }}
        </h5>
      </div>
      <div class="flex items-center justify-center flex-col gap-6">
        <h4 class="font-medium text-2xl xl:text-7xl">
          {{ this.stat[2] }}+
        </h4>
        <h5 class="tt-text-gray">
          {{ $t('p8tt') }}
        </h5>
      </div>
    </div>
  </section>
</template>

<script>
import nvgpost from "@/common/nvgpost";


export default {
  name: "StatCreate",
  data() {
    return {
      stat: []
    }
  },
  mounted() {
    nvgpost(this.$apiEndpoint+"/v1/mist/createstat",
        "POST",
        {},
        {
          'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
        }, (res)=>{
          if(res) {
            this.stat = res.data
          }
        }, true)
  },
}
</script>