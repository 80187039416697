<template>
  <div class="mb-32">
    <Navbar class="hidden xl:grid" :nonav="true" />
    <div v-if="this.tour.paid" class="xl:grid xl:grid-cols-12 mt-0 xl:mt-16">
      <section
        v-if="this.step === 1"
        class="xl:col-start-2 xl:col-span-10 relative rounded-2xl"
      >
        <div class="mt-2 p-2 rounded-2xl bg-white">
          <div class="overflow-hidden rounded-2xl">
            <MglMap
              :accessToken="this.accessToken"
              :mapStyle.sync="this.mapStyle"
              @load="onMapLoadedNew"
              class="h-72 xl:h-96 relative rounded-2xl"
            >
              <MglNavigationControl
                position="top-right"
                class="hidden xl:block"
              />
              <MglGeolocateControl
                position="top-right"
                :trackUserLocation="true"
                :showUserHeading="true"
              />
              <MglMarker
                v-for="(pp, ppi) in this.tour.places"
                :key="'pp' + ppi"
                :coordinates="pp.coordinates"
                color="#35B642"
              />
            </MglMap>
          </div>

          <div class="p-4">
            <h2
              class="tt-text-green flex items-center justify-start gap-1 text-sm"
            >
              <LocationMarkerIcon class="w-4 h-4" />
              <span>{{ this.tour.country }}, {{ this.tour.city }}</span>
            </h2>
            <h1 class="text-lg font-bold mt-1">
              {{ this.tour.title }}
            </h1>

            <div class="mt-8">
              <div v-for="(ppn, ppni) in this.tour.places" :key="'ppn' + ppni">
                {{ ppni + 1 }}. {{ daimnef(ppn.name) }}
              </div>
            </div>

            <hr class="my-4" v-if="tour.discounts_contact" />

            <div
              v-if="tour.discounts_contact"
              v-html="tour.discounts_contact.replace(/(\r\n|\r|\n)/g, '<br>')"
              style="word-break: break-word"
              class="my-4 text-sm break-words"
            ></div>
          </div>
        </div>

        <Button
          class="rounded-lg tt-bg-green text-white gap-2 mt-6 mx-5 xl:mx-0"
          @wasClicked="doStep()"
        >
          <span>{{ $t("startimahre") }}</span>
          <ArrowRightIcon class="w-4 h-4" />
        </Button>
      </section>
      <section
        v-for="(place, index) in tour.places"
        :key="'place' + index"
        class="xl:col-start-2 xl:col-span-10 relative rounded-2xl"
      >
        <div class="mt-16" v-if="step === index + 2">
          <div class="p-5 bg-white fixed top-0 xl:top-20 left-0 z-30 w-full">
            <div class="flex items-center justify-start gap-6 xl:gap-3">
              <div class="order-1 xl:order-2">
                <h1 class="font-semibold">
                  {{ tour.title }}
                </h1>
                <h2 class="text-sm tt-text-gray">
                  {{ index + 1 }}. {{ daimnef(place.name) }}
                </h2>
              </div>
            </div>
          </div>

          <div
            class="bg-white drop-shadow-2xl overflow-hidden p-6 rounded-lg md:mt-32"
          >
            <div class="overflow-hidden rounded-2xl">
              <MglMap
                :accessToken="accessToken"
                :mapStyle.sync="mapStyle"
                @load="onMapLoadedNew2"
                class="h-72 xl:h-96 relative rounded-2xl"
              >
                <MglNavigationControl
                  position="top-right"
                  class="hidden xl:block"
                />
                <MglGeolocateControl
                  position="top-right"
                  :trackUserLocation="true"
                  :showUserHeading="true"
                />
                <MglMarker
                  :coordinates="
                    place.coordinates.lng !== undefined
                      ? [place.coordinates.lng, place.coordinates.lat]
                      : place.coordinates
                  "
                  color="#35B642"
                />
              </MglMap>
            </div>

            <div
              class="flex items-start gap-4 mb-5 mt-8"
              :class="isAndroid || isIOS ? 'justify-center' : 'justify-start'"
            >
              <a
                target="_blank"
                class="p-3 rounded-lg tt-bg-green text-white text-sm font-bold"
                :href="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  (place.coordinates.lng !== undefined
                    ? place.coordinates.lat
                    : place.coordinates[1]) +
                  ',' +
                  (place.coordinates.lng !== undefined
                    ? place.coordinates.lng
                    : place.coordinates[0])
                "
              >
                {{ $t("oewpnewqiq") }}
              </a>

              <a
                target="_blank"
                v-if="isAndroid || isIOS"
                class="xl:hidden p-3 rounded-lg tt-bg-green text-white text-sm font-bold"
                :href="
                  (isIOS ? 'http://maps.apple.com/?ll=' : 'geo:') +
                  (place.coordinates.lng !== undefined
                    ? place.coordinates.lat
                    : place.coordinates[1]) +
                  ',' +
                  (place.coordinates.lng !== undefined
                    ? place.coordinates.lng
                    : place.coordinates[0]) +
                  (isAndroid ? '?z=16' : '')
                "
              >
                {{ $t("oewpnewqiq2") }}
              </a>
            </div>

            <div class="flex items-start justify-start flex-col mt-14 gap-4">
              <div
                v-for="(element, index2) in place.elements"
                :key="'ele' + index2"
              >
                <div
                  v-if="element.name === 'description'"
                  v-html="
                    daimnef(element.values).replace(/(\r\n|\r|\n)/g, '<br>')
                  "
                  style="word-break: break-word"
                  class="break-words"
                ></div>

                <div
                  class="flex items-center justify-center rounded-2xl overflow-hidden relative !w-5/6 xl:w-1/2 mx-auto xl:mx-0 mt-8"
                  v-viewer
                  v-else-if="element.name === 'image'"
                >
                  <img
                    :src="
                      'https://scontent.teleportravel.com/img/' +
                      tour.placesImages[element.values[0]]
                    "
                    alt="Element Image"
                    class="object-cover rounded-2xl cursor-pointer"
                  />
                </div>

                <div
                  class="flex items-start justify-center flex-col relative xl:mx-0 mt-8"
                  :id="'poll' + index + '_' + index2"
                  v-else-if="element.name === 'poll'"
                >
                  <div class="font-semibold text-2xl">
                    {{ element.values.poll.question }}
                  </div>
                  <div
                    class="flex items-center justify-start gap-4 mt-2"
                    v-for="(pollvalue, pollindex) in element.values.poll.vars"
                    :key="'pollv' + pollindex"
                  >
                    <Button
                      class="hover:tt-bg-green hover:tt-text-black text-white px-1 py-2"
                      v-if="
                        !poll.includes(tour.id + '_' + index + '_' + index2) ||
                        poll2.includes(
                          tour.id + '_' + index + '_' + index2 + '_' + pollindex
                        )
                      "
                      size="0"
                      :class="
                        element.values.poll.answer === pollindex ||
                        poll2.includes(
                          tour.id + '_' + index + '_' + index2 + '_' + pollindex
                        )
                          ? 'tt-bg-green'
                          : 'tt-bg-gray'
                      "
                      @wasClicked="pollVChoose(index, index2, pollindex)"
                    >
                      <span
                        v-if="
                          element.values.poll.answer !== pollindex &&
                          !poll2.includes(
                            tour.id +
                              '_' +
                              index +
                              '_' +
                              index2 +
                              '_' +
                              pollindex
                          )
                        "
                      >
                        {{ $t("chooisemef") }}
                      </span>
                      <span v-else>
                        {{ $t("chooisemefed") }}
                      </span>
                    </Button>
                    <div>
                      {{ pollvalue }}
                    </div>
                  </div>
                  <Button
                    size="Lg"
                    v-if="!poll.includes(tour.id + '_' + index + '_' + index2)"
                    class="rounded-lg tt-bg-green text-white mt-5 answebtn"
                    @wasClicked="pollVAnswer(index, index2)"
                  >
                    {{ $t("otveititmdanda") }}
                  </Button>
                </div>

                <h3
                  v-else-if="element.name === 'title'"
                  class="font-semibold mt-4"
                  v-html="daimnef(element.values)"
                ></h3>

                <div
                  v-else-if="element.name === 'info'"
                  class="flex items-start justify-center flex-col italic tt-text-black font-medium tt-bg-lightgray rounded-2xl w-full p-4 xl:p-12 mt-8 overflow-hidden w-full break-words mobvw"
                  v-html="
                    daimnef(element.values).replace(/(\r\n|\r|\n)/g, '<br>')
                  "
                ></div>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-start gap-4 mt-6 mx-6 xl:mx-0">
            <Button
              class="rounded-lg tt-bg-green text-white gap-2"
              @wasClicked="doStep(-1)"
            >
              <ArrowLeftIcon class="w-4 h-4" />
              <span>{{ $t("bakcktogtnaxza") }}</span>
            </Button>

            <Button
              class="rounded-lg tt-bg-green text-white gap-2"
              v-if="step <= tour.places.length"
              @wasClicked="doStep()"
            >
              <span>{{ $t("comntitthrnea") }}</span>
              <ArrowRightIcon class="w-4 h-4" />
            </Button>
          </div>

          <div
            class="block mx-auto w-full xl:w-1/2 mt-8"
            v-if="step > tour.places.length"
          >
            <h2 class="tt-text-black text-lg font-bold px-6">
              {{ $t("writoyuanspgtrrfvierw") }}
            </h2>
            <FeedBacker
              :tour="tour"
              :doRate="doRate"
              :rate="rate"
              :rates="rates"
              :tour_url="tour_url"
            />
          </div>
        </div>
      </section>
    </div>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>
    <Menu />
  </div>
</template>

<script>
import Menu from "@/components/Control/Menu"
import userInfo from "@/common/userInfo"
import nvgpost from "@/common/nvgpost"
import Loader from "@/components/Static/Loader"
import {
  MglMap,
  MglMarker,
  MglNavigationControl,
  MglGeolocateControl,
} from "vue-mapbox"
import {
  LocationMarkerIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@vue-hero-icons/outline"
import Button from "@/components/Inter/Button"
import daimnef from "@/common/daimnetranslate"
import { isIOS, isAndroid } from "mobile-device-detect"
import FeedBacker from "@/components/Block/FeedBacker"
import Navbar from "@/components/Landing/Navbar"

export default {
  name: "Travel",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: {
    Navbar,
    Button,
    Loader,
    Menu,
    MglMap,
    MglMarker,
    LocationMarkerIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    MglNavigationControl,
    MglGeolocateControl,
    FeedBacker,
  },
  data() {
    return {
      accessToken: userInfo.accessToken,
      mapStyle: userInfo.mapStyle,
      tour_id: this.$route.params.tour_id,
      tour_country: this.$route.params.tour_country,
      tour_url: this.$route.params.tour_url,
      isIOS: isIOS,
      isAndroid: isAndroid,

      codeLang: localStorage.getItem("codeLang"),
      poll: [],
      poll2: [],
      tour: {
        myReview: false,
        paid: false,
        places: [],
        startLocation: [0, 0],
        title: "",
        country: "",
        city: "",
      },
      step: 1,
      rates: [0, 0, 0, 0, 0],
      rate: 0,
    }
  },
  methods: {
    doRate: function (star) {
      this.rates = [0, 0, 0, 0, 0]
      this.rate = star
      for (let i = 0; i <= star; i++) {
        this.rates[i] = 1
      }
    },

    async onMapLoadedNew(event) {
      const asyncActions = await event.component.actions
      asyncActions.flyTo({
        center: this.tour.startLocation,
        zoom: 10,
        speed: 1,
      })
    },

    async onMapLoadedNew2(event) {
      const asyncActions = await event.component.actions
      asyncActions.flyTo({
        center: this.tour.places[this.step - 2].coordinates,
        zoom: 13,
        speed: 1,
      })
    },

    daimnef: function (titleObj) {
      return daimnef(titleObj, this.codeLang)
    },

    pollVChoose: function (pi, ei, index) {
      this.tour.places[pi].elements[ei].values.poll.answer = index
      this.tour = JSON.parse(JSON.stringify(this.tour))
    },

    pollVAnswer: function (pi, ei) {
      if (this.tour.places[pi].elements[ei].values.poll.answer !== undefined) {
        nvgpost(
          this.$apiEndpoint + "/v1/tours/poll",
          "POST",
          {
            tourUrl: this.tour_url,
            place: pi,
            element: ei,
            answer: this.tour.places[pi].elements[ei].values.poll.answer,
          },
          { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
          () => {
            document
              .querySelector("#poll" + pi + "_" + ei + " button.answebtn")
              .remove()
            let ab = document.querySelectorAll(
              "#poll" + pi + "_" + ei + " button.tt-bg-gray"
            )
            for (let i = 0; i < ab.length; i++) {
              ab[i].remove()
            }

            this.$root.$emit(
              "toShowTrigger",
              this.$root.$t("vitotneititili"),
              1
            )
          },
          true
        )
      }
    },

    doStep: function (minus) {
      window.scrollTo(0, 0)
      if (minus !== -1) {
        if (this.step <= this.tour.places.length) this.step++
      } else {
        this.step--
      }

      if (this.step === this.tour.places.length + 1) {
        nvgpost(
          this.$apiEndpoint + "/v1/tours/success",
          "POST",
          {
            tourUrl: this.tour_url,
          },
          { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
          () => {},
          true
        )
      }
    },
  },
  mounted() {
    setTimeout(
      function () {
        this.codeLang = userInfo.codeLang

        nvgpost(
          this.$apiEndpoint + "/v1/tours/paid",
          "POST",
          {
            tourId: this.tour_id,
            tour_country: this.tour_country,
            tour_url: this.tour_url,
            lang: this.codeLang,
          },
          { Authorization: "Bearer " + localStorage.getItem("nvg_auth") },
          (res) => {
            this.tour = res.data[0]
            this.poll = res.data[0].poll
            this.poll2 = res.data[0].polla

            if (res?.ref === "ok") {
              localStorage.removeItem("nvgref")
              localStorage.removeItem("nvgref_type")
              localStorage.removeItem("referal_tour")
            }
          },
          true
        )
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.codeLang = userInfo.codeLang
      }.bind(this),
      1000
    )
  },
}
</script>

<style scoped>
.top-17 {
  top: 4.8rem;
}
@media screen and (max-width: 425px) {
  .mobvw {
    max-width: 87vw;
  }
}
</style>
