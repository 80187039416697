<template>
  <section class="mx-5 xl:mx-12 grid xl:grid-cols-2 items-center gap-6 mt-28 bg-white
  rounded-2xl drop-shadow-34xl1 filter">
    <div class="flex items-start justify-center gap-6 flex-col w-full px-5 xl:px-20 py-2 order-2 xl:order-1">
      <h2 class="font-bold uppercase tt-text-green">
        {{ $t('centra') }}
      </h2>
      <h1 class="font-extrabold tt-text-black text-2xl xl:text-5xl" v-html="$t('sahrewqp')"></h1>
      <p>
        {{ $t('sahrewqp1') }}
      </p>

      <ButtonRouter class="tt-bg-green text-white text-base mt-2" href="/create/steps/">
        {{ $t('choosetra2') }}
      </ButtonRouter>
    </div>
    <div class="w-full rounded-2xl overflow-hidden relative order-1 xl:order-2">
      <picture>
        <source srcset="../../assets/startCreate.webp" type="image/webp">
        <source srcset="../../assets/startCreate.jpg" type="image/jpeg">
        <img src="../../assets/startCreate.jpg" alt="Start create travels"
             class="object-cover pointer-events-none select-none w-full rounded-2xl">
      </picture>
    </div>
  </section>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";
export default {
  name: "StartCreate",
  components: {ButtonRouter}
}
</script>