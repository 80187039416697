<template>
  <section class="mx-5 xl:mx-12 grid xl:grid-cols-12 items-center gap-6 mt-8 mt-20">
    <div class="xl:col-start-2 xl:col-span-10 flex items-start justify-center gap-6 flex-col w-full p-5 xl:p-20
    bg-white rounded-2xl drop-shadow-34xl1 filter">
      <div class="xl:grid xl:grid-cols-2 xl:gap-10">
        <div>
          <h2 class="font-extrabold text-2xl xl:text-5xl leading-normal">
            {{ $t('wdsahgu') }}
          </h2>
          <p class="mt-8" v-html="$t('tewdsrwdo2edwi2')"></p>
        </div>
        <div class="flex items-start mx-auto justify-center gap-12 my-5 xl:my-0 flex-col">
          <div class="flex items-center justify-center gap-8">
            <strong class="tt-text-black font-extrabold text2xl xl:text-4xl">1</strong>
            <span v-html="$t('nubrtext1')"></span>
          </div>
          <div class="flex items-center justify-center gap-8">
            <strong class="tt-text-black font-extrabold text2xl xl:text-4xl">2</strong>
            <span v-html="$t('nubrtext2')"></span>
          </div>
          <div class="flex items-center justify-center gap-8">
            <strong class="tt-text-black font-extrabold text2xl xl:text-4xl">3</strong>
            <span v-html="$t('nubrtext3')"></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhatYouNeed",
}
</script>