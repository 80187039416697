<template>
  <section class="mx-5 xl:mx-12 grid xl:grid-cols-12 items-center gap-6 mt-8">
    <div class="xl:col-start-3 xl:col-span-8 rounded-3xl overflow-hidden relative">
      <picture>
        <source srcset="../../assets/startCreate3.webp" type="image/webp">
        <source srcset="../../assets/startCreate3.png" type="image/png">
        <img src="../../assets/startCreate3.png" alt="How much Can you earn"
             class="object-cover pointer-events-none select-none w-full hidden xl:block">
      </picture>

      <div class="h-full w-full xl:absolute xl:top-0 xl:left-0 flex items-center justify-center gap-8 sm:gap-10 lg:gap-8 flex-col">
        <h2 class="font-extrabold text-4xl sm:text-6xl xl:text-8xl self-center">{{ $t('pawod2edas21') }}</h2>
        <h2 class="font-extrabold text-4xl sm:text-6xl xl:text-8xl self-center">{{ $t('pawod2edas22') }}</h2>
        <h2 class="font-extrabold text-4xl sm:text-6xl xl:text-8xl self-center">{{ $t('pawod2edas23') }}</h2>

        <p class="xl:w-1/2 self-start px-5 mt-5 self-start xl:mt-10" v-html="$t('pawod2edas24text3')"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowMuch"
}
</script>