<template>
  <router-link :to="'/article/' + article.url"
               class="w-full overflow-hidden rounded-2xl relative group tt-bg-black" :class="hh">
    <img :src="article.preview" :alt="article.title"
         class="rounded-2xl group-hover:scale-110 group-hover:brightness-50 brightness-75 tt-bg-black transform transition filter
          object-cover w-full pointer-events-none select-none" :class="hh">
    <div class="absolute top-3 left-3 xl:top-8 xl:left-8 rounded-xl font-bold text-white">
      <div class="text-sm xl:text-base uppercase" v-if="!homepage">
        {{ article.timestamp }}
      </div>
      <div class="text-lg xl:text-xl w-64 xl:max-w-sm xl:w-auto mt-2">
        {{ article.title }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ArticleCard",
  props: {
    article: Object,
    homepage: {
      type: Boolean,
      default: false,
    },
    hh: {
      type: String,
      default: "xl:h565 h-60",
    }
  }
}
</script>

<style scoped>

.h295 {
    height: 295px;
  }

  @media (min-width: 1280px) {
    .xl\:h565 {
      height: 565px;
    }
  }

</style>