<template>
  <div>
    <Banner
      :image="this.user.banner"
      v-if="this.user.firstName"
      class="xl:h-96 w-full"
    />
    <Navbar
      class="xl:grid hidden"
      :class="
        !!this.needWhiteNav
          ? 'text-white backdrop-filter backdrop-brightness-50'
          : ''
      "
      :logo="!!this.needWhiteNav ? 'white' : ''"
    />
    <Menu />

    <section
      class="px-5 xl:px-8 grid xl:grid-cols-12 gap-6 relative mt-2 xl:mt-4"
      v-if="this.user.firstName"
    >
      <div
        class="xl:col-span-4 rounded-t-2xl bg-white px-3 xl:px-8 py-10 transform xl:-translate-y-32 xl:h-mnogo xl:overflow-hidden xl:sticky xl:top-48"
      >
        <div
          class="flex items-center justify-start gap-8 flex-col"
          :class="{ heismoboa: heismoboa }"
        >
          <Avatar :avatar="user.avatar" href="#" size="w-32 h-32" />
          <div class="text-center">
            <h1 class="font-bold text-3xl">
              {{ this.user.firstName }} {{ this.user.lastName }}
            </h1>
          </div>
          <div class="w-full">
            <ButtonRouter
              class="rounded-lg tt-bg-green text-white w-full gap-2"
              :href="'/edit/' + this.uid"
              v-if="this.me.uid === this.user.uid"
            >
              <span>{{ $t("editproe") }}</span>
              <PencilIcon class="w-4 h-4" />
            </ButtonRouter>
            <Button
              class="rounded-lg xl:hidden mt-5 tt-bg-green text-white w-full gap-2"
              @wasClicked="dologout"
              v-if="this.me.uid === this.user.uid"
            >
              <span>{{ $t("logout") }}</span>
              <LogoutIcon class="w-4 h-4" />
            </Button>
            <div
              v-if="this.me.uid === this.user.uid"
              class="mt-4 border tt-border-green p-3 rounded-md"
            >
              <div class="flex items-center justify-start gap-2">
                <div class="text-sm">{{ $t("profile_yourlink") }}</div>
                <div class="group cursor-help relative">
                  <QuestionMarkCircleIcon class="w-4 h-4 tt-text-green" />

                  <div
                    class="group-hover:block hidden bg-white shadow-xl rounded-2xl p-4 absolute top-0 -right-12 w-64 z-40"
                    v-html="$t('profile_invitefriendstext')"
                  ></div>
                </div>
              </div>
              <div class="text-xs mt-2">{{ $t("profile_invitefriends") }}</div>

              <div class="flex items-center justify-center gap-0 mt-2">
                <input
                  class="tt-text-black text-sm bg-white rounded-xl border focus:tt-border-green px-4 select-text w-full text-indent py-3"
                  style="
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                  "
                  type="text"
                  readonly
                  ref="refLink"
                  :value="`https://teleportravel.com/?ref=${this.me.uid}`"
                />
                <Button
                  size="Lg"
                  @wasClicked="copyRefLink"
                  style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                  class="rounded-r-lg tt-bg-green text-white w-auto gap-2"
                >
                  <span>{{ $t("docopy") }}</span>
                  <ClipboardCopyIcon class="w-4 h-4" />
                </Button>
              </div>
            </div>

            <Button
              class="hidden xl:flex mt-2 tt-text-green gap-2"
              @wasClicked="dologout"
              v-if="this.me.uid === this.user.uid"
            >
              <span>{{ $t("logout") }}</span>
              <LogoutIcon class="w-4 h-4" />
            </Button>
          </div>
          <ul
            class="flex items-start justify-start gap-3 flex-col w-full"
            v-if="this.isAdmin || this.me.uid === this.user.uid"
          >
            <li
              class="flex items-center justify-between w-full"
              v-for="(stat, index) in [1, 2]"
              :key="'a' + index"
            >
              <div
                @click="setheismoboa()"
                class="group cursor-help relative flex items-center justify-start gap-2"
              >
                <span>
                  {{ $t("sgtat" + index) }}
                </span>

                <div v-if="stat === 2">
                  <QuestionMarkCircleIcon class="w-4 h-4 tt-text-green" />

                  <div
                    class="group-hover:block hidden bg-white shadow-xl rounded-2xl p-4 absolute top-0 left-0 w-64 xl:w-96 z-40"
                    v-html="$t('podskadkasotraxschr002')"
                  ></div>
                </div>
              </div>
              <strong class="text-lg"> {{ statUser[index] }} € </strong>
            </li>
          </ul>
          <div class="w-full">
            <h3 class="font-semibold w-full">
              {{ $t("abome") }}
            </h3>
            <p
              class="self-start break-words mt-2"
              v-html="this.user.bio.replace(/(\r\n|\r|\n)/g, '<br>')"
            ></p>
          </div>
        </div>
      </div>

      <div class="xl:col-span-8">
        <div
          class="flex items-center justify-between xl:justify-start xl:gap-8 border-b tt-border-lightgray w-full mt-6"
        >
          <button
            class="font-semibold tt-text-green border-b-2 transform translate-y-px tt-border-green"
            @click="swtichTours(1)"
            id="swtichTours1"
          >
            {{ $t("creager1") }}
          </button>
          <button
            class="font-semibold tt-text-black transform translate-y-px"
            @click="swtichTours(2)"
            id="swtichTours2"
          >
            {{ $t("creager2") }}
          </button>
          <button
            class="font-semibold tt-text-black transform translate-y-px"
            @click="swtichTours(3)"
            id="swtichTours3"
          >
            {{ $t("creager3") }}
          </button>
        </div>

        <div
          class="grid xl:grid-cols-2 gap-6 mt-8"
          v-if="this.swtichToursState === 1"
        >
          <div
            v-for="(tour, tourIndex) in tours.created"
            :key="'tourc' + tourIndex"
          >
            <TourCard
              :user="user"
              :me="me"
              :tour="tour"
              :codeLang="codeLang"
              :autorStat="true"
            />
          </div>
        </div>

        <div
          class="grid xl:grid-cols-2 gap-6 mt-8"
          v-if="this.swtichToursState === 2"
        >
          <div
            v-for="(tour, tourIndex) in tours.purchased"
            :key="'tourf' + tourIndex"
          >
            <TourCard
              :user="user"
              :me="me"
              :tour="tour"
              :codeLang="codeLang"
              :autorStat="true"
            />
          </div>
        </div>

        <div
          class="grid xl:grid-cols-2 gap-6 mt-8"
          v-if="this.swtichToursState === 3"
        >
          <div
            v-for="(tour, tourIndex) in tours.followed"
            :key="'tourf' + tourIndex"
          >
            <TourCard
              :user="user"
              :me="me"
              :tour="tour"
              :codeLang="codeLang"
              :autorStat="true"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar"
import TourCard from "@/components/Block/TourCard"
import Banner from "@/components/Static/Banner"
import Avatar from "@/components/Static/Avatar"
import nvgpost from "@/common/nvgpost"
import logout from "@/common/logout"
import ButtonRouter from "@/components/Inter/ButtonRouter"
import Button from "@/components/Inter/Button"
import {
  PencilIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
  ClipboardCopyIcon,
} from "@vue-hero-icons/outline"
import Footer from "@/components/Block/Footer"
import userInfo from "@/common/userInfo"
import Loader from "@/components/Static/Loader"
import Menu from "@/components/Control/Menu"

export default {
  name: "Profile",
  metaInfo() {
    return {
      title: "Teleportravel",
      meta: [
        {
          name: "description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        {
          name: "og;description",
          content:
            "Испытайте радость путешествий с семьей и друзьями без траты времени на составления маршрутов",
        },
        { property: "og:title", content: "Teleportravel" },
        { property: "og:site_name", content: "Teleportravel" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    }
  },
  components: {
    Footer,
    ButtonRouter,
    Avatar,
    Button,
    Banner,
    Menu,
    Navbar,
    ClipboardCopyIcon,
    PencilIcon,
    LogoutIcon,
    QuestionMarkCircleIcon,
    Loader,
    TourCard,
  },
  data() {
    return {
      uid: this.$route.params.uid,
      needWhiteNav: true,
      heismoboa: false,
      user: {},
      me: {
        uid: userInfo.uid,
      },
      codeLang: localStorage.getItem("codeLang"),
      tours: {
        followed: [],
        purchased: [],
        created: [],
      },
      statUser: [0, 0],
      swtichToursState: 1,
    }
  },
  computed: {
    isAdmin: function () {
      let a = false
      let b = false
      let c = false

      if (this.tours.created.length) a = this.tours.created[0].isAdmin
      if (this.tours.followed.length) b = this.tours.followed[0].isAdmin
      if (this.tours.purchased.length) c = this.tours.purchased[0].isAdmin

      return a || b || c
    },
  },
  methods: {
    copyRefLink() {
      const copyText = this.$refs.refLink
      copyText.select()
      copyText.setSelectionRange(0, 99999) // For mobile devices
      navigator.clipboard.writeText(copyText.value)
    },
    updateScroll: function () {
      this.needWhiteNav = window.scrollY <= 350
    },
    dologout: function () {
      this.me = {}
      logout()
      window.location.reload()
    },
    setheismoboa: function () {
      this.heismoboa = true
      setTimeout(
        function () {
          this.heismoboa = false
        }.bind(this),
        25000
      )
    },
    htmlchanger: function (tid, tids2) {
      document.getElementById(tid).classList.add("tt-text-green")
      document.getElementById(tid).classList.add("tt-border-green")
      document.getElementById(tid).classList.add("border-b-2")
      document.getElementById(tid).classList.remove("tt-text-black")

      for (let i = 0; i < tids2.length; i++) {
        document.getElementById(tids2[i]).classList.remove("tt-text-green")
        document.getElementById(tids2[i]).classList.remove("tt-border-green")
        document.getElementById(tids2[i]).classList.remove("border-b-2")
        document.getElementById(tids2[i]).classList.add("tt-text-black")
      }
    },
    swtichTours: function (number) {
      this.swtichToursState = number

      if (number === 1) {
        this.htmlchanger("swtichTours1", ["swtichTours2", "swtichTours3"])
      } else if (number === 2) {
        this.htmlchanger("swtichTours2", ["swtichTours1", "swtichTours3"])
      } else if (number === 3) {
        this.htmlchanger("swtichTours3", ["swtichTours1", "swtichTours2"])
      }
    },
  },
  mounted() {
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      500
    )
    setTimeout(
      function () {
        this.me = {
          uid: userInfo.uid,
        }
        this.codeLang = userInfo.codeLang
      }.bind(this),
      1000
    )

    window.addEventListener("scroll", this.updateScroll)

    nvgpost(
      this.$apiEndpoint + "/v1/users/info",
      "POST",
      {
        uid: this.uid,
      },
      {},
      (res) => {
        if (res.firstName === undefined || res.lastName === undefined)
          this.$root.$emit("toShowTrigger", this.$root.$t("kdferor2df"), 2)

        if (res.firstName !== undefined && res.lastName !== undefined) {
          this.user = {
            firstName: res.firstName,
            lastName: res.lastName,
            email: res.email,
            avatar: res.avatar,
            banner: res.banner,
            uid: res.uid,
            bio: res.bio,
          }

          // get my vip info
          nvgpost(
            this.$apiEndpoint + "/v1/users/stat",
            "POST",
            {
              userId: this.uid,
            },
            {
              Authorization: "Bearer " + localStorage.getItem("nvg_auth"),
            },
            (res) => {
              if (res) {
                this.statUser = res.data
              }
            },
            true
          )

          // get his tours
          nvgpost(
            this.$apiEndpoint + "/v1/tours/list",
            "POST",
            {
              userId: this.user.uid,
              lang: this.codeLang,
            },
            {
              Authorization: "Bearer " + localStorage.getItem("nvg_auth"),
            },
            (res) => {
              if (res) {
                this.tours.created = res.data.created
                this.tours.followed = res.data.followed
                this.tours.purchased = res.data.purchased
              }
            },
            true
          )
        }
      },
      true
    )
  },
}
</script>

<style scoped>
@media (min-width: 1280px) {
  .xl\:h-mnogo {
    height: 772px;
  }
  .heismoboa {
    height: auto;
  }
}

.heismoboa {
  height: 850px;
}
</style>
