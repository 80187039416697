<template>
  <input class="tt-text-black text-sm bg-white rounded-xl border focus:tt-border-green px-4
                select-text" :id="this.id" :style="'width: ' + this.width + 'px'"
         :class="itype === 'Sm' ? ' py-3' : ' py-4'" @input="onchangefunc($event, farg1)"
         :type="type" :placeholder="this.placeholder" v-model="valueOfType"
         v-on:keyup.enter="vkeyup()">
</template>

<script>
export default {
  name: "Input",
  props: {
    id: String,
    placeholder: String,
    type: String,
    itype: String,
    width: Number,
    farg1: [String, Number, Array, Object],
    watchFunction: Function,
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
    vkeyup: Function,
  },
  data() {
    return {
      valueOfType: ""
    }
  },
  watch: {
    valueOfType: function (value) {
      if(this.watchFunction) {
        this.watchFunction(value, this.id)
      }
    },
  }
}
</script>
