<template>
  <div>
    <Navbar class="grid"/>
    <Menu />

    <vue-tailwind-modal
        :showing="shareModal"
        @close="shareModal = false"
        :showClose="false"
        :backgroundClose="true"
        class="xl:p-8 bg-smoke-400"
    >
      <div class="relative">
        <ButtonX class="absolute top-4 right-4 tt-text-black text-3xl" @wasClicked="shareModal = false" />
      </div>

      <div class="tt-text-black font-semibold">
        {{ $t('doosahre') }}
      </div>

      <div class="bg-white mt-16">
        <div class="flex items-center justify-start gap-2">
          <Input type="text" :value="this.thisurl" readonly="true" class="w-full flex-grow" id="shareUrlInput" />
          <Button class="tt-bg-green text-white flex-none uppercase rounded-lg" size="Lg" @wasClicked="copyUrlInput()">
            {{ $t('docopy') }}
          </Button>
        </div>

        <div class="xl:grid xl:grid-cols-2 gap-4 mt-4">
          <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + this.thisurl"
             class="tt-bg-light flex items-center justify-start gap-2 p-3 rounded-2xl"
             target="_blank">
            <img src="../assets/facebook.svg" class="w-4 h-4">
            <span>{{ $t('sarefavwe1') }}</span>
          </a>
          <a :href="'http://vk.com/share.php?url=' + this.thisurl"
             class="tt-bg-light flex items-center justify-start gap-2 p-3 rounded-2xl"
             target="_blank">
            <img src="../assets/vk.svg" class="w-4 h-4">
            <span>{{ $t('sarefavwe2') }}</span>
          </a>
          <a :href="'https://twitter.com/intent/tweet?url=' + this.thisurl"
             class="tt-bg-light flex items-center justify-start gap-2 p-3 rounded-2xl"
             target="_blank">
            <img src="../assets/twitter.svg" class="w-4 h-4">
            <span>{{ $t('sarefavwe3') }}</span>
          </a>
          <a :href="'https://t.me/share/url?url=' + this.thisurl"
             class="tt-bg-light flex items-center justify-start gap-2 p-3 rounded-2xl"
             target="_blank">
            <img src="../assets/tg.svg" class="w-4 h-4">
            <span>{{ $t('sarefavwe4') }}</span>
          </a>
        </div>
      </div>
    </vue-tailwind-modal>
    
    <section class="mt-24 xl:mt-28" v-if="!!this.article.id">
      <div class="xl:grid xl:grid-cols-12 mt-8 xl:mt-16 px-5">
        <div class="xl:col-start-4 xl:col-end-10">
          <Categories :categories="this.article.categories" class="justify-start" :btnClick="gotoFilter" />

          <h1 class="text-2xl xl:text-4xl font-bold mt-2">
            {{ this.article.title }}
          </h1>
          <h2 class="tt-text-gray text-base uppercase mt-2">
            {{ this.article.timestamp }}
          </h2>
          <button class="tt-text-black inline-block mt-4" @click="shareModal = true">
            <ShareIcon class="w-8 h-8" />
          </button>
        </div>
      </div>

      <div class="xl:grid xl:grid-cols-12 mt-8 xl:mt-8 px-5">
        <div class="xl:col-start-4 xl:col-end-10 w-full h-screen xl:h-96 overflow-hidden rounded-2xl">
          <img :src="this.article.preview" :alt="'Article preview' + this.article.title"
               class="object-cover w-full h-screen xl:h-96 rounded-2xl pointer-events-none select-none">
        </div>
      </div>

      <div class="grid xl:grid-cols-12 mt-8 xl:mt-8 px-5" id="textBlog">
        <div class="xl:col-start-4 xl:col-end-10 text-sm mb-8" v-if="!!this.article.googled && this.codeLang !== 'ru'">
          {{ $t('perevefenee') }}
        </div>

        <div class="xl:col-start-4 xl:col-end-10 text-lg" v-html="weboaMD(this.article.text) + this.cssText"></div>
      </div>

      <div class="grid xl:grid-cols-12 mt-16 px-5">
        <div class="xl:col-start-4 xl:col-end-10 border-t tt-border-green">
          <div class="tt-text-black text-lg font-bold mt-4">
            {{ $t('redmemofrekf') }}
          </div>
          <div class="grid xl:grid-cols-2 gap-4 mt-4">
            <ArticleCard class="mt-5"
                         v-for="(article, index) in readmore" :key="'readarticle' + index" :article="article" hh="h295" />
          </div>
        </div>
      </div>

      <Subscribe class="mt-32" />


    </section>
    <div class="w-full h-screen flex items-center justify-center" v-else>
      <Loader />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Landing/Navbar";
import Menu from "@/components/Control/Menu";
import Footer from "@/components/Block/Footer";
import {ShareIcon} from "@vue-hero-icons/outline";
import userInfo from "@/common/userInfo";
import nvgpost from "@/common/nvgpost";
import Categories from "@/components/Block/Categories";
import ArticleCard from "@/components/Block/ArticleCard";
import Loader from "@/components/Static/Loader";
import router from "@/router";
import ButtonX from "@/components/Inter/ButtonX";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";
import Subscribe from "@/components/Landing/Subscribe";


export default {
  name: "Article",
  components: {Subscribe, Button, Input, ButtonX, Categories, Footer, Menu, Navbar, ShareIcon, ArticleCard, Loader},
  data() {
    return {
      thisurl: window.location,
      shareModal: false,
      article_name: this.$route.params.article_name,
      codeLang: localStorage.getItem("codeLang"),
      article: [],
      readmore: [],

      cssText: "<style>" +
          "#textBlog img {\n" +
          "    border-radius: 2rem;\n" +
          "  }" +
          "" +
          "#textBlog a {color: #35B642}" +
          "" +
          "#textBlog figure img {" +
          "margin:auto" +
          "}" +
          "</style>"
    }
  },
  mounted() {

    setTimeout(function () {
      this.codeLang = userInfo.codeLang

      nvgpost(this.$apiEndpoint+"/v1/blog/article",
          "POST",
          {
            lang: this.codeLang,
            article_name: this.article_name,
          },
          {
            'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
          }, (res)=>{
            if(res) {
              this.article = res.data[0]

              if(this.article.articleLang !== this.codeLang) {
                router.push("/article/" + this.article.curls[this.codeLang])
              }

              nvgpost(this.$apiEndpoint+"/v1/blog/articles",
                  "POST",
                  {
                    lang: this.codeLang,
                    limit: 2,
                    noid: this.article.id,
                  },
                  {
                    'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),
                  }, (res)=>{
                    if(res) {
                      this.readmore = res.data
                    }
                  }, true)
            }
          }, true)
    }.bind(this), 500)
  },
  metaInfo() {
    return {
      title: this.article.title,
      meta: [
        { name: 'description', content:  this.article.shorttext},
        { property: 'og:title', content: this.article.title},
        {property: 'og:type', content: 'article'},
      ]
    }
  },
  methods: {
    gotoFilter: function (ctitle) {
      router.push("/blog?tag=" + ctitle)
    },
    copyUrlInput: function () {
      const url = document.getElementById("shareUrlInput")
      url.select()
      document.execCommand('copy')
      this.$root.$emit("toShowTrigger", this.$root.$t('uraskrpksir'), 1)
    },
    weboaMD: function (text) {
      try {
        let a = JSON.parse(text)
        return a.html.join("\n")
      }
      catch (e) {
        return text
      }
    }
  }
}
</script>