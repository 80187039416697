<template>
  <section class="px-5 xl:px-12 mt-40 xl:mt-20">
    <div class="xl:grid xl:grid-cols-12">
      <div class="xl:col-start-2 xl:col-span-6">
        <h2 class="font-extrabold text-2xl xl:text-5xl">
          {{ $t('dsoba') }}
        </h2>
      </div>
      <div class="xl:col-start-2 xl:col-span-10 flex items-center justify-center flex-col mt-8 xl:mt-16">
        <div v-for="col in [1,2,3,4]" :key="'bb'+col" class="w-full border-t tt-border-lightgray">
          <button class="flex items-center justify-between hover:bg-white transition w-full px-4 py-10 text-left"
                  @click="openColp(col)">
          <span v-html="$t('dsoba2'+col)"
                class="block w-full text-lg text-left self-start place-content-start"></span>
            <div :id="'coalp21' + col" class="transition transform">
              <PlusIcon class="w-8 h-8" />
            </div>
          </button>
          <div class="hidden px-8 py-5" :id="'colp' + col" v-html="$t('dsoba2text'+col)"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {PlusIcon} from "@vue-hero-icons/outline";


export default {
  name: "QuestionsCreate",
  components: {PlusIcon},
  methods: {
    openColp: function (col) {
      let colm = document.getElementById('colp' + col)
      if(colm.classList.contains("hidden"))
        colm.classList.remove("hidden")
      else
        colm.classList.add("hidden")

      let colm2 = document.getElementById('coalp21' + col)
      if(colm2.classList.contains("rotate-45"))
        colm2.classList.remove("rotate-45")
      else
        colm2.classList.add("rotate-45")
    }
  }
}
</script>