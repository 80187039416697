let daimnef = function (titleObj, codeLang) {
    let a = titleObj
    if(a) {
        let obj1 = Object.keys(a)
        if(obj1.includes(codeLang)) {
            return a[codeLang] ? a[codeLang] : "-"
        }
        else {
            //[obj1[0]]
            return a[Object.keys(a)[0]]
        }
    }
    else {
        return "-"
    }
}

export default daimnef